import { Surface } from '@/components/editor/components/ui/Surface'
import { Toolbar } from '@/components/editor/components/ui/Toolbar'
import { PencilIcon, TrashIcon } from '@heroicons/react/solid'

export type LinkPreviewPanelProps = {
  url: string
  onEdit: () => void
  onClear: () => void
}

export const LinkPreviewPanel = ({ onClear, onEdit, url }: LinkPreviewPanelProps) => {
  return (
    <Surface className="flex items-center gap-2 p-2">
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="deocrated-link text-[13px] break-all"
      >
        {url}
      </a>
      <Toolbar.Divider />
      <button className="dashboard-secondary" onClick={onEdit}>
        <PencilIcon name="Pen" />
      </button>
      <button className="dashboard-secondary" onClick={onClear}>
        <TrashIcon className="secondary-svg" />
      </button>
    </Surface>
  )
}
