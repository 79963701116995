import React, { useCallback, useEffect, useMemo, useState } from 'react'
import DashboardWrapper from '../../../components/DashboardWrapper'
import SettingsWrapper from '../../../components/SettingsWrapper'
import { useCurrentOrganization } from '../../../data/organization'
import { useBannedUsersWithFiltering } from '../../../data/organization'
import { IBannedUser } from '../../../data/organization'
import Loader from '../../../components/Loader'
import { InView } from 'react-intersection-observer'
import { XIcon, SearchIcon, PlusIcon, PlusCircleIcon } from '@heroicons/react/solid'
import { cn } from '@/lib'
import { AnimatePresence, motion } from 'framer-motion'
import { banUser, unbanUser } from '../../../../network/lib/organization'
import { toast } from 'sonner'
import PopupWrapper from '@/components/PopupWrapper'
import ConfirmationModal from '@/components/ConfirmationModal'
import { CardContent, CardFooter, CardHeader } from '@/components/radix/Card'
import { CardTitle } from '@/components/radix/Card'
import { Label } from '@/components/radix/Label'
import { Input } from '@/components/radix/Input'
import MutedItemWrapper from '@/components/settings/MutedItemWrapper'
import MutedItemLoading from '@/components/settings/MutedItemLoading'

export const BanUserModal: React.FC<{
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  onBanUser: (email: string, reason?: string) => void
  presetValues?: { email: string; reason?: string }
}> = ({ isOpen, setIsOpen, onBanUser, presetValues }) => {
  const [email, setEmail] = useState(presetValues?.email || '')
  const [reason, setReason] = useState(presetValues?.reason || '')

  return (
    <PopupWrapper setIsOpen={setIsOpen} isOpen={isOpen}>
      <CardHeader>
        <CardTitle>Ban User</CardTitle>
      </CardHeader>
      <CardContent className="gap-3 flex flex-col">
        <div>
          <Label className={cn('flex items-center pb-1.5')} htmlFor="email">
            Email
          </Label>
          <Input
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="block w-full mt-1"
            placeholder="user@example.com"
          />
        </div>
        <div>
          <Label className={cn('flex items-center pb-1.5')} htmlFor="reason">
            Reason{' '}
            <span className="text-foreground/50 dark:text-foreground/50 ml-1.5">(optional)</span>
          </Label>
          <Input
            id="reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            className="block w-full mt-1"
            placeholder="Reason for ban"
          />
        </div>
      </CardContent>
      <CardFooter className="flex justify-end gap-3">
        <button onClick={() => setIsOpen(false)} className="dashboard-secondary">
          Cancel
        </button>
        <button
          onClick={() => {
            onBanUser(email, reason)
            setIsOpen(false)
          }}
          className="dashboard-primary"
        >
          Ban User
        </button>
      </CardFooter>
    </PopupWrapper>
  )
}

const BannedUsers: React.FC = () => {
  const { org } = useCurrentOrganization()
  const [searchQuery, setSearchQuery] = useState('')
  const [filters, setFilters] = useState({ limit: 10, q: '' })
  const [banUserModalOpen, setBanUserModalOpen] = useState(false)
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [userToUnban, setUserToUnban] = useState<IBannedUser | null>(null)

  const { bannedUsers, totalBannedUsers, size, setSize, mutateBannedUsers } =
    useBannedUsersWithFiltering(filters, org)

  const showLoader = totalBannedUsers ? size * filters.limit < totalBannedUsers : false

  const loadMore = useCallback(() => {
    if (showLoader) {
      setSize(size + 1)
    }
  }, [showLoader, size, setSize])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
    setFilters((prev) => ({ ...prev, q: e.target.value }))
  }

  const handleBanUser = async (email: string, reason?: string) => {
    try {
      await banUser({ email, reason })
      await mutateBannedUsers()
      toast.success('User banned successfully')
    } catch (err: any) {
      toast.error(err?.response?.data?.message || 'Failed to ban user')
    }
  }

  const handleUnbanUser = async () => {
    if (!userToUnban) return

    try {
      await unbanUser(userToUnban._id)
      toast.success('User unbanned successfully')
      await mutateBannedUsers()
    } catch (err: any) {
      toast.error(err?.response?.data?.message || 'Failed to unban user')
    }
    setUserToUnban(null)
  }

  if (!org) return null

  return (
    <SettingsWrapper title="Banned users">
      <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
        <div className="sm:rounded-md">
          <div className="relative px-4 py-6 rounded-md up-element sm:p-6">
            <div className="flex items-start justify-between mb-6">
              <div>
                <h2
                  id="banned-users-heading"
                  className="text-lg font-medium leading-6 text-gray-600 dark:text-white"
                >
                  Banned users{' '}
                  <AnimatePresence>
                    {totalBannedUsers ? (
                      <motion.span
                        className="text-base  dark:text-foreground/80 text-gray-400/80"
                        initial={{
                          opacity: 0,
                        }}
                        animate={{
                          opacity: 1,
                        }}
                      >
                        ({totalBannedUsers})
                      </motion.span>
                    ) : null}
                  </AnimatePresence>
                </h2>
                <p className="mt-1 text-sm text-gray-400 dark:text-foreground">
                  Users who have been banned from interacting with your Featurebase organization.
                </p>
              </div>
              <div className="flex items-start gap-3">
                <label className="relative block focus:outline-none">
                  <span className="sr-only">Search</span>
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 ">
                    <SearchIcon className="w-4 h-4 secondary-svg" />
                  </span>
                  <input
                    className="py-1.5 pl-10 pr-3 bg-white dark:bg-secondary"
                    placeholder="Search for users"
                    type="text"
                    name="q"
                    onChange={handleChange}
                    value={searchQuery}
                  />
                </label>
                <button onClick={() => setBanUserModalOpen(true)} className="dashboard-primary">
                  <PlusCircleIcon className="w-4 h-4 mr-1.5 " />
                  Ban User
                </button>
              </div>
            </div>

            <div className="mt-6 space-y-4">
              {typeof bannedUsers === 'undefined' || bannedUsers === null ? (
                <MutedItemLoading />
              ) : bannedUsers.length === 0 ? (
                <MutedItemWrapper>No banned users found.</MutedItemWrapper>
              ) : (
                bannedUsers.map((user: IBannedUser) => (
                  <div
                    className="flex items-center justify-between px-3 py-2 text-gray-600 rounded-lg shadow-none dark:shadow-none dark:text-gray-50 dark:bg-secondary bg-gray-50 up-element"
                    key={user._id}
                  >
                    <div className="flex items-center">
                      <div>
                        <p className="flex items-center text-base font-medium line-clamp-2">
                          {user.value}
                        </p>
                        <span
                          className={cn(
                            'text-background-accent mt-0.5 dark:text-foreground line-clamp-3',
                            'block text-sm'
                          )}
                        >
                          {user.reason && `${user.reason}`}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 items-center space-x-3">
                      <button
                        className="px-2 text-xs dashboard-secondary"
                        onClick={() => {
                          setUserToUnban(user)
                          setConfirmationModalOpen(true)
                        }}
                      >
                        <XIcon className="w-3.5 h-3.5 mr-1 text-gray-200" /> Unban
                      </button>
                    </div>
                  </div>
                ))
              )}

              {showLoader && (
                <InView
                  as="div"
                  onChange={(inView) => {
                    if (inView) {
                      loadMore()
                    }
                  }}
                >
                  <div className="flex justify-center mt-4">
                    <div className="w-5 h-5 mx-auto secondary-svg">
                      <Loader />
                    </div>
                  </div>
                </InView>
              )}
            </div>
          </div>
        </div>
      </div>
      <BanUserModal
        isOpen={banUserModalOpen}
        setIsOpen={setBanUserModalOpen}
        onBanUser={handleBanUser}
      />
      <ConfirmationModal
        open={confirmationModalOpen}
        setOpen={setConfirmationModalOpen}
        callBack={handleUnbanUser}
        title={`Are you sure you want to unban ${userToUnban?.value}?`}
        description="This action cannot be undone."
        buttonTxt="Unban user"
      />
    </SettingsWrapper>
  )
}

export default BannedUsers
