import { useCallback } from 'react'
import { ColorButton } from './ColorButton'
import { Toolbar } from '../../ui/Toolbar'
import { Icon } from '../../ui/Icon'
import { themeColors } from '@/lib/editorConstants'

export type ColorPickerProps = {
  color?: string
  onChange?: (color: string) => void
  onClear?: () => void
  highlight?: boolean
}

export const ColorPicker = ({ color, onChange, onClear, highlight }: ColorPickerProps) => {
  const handleColorChange = useCallback(
    (newColor: string) => {
      if (highlight && onChange) {
        // Convert hex to rgba with 20% opacity
        const r = parseInt(newColor.slice(1, 3), 16)
        const g = parseInt(newColor.slice(3, 5), 16)
        const b = parseInt(newColor.slice(5, 7), 16)
        onChange(`rgba(${r}, ${g}, ${b}, 0.15)`)
      } else if (onChange) {
        onChange(newColor)
      }
    },
    [highlight, onChange]
  )

  return (
    <div className="flex flex-col gap-2  pt-2 px-2">
      <div className="">
        <div className="grid grid-cols-6 gap-1">
          {themeColors.map((currentColor) => (
            <ColorButton
              active={currentColor === color}
              color={currentColor}
              key={currentColor}
              onColorChange={handleColorChange}
            />
          ))}
          <Toolbar.Button
            className="!rounded-md !h-7"
            tooltip="Reset color to default"
            onClick={onClear}
          >
            <Icon name="Undo" />
          </Toolbar.Button>
        </div>
      </div>
    </div>
  )
}
