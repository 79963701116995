const HttpBackend = require('i18next-http-backend/cjs')
const ChainedBackend = require('i18next-chained-backend').default
const LocalStorageBackend = require('i18next-localstorage-backend').default
const path = require('path')

const canReadLocalStorage = () => {
  try {
    localStorage?.setItem('test', 'test')
    localStorage?.removeItem('test')
    return true
  } catch (e) {
    return false
  }
}

const determineBackends = () => {
  if (typeof window === 'undefined') {
    return []
  }

  if (canReadLocalStorage()) {
    return [LocalStorageBackend, HttpBackend]
  }

  return [HttpBackend]
}

module.exports = {
  backend: {
    backendOptions: [
      { expirationTime: 60 * 60 * 1000 },
      {
        /* loadPath: 'https:// somewhere else' */
      },
    ], // 1 hour
    backends: determineBackends(),
  },
  i18n: {
    locales: [
      'default',
      'bn', // Bengali
      'bs', // Bosnian
      'pt-BR', // Brazilian Portuguese
      'bg', // Bulgarian
      'ca', // Catalan
      'hr', // Croatian
      'cs', // Czech
      'da', // Danish
      'nl', // Dutch
      'en', // English
      'et', // Estonian
      'fi', // Finnish
      'fr', // French
      'de', // German
      'el', // Greek
      'hi', // Hindi
      'hu', // Hungarian
      'id', // Indonesian
      'it', // Italian
      'ja', // Japanese
      'ko', // Korean
      'lv', // Latvian
      'lt', // Lithuanian
      'ms', // Malay
      'mn', // Mongolian
      'nb', // Norwegian
      'pl', // Polish
      'pt', // Portuguese
      'ro', // Romanian
      'ru', // Russian
      'sr', // Serbian
      'zh-CN', // Simplified Chinese
      'sl', // Slovenian
      'es', // Spanish
      'sw', // Swahili
      'sv', // Swedish
      'th', // Thai
      'zh-TW', // Traditional Chinese
      'tr', // Turkish
      'uk', // Ukrainian
      'vi', // Vietnamese
    ],
    defaultLocale: 'default',
    localeDetection: false,
  },
  reloadOnPrerender: true,
  serializeConfig: false,
  use: typeof window !== 'undefined' ? [ChainedBackend] : [],
  localePath: path.resolve('./public/locales'),
}
