import { tagColorData } from '@/components/AddTagModal'
import Callout from '@/components/Callout'


import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/radix/DropdownMenu'
import { cn } from '@/lib'
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react'
import React from 'react'

const CalloutComponent: React.FC<any> = (props) => {
  const presetColros = [{ name: 'Accent', hex: '' }, ...tagColorData]

  const currentColor = props.node.attrs.color.toString()?.startsWith('#')
    ? { name: 'Custom', hex: props.node.attrs.color }
    : presetColros.find((color) => color.name === props.node.attrs.color)

  const background = currentColor?.hex ? currentColor?.hex : 'hsl(var(--accent))'

  return (
    <NodeViewWrapper>
      <Callout
        background={background}
        editor={
          <div className="absolute z-20 top-3 right-3" contentEditable={false}>
            <DropdownMenu>
              <DropdownMenuTrigger className="p-1.5 hover:bg-white/5">
                <div
                  style={{
                    background: background,
                  }}
                  className={cn('h-2.5 w-2.5 rounded-full border border-white/20')}
                ></div>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-32" align="end">
                {presetColros.map((color) => (
                  <DropdownMenuItem
                    onSelect={() => {
                      props.updateAttributes({
                        color: color.name,
                      })
                    }}
                    key={color.name}
                  >
                    <div
                      className="h-1.5 w-1.5 mr-2 rounded-full"
                      style={{
                        background: color?.hex ? color?.hex : 'hsl(var(--accent))',
                      }}
                    ></div>
                    {color.name}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        }
      >
        <NodeViewContent />
      </Callout>
      {/* <div className="relative p-4 overflow-hidden rounded-md">
        <div
          className="absolute inset-0 opacity-10"
          contentEditable={false}
          style={{
            background: background,
          }}
        ></div>
        <div
          className="absolute inset-0 opacity-10"
          contentEditable={false}
          style={{
            border: `1px solid ${background}`,
          }}
        ></div>
        <div className="absolute z-20 top-3 right-3" contentEditable={false}>
          <DropdownMenu>
            <DropdownMenuTrigger className="p-1.5 hover:bg-white/5">
              <div
                style={{
                  background: background,
                }}
                className={cn('h-2.5 w-2.5 rounded-full border border-white/20')}
              ></div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-32" align="end">
              {presetColros.map((color) => (
                <DropdownMenuItem
                  onSelect={() => {
                    props.updateAttributes({
                      color: color.name,
                    })
                  }}
                  key={color.name}
                >
                  <div
                    className="h-1.5 w-1.5 mr-2 rounded-full"
                    style={{
                      background: color?.hex ? color?.hex : 'hsl(var(--accent))',
                    }}
                  ></div>
                  {color.name}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <div className="relative text-white">
          <NodeViewContent />
        </div>
      </div> */}
    </NodeViewWrapper>
  )
}

export default CalloutComponent
