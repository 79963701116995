import { PlusCircleIcon } from '@heroicons/react/solid'
import { useAtom } from 'jotai'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { authenitcateModalAtom } from '../atoms/authAtom'
import { createPostAtom } from '../atoms/displayAtom'
import { useCurrentOrganization } from '../data/organization'
import { useUser } from '../data/user'

const EmptyFeedbackBoard: React.FC<{
  widget?: boolean
  light?: boolean
  setActivePage?: any
  hidePostCreation?: boolean
}> = ({ widget, light, setActivePage, hidePostCreation }) => {
  const [createPost, setCreatePost] = useAtom(createPostAtom)
  const [authenitcateModal, setAuthenitacteModal] = useAtom(authenitcateModalAtom)
  const { org } = useCurrentOrganization()
  const { user } = useUser()
  const { t } = useTranslation()

  const isIframeContext = React.useMemo(() => {
    if (typeof window === 'undefined') return false
    return window.parent !== window
  }, [])

  const handleClick = () => {
    if (!user) {
      if (org.settings.anyoneCanSubmit) {
        setCreatePost(true)
        return
      }
      setAuthenitacteModal(true)
    } else {
      setCreatePost(true)
    }
  }

  return (
    <div className="w-full">
      <div className="relative flex flex-col items-center justify-center mt-8 mb-12 space-y-6">
        <div className="flex flex-col items-center justify-center text-center">
          <h2 className="pt-12 font-semibold text-gray-700 dark:text-white">
            {t('no-submissions-yet')}
          </h2>
          <p className="max-w-xs mt-2 text-gray-400 dark:text-foreground">
            {t(
              'if-you-would-like-to-say-something-click-the-button-below-to-join-the-conversation'
            )}
          </p>
          {!hidePostCreation && (
            <button
              onClick={() => (!widget ? handleClick() : setActivePage('CreatePost'))}
              className="mt-6 dashboard-secondary"
            >
              <PlusCircleIcon className="w-4 h-4 mr-2 text-gray-200" />
              {t('create-first-post')}
            </button>
          )}
        </div>
      </div>
      <div className="relative max-w-md mx-auto mb-6 space-y-6 overflow-hidden">
        {!org?.settings?.customTheme?.enabled &&
          (widget ? (
            <div
              className={`absolute z-10 pb-10 -inset-2 mt-5  ${
                light
                  ? 'bg-gradient-to-b from-[#F6F5EF]/20 to-[#F6F5EF] dark:from-transparent  dark:to-[#222633]/90'
                  : 'bg-gradient-to-b from-white/20 to-white dark:from-transparent  dark:to-[#1C202C]/90'
              }`}
            ></div>
          ) : (
            <div
              className={`absolute z-10 pb-10 -inset-6 mt-5 bg-gradient-to-b from-white/20 to-white dark:from-transparent  dark:to-[#202431]`}
            ></div>
          ))}

        <div className="relative">
          {/* <div className="absolute z-0 w-20 h-20 transform -skew-y-6 rounded-md skew-x-4 left-10 top-1 bg-gradient-to-bl from-secondary/30 to-secondary/20 " /> */}
          <div className="relative flex items-end w-full p-5 border rounded-lg shadow-sm dark:shadow-lg border-gray-100/70 dark:border-border/20 bg-gray-50/50 dark:bg-secondary">
            <div className="">
              <div className="h-5 rounded-md w-52 bg-gray-500/10 dark:bg-border"></div>
              <div className="h-5 mt-3 rounded-md w-36 bg-gray-500/10 dark:bg-border/70"></div>
            </div>
            <div className="flex ml-auto">
              <div className="relative w-6 h-6 rounded-full bg-gradient-to-b from-blue-600 to-sky-400"></div>
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="relative flex items-end w-full p-5 border rounded-lg shadow-sm dark:shadow-lg border-gray-100/70 dark:border-border/20 bg-gray-50/50 dark:bg-secondary">
            <div className="">
              <div className="h-5 rounded-md w-28 bg-gray-500/10 dark:bg-border"></div>
              <div className="h-5 mt-3 rounded-md w-52 bg-gray-500/10 dark:bg-border/70"></div>
            </div>
            <div className="flex ml-auto">
              <div className="relative w-6 h-6 rounded-full bg-gradient-to-b from-emerald-600 to-emerald-400"></div>
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="relative">
            <div className="relative flex items-end w-full p-5 border rounded-lg shadow-sm dark:shadow-lg border-gray-100/70 dark:border-border/20 bg-gray-50/50 dark:bg-secondary">
              <div className="">
                <div className="w-40 h-5 rounded-md bg-gray-500/10 dark:bg-border"></div>
                <div className="h-5 mt-3 rounded-md w-28 bg-gray-500/10 dark:bg-border/70"></div>
              </div>
              <div className="flex ml-auto">
                <div className="relative w-6 h-6 rounded-full bg-gradient-to-b from-purple-600 to-fuchsia-400"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmptyFeedbackBoard
