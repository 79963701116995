import { ISubmission } from '@/interfaces/ISubmission'
import React from 'react'
import PostExternalLink from './PostExternalLink'
import ConfirmationModal from './ConfirmationModal'
import { KeyedMutator } from 'swr'
import { toast } from 'sonner'
import { unlinkJiraIssue } from 'network/lib/organization'

const JiraLinks: React.FC<{
  submission: ISubmission
  mutateSubmissions: KeyedMutator<any>
}> = ({ submission, mutateSubmissions }) => {
  const [showModal, setShowModal] = React.useState(false)

  const unlink = () => {
    unlinkJiraIssue({ submissionId: submission?.id })
      .then((resp: any) => {
        if (resp.data.success) {
          mutateSubmissions()
          toast.success('Issue successfully unlinked')
        }
      })
      .catch((err: any) => {
        console.log(err)
        toast.error('Something went wrong')
      })
  }

  if (!submission?.jiraIssueId || !submission?.jiraIssueUrl) return null

  return (
    <>
      <ConfirmationModal
        title="Unlink Jira Issue"
        callBack={unlink}
        description="Are you sure you want to unlink this issue?"
        open={showModal}
        setOpen={setShowModal}
        buttonTxt="Unlink"
      />
      <div>
        <div className="mt-3">
          <PostExternalLink
            bgColor="bg-blue-100 dark:bg-blue-400/10"
            onClick={() => {
              setShowModal(true)
            }}
            icon={
              <svg
                className="w-3.5 -ml-px -mt-px h-3.5 text-[#1868DB]/80 dark:text-blue-200/80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 33 28"
              >
                <path
                  d="M27.006 4H15.45a5.21 5.21 0 0 0 5.212 5.211h2.125v2.058A5.21 5.21 0 0 0 28 16.48V4.994A.995.995 0 0 0 27.006 4Z"
                  fill="currentColor"
                />
                <path
                  d="M21.28 9.76H9.726a5.21 5.21 0 0 0 5.211 5.211h2.126v2.058a5.21 5.21 0 0 0 5.211 5.211V10.754a.995.995 0 0 0-.994-.994Z"
                  fill="currentColor"
                />
                <path
                  d="M15.554 15.52H4a5.21 5.21 0 0 0 5.211 5.211h2.126v2.057A5.21 5.21 0 0 0 16.55 28V16.514a.995.995 0 0 0-.995-.994Z"
                  fill="currentColor"
                />
              </svg>
            }
            link={submission.jiraIssueUrl}
            title={`[${submission.jiraIssueId}] - ${submission.jiraIssueUrl}`}
          />
        </div>
      </div>
    </>
  )
}

export default JiraLinks
