import { mergeAttributes, Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import MulticodeComponent from './MulticodeComponent'
import { v4 as uuid } from 'uuid'
import { common, createLowlight } from 'lowlight'

const lowlight = createLowlight(common)

export default Node.create({
  name: 'multicodeComponent',
  group: 'block',
  content: 'block',
  draggable: true,
  selectable: false,
  code: true,
  atom: true,
  whitespace: 'pre',
  isolating: false,

  addOptions() {
    return {
      lowlight,
    }
  },

  parseHTML() {
    return [
      {
        tag: 'multicode-component',
      },
    ]
  },

  addAttributes() {
    const id = uuid()
    return {
      data: {
        default: JSON.stringify([{ name: 'HTML', value: '', id: id, language: 'javascript' }]),
      },
      activeCode: {
        default: id,
      },
    }
  },

  renderHTML({ HTMLAttributes }) {
    return ['multicode-component', mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return ReactNodeViewRenderer(MulticodeComponent)
  },
})
