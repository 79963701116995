import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/radix/DropdownMenu'
import { CodeIcon, CogIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/solid'
import { NodeViewWrapper } from '@tiptap/react'
import { v4 as uuid } from 'uuid'
import TextareaAutosize from 'react-textarea-autosize'
import CodeArea from '@/components/CodeArea'
import { common } from 'lowlight'
import React from 'react'
import ModularComboBox from '@/components/radix/ModularComboBox'
import { CommandGroup, CommandHeading, CommandItem } from '@/components/radix/Command'

const MulticodeComponent: React.FC<any> = (props) => {
  const data = JSON.parse(props.node.attrs.data)
  const activeValue = data.find((item: any) => item.id === props.node.attrs.activeCode)

  const handleDescriptionChange = (event: any) => {
    props.updateAttributes({
      data: JSON.stringify(
        data.map((item: any) => {
          if (item.id === activeValue?.id) {
            return { ...item, value: event.target.value }
          }
          return item
        })
      ),
    })
  }

  const handleNameChange = (event: any, id: string) => {
    props.updateAttributes({
      data: JSON.stringify(
        data.map((item: any) => {
          if (item.id === id) {
            return { ...item, name: event.target.value }
          }
          return item
        })
      ),
    })
  }

  const handleLanguageChange = (lang: string, id: string) => {
    props.updateAttributes({
      data: JSON.stringify(
        data.map((item: any) => {
          if (item.id === id) {
            return { ...item, language: lang }
          }
          return item
        })
      ),
    })
  }

  const handleRemoveItem = (id: string) => {
    if (data.length <= 1) {
      // Don't remove if it's the last item
      return
    }
    const newData = data.filter((item: any) => item.id !== id)
    const newActiveCode = newData.length ? newData[0].id : null

    props.updateAttributes({
      data: JSON.stringify(newData),
      activeCode: newActiveCode,
    })
  }

  return (
    <NodeViewWrapper>
      <CodeArea
        lowlight={props?.extension?.options?.lowlight}
        activeItem={activeValue?.id}
        updateAttributes={props.updateAttributes}
        activeValue={activeValue}
        languagePicker={
          <ModularComboBox
            searchableDisplayName="languages"
            TriggerButton={() => (
              <button className="mt-1 p-0 flex items-center justify-center mr-2.5 dashboard-secondary !w-6 !h-6 z-50 relative">
                <CodeIcon className="!w-3 !h-3 secondary-svg flex-shrink-0" />
              </button>
            )}
            CommandItems={({ closeComboBox }) => (
              <CommandGroup
                heading={
                  <CommandHeading
                    text={`Selected: ${activeValue?.language || 'JavaScript'}`}
                    icon={<CodeIcon className="w-3 h-3 secondary-svg flex-shrink-0" />}
                  />
                }
              >
                {Object.keys(common).map((lang) => (
                  <CommandItem
                    key={lang}
                    onSelect={() => {
                      handleLanguageChange(lang, activeValue?.id)
                      closeComboBox()
                    }}
                    className="capitalize"
                  >
                    {lang || 'JavaScript'}
                  </CommandItem>
                ))}
              </CommandGroup>
            )}
            popoverContentProps={{
              align: 'end',
            }}
          />
        }
        editorElement={
          <TextareaAutosize
            onChange={handleDescriptionChange}
            autoFocus={false}
            rows={1}
            value={activeValue?.value}
            placeholder="Code here"
            className="p-0 text-sm dark:!text-transparent text-transparent inset-0 whitespace-pre-wrap bg-transparent border-0 rounded-none shadow-none resize-none placeholder:text-gray-300/60 dark:bg-transparent ring-0 custom-scrollbar-stronger focus:ring-0 z-40 relative"
          />
        }
        examples={data}
        menu={
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <button className="h-6 w-6 ml-1.5 p-0 flex items-center justify-center dashboard-secondary">
                <CogIcon className="!w-3 !mr-0 !h-3 secondary-svg" />
              </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-64 p-2 pt-0" align="end">
              <div className=" divide-y dashboard-divide">
                {data.map((item: any) => (
                  <div key={item.id} className="flex items-center gap-1">
                    <input
                      type="text"
                      value={item.name}
                      onChange={(e) => handleNameChange(e, item.id)}
                      className="flex-1 p-1 py-3 mr-2 text-sm font-medium bg-transparent border-0 rounded dark:bg-transparent focus:ring-0"
                    />

                    <button onClick={() => handleRemoveItem(item.id)} className="">
                      <TrashIcon className="w-4 h-4 secondary-svg" />
                    </button>
                  </div>
                ))}
              </div>
              <DropdownMenuItem
                className="w-full mx-0 hover:mx-0 items-center justify-center dropdown-item"
                onSelect={() => {
                  const newId = uuid()
                  props.updateAttributes &&
                    props.updateAttributes({
                      data: JSON.stringify([
                        ...data,
                        { name: 'New', value: '', id: newId, language: 'javascript' },
                      ]),
                      activeCode: newId,
                    })
                }}
              >
                <PlusCircleIcon className="w-4 h-4 secondary-svg mr-1.5" />
                Add new
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        }
      />
    </NodeViewWrapper>
  )
}

export default MulticodeComponent
