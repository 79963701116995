import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/radix/DropdownMenu'
import { useCurrentOrganization, useUserOrganizations } from '@/data/organization'
import { redirectAdminToOrgById } from '@/lib/redirectAdminToOrg'
import { PlusCircleIcon } from '@heroicons/react/solid'
import React from 'react'

const OrganizationPicker: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { orgResults, isOrganizationsLoading, isOrganizationsError } = useUserOrganizations()

  const { org } = useCurrentOrganization()

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <div>{children}</div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="w-56 max-h-96 overflow-auto custom-scrollbar"
        align="start"
        side="right"
      >
        <DropdownMenuLabel>Your organizations</DropdownMenuLabel>
        {orgResults &&
          orgResults.results.map((item) => (
            <DropdownMenuItem
              key={item.id}
              onSelect={() => {
                redirectAdminToOrgById(item.id, '/posts')
              }}
            >
              <div className="flex items-center justify-center flex-shrink-0 w-5 h-5 mr-2 overflow-hidden rounded-full ">
                {item?.picture ? (
                  <img width={20} height={20} src={item?.picture} alt="" />
                ) : (
                  <span className="text-xs text-gray-200">A</span>
                )}
              </div>
              <span className="first-letter:uppercase">{item.displayName}</span>
            </DropdownMenuItem>
          ))}
        <DropdownMenuItem
          onSelect={() => {
            window.location.href = 'https://auth.featurebase.app/register'
          }}
          icon={PlusCircleIcon}
        >
          Create new
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default OrganizationPicker
