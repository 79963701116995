import React, { memo, useMemo } from 'react'
import {
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarHeader,
} from '@/components/radix/Sidebar'
import { useCurrentOrganization } from '@/data/organization'
import { useAtom } from 'jotai'
import { createPostAtom } from '@/atoms/displayAtom'
import { Button } from '@/components/radix/Button'
import { CollapsibleNavSection } from './CollapsibleNavSection'
import Link from 'next/link'
import { useRouter } from 'next/router'

import {
  CogIcon,
  UsersIcon,
  CreditCardIcon,
  LockClosedIcon,
  ColorSwatchIcon,
  MailIcon,
  MenuIcon,
  IdentificationIcon,
  UserGroupIcon,
  BanIcon,
  GlobeIcon,
  HomeIcon,
  CollectionIcon,
  RefreshIcon,
  MapIcon,
  ViewGridAddIcon,
  BellIcon,
  TerminalIcon,
  DocumentDownloadIcon,
  TagIcon,
  StatusOnlineIcon,
  ArrowUpIcon,
  PuzzleIcon,
  ShieldCheckIcon,
  ExclamationIcon,
  CubeIcon,
  EyeOffIcon,
  SupportIcon,
  CodeIcon,
} from '@heroicons/react/solid'

export const navigationSections = [
  {
    title: 'General',
    icon: CogIcon,
    expandable: false,
    items: [
      {
        title: 'Team Members',
        icon: UsersIcon,
        url: '/team',
        helpLink: 'https://help.featurebase.app/en/articles/7663939-invite-your-team',
      },
      {
        title: 'Pricing Plan',
        icon: CreditCardIcon,
        url: '/pricing',
        helpLink: 'https://help.featurebase.app/en/collections/4485490-billing-and-subscription',
      },
    ],
  },
  {
    title: 'Branding & Customizations',
    icon: ColorSwatchIcon,
    expandable: true,
    items: [
      {
        title: 'Branding',
        icon: ColorSwatchIcon,
        url: '/branding',
        helpLink: 'https://help.featurebase.app/en/articles/2083478-add-your-branding',
      },
      { title: 'Notification Emails', icon: MailIcon, url: '/email' },
      {
        title: 'Customize/disable Modules',
        icon: MenuIcon,
        url: '/portal-menu',
        helpLink:
          'https://help.featurebase.app/en/articles/8822324-customizing-the-public-portal-menu',
      },
      {
        title: 'Custom Domains',
        icon: HomeIcon,
        items: [
          {
            title: 'Web Portal',
            icon: LockClosedIcon,
            url: '/custom-domain',
            helpLink:
              'https://help.featurebase.app/en/articles/2474184-custom-domains-in-featurebase',
          },
          {
            title: 'Help Center',
            icon: IdentificationIcon,
            url: '/helpcenter-custom-domain',
            helpLink: 'https://help.featurebase.app/en/articles/4170916-help-center-custom-domain',
          },
        ],
      },
    ],
  },
  {
    title: 'Access & Authentication',
    icon: PuzzleIcon,
    expandable: true,
    items: [
      {
        title: 'Organization Access',
        icon: EyeOffIcon,
        url: '/accessibility',
        helpLink: 'https://help.featurebase.app/en/articles/6416894-public-vs-private-forum',
      },
      {
        title: 'Authentication',
        icon: LockClosedIcon,
        items: [
          { title: 'Single-Sign On', icon: LockClosedIcon, url: '/sso' },
          {
            title: 'SDK & Identity Verification',
            icon: IdentificationIcon,
            url: '/identity-verification',
          },
        ],
      },
    ],
  },
  {
    title: 'Modules',
    icon: GlobeIcon,
    expandable: true,
    items: [
      {
        title: 'Feedback',
        icon: CollectionIcon,
        items: [
          { title: 'Boards & Typography', icon: StatusOnlineIcon, url: '/boards' },
          {
            title: 'Statuses',
            icon: StatusOnlineIcon,
            url: '/statuses',
            helpLink: 'https://help.featurebase.app/en/articles/6979960-post-statuses',
          },
          {
            title: 'Tags',
            icon: TagIcon,
            url: '/tags',
            helpLink: 'https://help.featurebase.app/en/articles/6980042-post-tags',
          },
          { title: 'Voting', icon: ArrowUpIcon, url: '/voting' },
          {
            title: 'Custom Fields',
            icon: PuzzleIcon,
            url: '/custom-fields',
            helpLink:
              'https://help.featurebase.app/en/articles/9231869-custom-fields-for-the-post-form',
          },
          {
            title: 'Moderation & Permissions',
            icon: ShieldCheckIcon,
            url: '/permissions',
            helpLink: 'https://help.featurebase.app/collections/6068005-privacy-controls',
          },
          {
            title: 'Prioritization',
            icon: (props: any) => (
              <svg
                {...props}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path d="M12 2.005c-.777 0 -1.508 .367 -1.971 .99l-5.362 6.895c-.89 1.136-.89 3.083 0 4.227l5.375 6.911a2.457 2.457 0 0 0 3.93-.017l5.361-6.894c.89-1.136.89-3.083 0-4.227l-5.375-6.911a2.446 2.446 0 0 0 -1.958-.974z" />
              </svg>
            ),
            url: '/prioritization',
            helpLink:
              'https://help.featurebase.app/en/articles/8907685-using-prioritization-frameworks',
          },
          { title: 'Import / Export', icon: DocumentDownloadIcon, url: '/import-export' },
        ],
      },
      {
        title: 'Roadmap',
        icon: MapIcon,
        url: '/roadmap',
        helpLink: 'https://help.featurebase.app/en/articles/5928161-creating-and-editing-roadmaps',
      },
      {
        title: 'Changelog',
        icon: RefreshIcon,
        url: '/changelog',
        helpLink: 'https://help.featurebase.app/en/articles/0466477-configuring-the-changelog',
      },
      {
        title: 'Help Center',
        icon: (props: any) => (
          <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M11.25 4.533A9.707 9.707 0 0 0 6 3a9.735 9.735 0 0 0-3.25.555.75.75 0 0 0-.5.707v14.25a.75.75 0 0 0 1 .707A8.237 8.237 0 0 1 6 18.75c1.995 0 3.823.707 5.25 1.886V4.533ZM12.75 20.636A8.214 8.214 0 0 1 18 18.75c.966 0 1.89.166 2.75.47a.75.75 0 0 0 1-.708V4.262a.75.75 0 0 0-.5-.707A9.735 9.735 0 0 0 18 3a9.707 9.707 0 0 0-5.25 1.533v16.103Z" />
          </svg>
        ),
        url: '/help-center',
        helpLink: 'https://help.featurebase.app/collections/2964284-help-center',
      },
    ],
  },
  {
    title: 'Users',
    icon: UserGroupIcon,
    expandable: false,
    items: [
      {
        title: 'User Tags',
        icon: UserGroupIcon,
        url: '/roles',
        helpLink: 'https://help.featurebase.app/en/articles/9963170-user-tags',
      },
      {
        title: 'Banned Users',
        icon: BanIcon,
        url: '/banned-users',
        helpLink: 'https://help.featurebase.app/en/articles/1150061-banning-users',
      },
    ],
  },
  {
    title: 'Other',
    icon: PuzzleIcon,
    expandable: true,
    items: [
      {
        title: 'Widgets & Embeds',
        icon: CubeIcon,
        url: '/widget',
      },
      {
        title: 'Notifications',
        icon: BellIcon,
        url: '/notifications',
      },
      {
        title: 'Integrations',
        icon: ViewGridAddIcon,
        url: '/integrations',
        learnToSetup: true,
      },
      {
        title: 'Advanced',
        icon: TerminalIcon,
        items: [
          { title: 'API', icon: TerminalIcon, url: '/api' },
          {
            title: 'Webhooks',
            icon: ExclamationIcon,
            url: '/webhooks',
          },
        ],
      },
      { title: 'Danger Zone', icon: ExclamationIcon, url: '/danger-zone' },
    ],
  },
]

interface ProcessedItem {
  title: string
  icon?: React.ComponentType<any>
  url?: string
  items?: ProcessedItem[]
  isActive?: boolean
  beta?: boolean
}

interface ProcessedSection {
  title: string
  icon: React.ComponentType<any>
  expandable: boolean
  items: ProcessedItem[]
}

/** A memoized component that renders the sidebar sections */
const MemoizedSections = memo(function MemoizedSections({
  sections,
}: {
  sections: ProcessedSection[]
}) {
  return (
    <>
      {sections.map((section) => {
        if (!section.expandable) {
          return (
            <SidebarGroup key={section.title}>
              <SidebarGroupLabel>{section.title}</SidebarGroupLabel>
              {section.items.map((item) => {
                return (
                  <Link href={'/dashboard/settings' + item.url} key={item.title}>
                    <Button
                      icon={item.icon}
                      variant={item.isActive ? 'menuActive' : 'menu'}
                      size="sm"
                    >
                      <span className="truncate">{item.title}</span>
                    </Button>
                  </Link>
                )
              })}
            </SidebarGroup>
          )
        }

        // Expandable sections
        return (
          <CollapsibleNavSection
            key={section.title}
            title={section.title}
            items={section.items.map((item) => ({
              ...item,
              url: '/dashboard/settings' + item.url,
              accordionContent:
                item?.items && item.items.length > 0 ? (
                  <>
                    {item.items.map((subitem) => (
                      <Link href={'/dashboard/settings' + subitem.url} key={subitem.title}>
                        <Button variant={subitem.isActive ? 'menuActive' : 'menu'} size="xs">
                          <span className="truncate">{subitem.title}</span>
                        </Button>
                      </Link>
                    ))}
                  </>
                ) : null,
            }))}
          />
        )
      })}
    </>
  )
})

const SettingsSidebar: React.FC = () => {
  const { org } = useCurrentOrganization()
  const [createPost, setCreatePost] = useAtom(createPostAtom)
  const router = useRouter()

  const processedSections = useMemo(() => {
    const currentPath = router.pathname
    return navigationSections.map((section) => {
      const items = section.items.map((item) => {
        // Determine if this item or any of its nested items is active
        const isActiveItem = item.url ? currentPath.includes(item.url) : false

        let processedSubItems: ProcessedItem[] = []
        // @ts-ignore
        if (item?.items && item.items.length > 0) {
          // @ts-ignore
          processedSubItems = item.items.map((sub) => ({
            ...sub,
            isActive: currentPath.includes(sub.url ?? ''),
          }))
        }

        return {
          ...item,
          isActive: isActiveItem,
          items: processedSubItems,
        }
      })

      return {
        ...section,
        items,
      }
    })
  }, [router.pathname])

  return (
    <>
      <SidebarHeader>
        <h2 className="cursor-pointer">Settings</h2>
      </SidebarHeader>
      <SidebarContent>
        <MemoizedSections sections={processedSections} />
      </SidebarContent>
      <SidebarFooter>
        <SidebarGroup>
          <SidebarGroupLabel>Resources</SidebarGroupLabel>
          <a
            href={'https://help.featurebase.app/collections/7436840-recommended-installation'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button size="sm" variant="menu" icon={CodeIcon}>
              Visit Developer Docs
            </Button>
          </a>
          <a
            href={'https://discord.com/invite/9wFwqHW4HZ'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              size="sm"
              variant="menu"
              icon={(props: any) => (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill={'none'}
                  {...props}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.60211 5.48045C9.60211 5.48045 10.7147 5.24976 12 5.24976C13.2853 5.24976 14.3979 5.48045 14.3979 5.48045L15 4.24976C17.5 4.74976 19.5 5.68698 19.5 5.68698C19.5 5.68698 22 8.24976 22 17.2498C19.8658 18.8513 17 19.7498 17 19.7498L15.9484 18.1003L17.5824 17.1752L17.1069 16.8035C17.1069 16.8035 15.2049 17.9447 12 17.9447C8.79507 17.9447 6.89313 16.8035 6.89313 16.8035L6.41764 17.1752L8.05159 18.1003L7 19.7498C7 19.7498 4.13419 18.8513 2 17.2498C2 8.24976 4.5 5.68698 4.5 5.68698C4.5 5.68698 6.5 4.74976 9 4.24976L9.60211 5.48045ZM17.25 12.7498C17.25 13.8543 16.4665 14.7498 15.5 14.7498C14.5335 14.7498 13.75 13.8543 13.75 12.7498C13.75 11.6452 14.5335 10.7498 15.5 10.7498C16.4665 10.7498 17.25 11.6452 17.25 12.7498ZM8.5 14.7498C9.4665 14.7498 10.25 13.8543 10.25 12.7498C10.25 11.6452 9.4665 10.7498 8.5 10.7498C7.5335 10.7498 6.75 11.6452 6.75 12.7498C6.75 13.8543 7.5335 14.7498 8.5 14.7498Z"
                    fill="currentColor"
                  />
                </svg>
              )}
            >
              Join our Discord community
            </Button>
          </a>
        </SidebarGroup>
      </SidebarFooter>
    </>
  )
}

export default memo(SettingsSidebar)
