'use client'

import * as React from 'react'
import { DialogProps } from '@radix-ui/react-dialog'
import { Command as CommandPrimitive, useCommandState } from 'cmdk'

import { Dialog, DialogContent } from './Dialog'
import { cn } from '@/lib/utils'

const Command = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({ className, ...props }, ref) => (
  <CommandPrimitive
    ref={ref}
    className={cn('flex h-full w-full flex-col overflow-hidden rounded-md', className)}
    {...props}
  />
))
Command.displayName = CommandPrimitive.displayName

interface CommandDialogProps extends DialogProps {}

const CommandDialog = ({ children, ...props }: CommandDialogProps) => {
  return (
    <Dialog {...props}>
      <DialogContent className="p-0 overflow-hidden shadow-2xl">
        <Command className="[&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-muted-foreground [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:h-5 [&_[cmdk-input-wrapper]_svg]:w-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:h-5 [&_[cmdk-item]_svg]:w-5">
          {children}
        </Command>
      </DialogContent>
    </Dialog>
  )
}

const CommandInput = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input> & {
    setNewItem?: (query: string) => void
    setPages?: (query: string) => void
    setOpen?: (query: boolean) => void
    type?: string
    pages?: string[]
    allowNewCreation?: boolean // Note that this is not optional here
    initiateNewItemCreation?: (name: string) => void
    goToPreviousPage?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  }
>((props, ref) => {
  const { className, allowNewCreation, initiateNewItemCreation, goToPreviousPage, ...restProps } =
    props

  const searchCount = useCommandState((state) => {
    return state.filtered.count
  })
  const search = useCommandState((state) => {
    return state.search
  })

  return (
    <div className="flex items-center border-b border-black/10 dark:border-white/5">
      {/* <Search className="w-4 h-4 mr-2 opacity-50 shrink-0" /> */}
      <CommandPrimitive.Input
        onKeyDown={(e) => {
          // Escape goes to previous page
          // Backspace goes to previous page when search is empty
          if (allowNewCreation) {
            if (searchCount === 0) {
              if (e.key === 'Enter') {
                initiateNewItemCreation && initiateNewItemCreation(search)
              }
            }
            if (e.key === 'Escape' || (e.key === 'Backspace' && !search)) {
              e.preventDefault()
              goToPreviousPage && goToPreviousPage(e)
            }
          }
        }}
        ref={ref}
        className={cn(
          'placeholder:text-foreground-muted flex border-0 focus:outline-none focus:ring-0 dark:bg-transparent h-9 w-full rounded-md bg-transparent py-3 text-base sm:text-sm outline-none disabled:cursor-not-allowed disabled:opacity-50',
          className
        )}
        {...restProps}
      />
    </div>
  )
})

CommandInput.displayName = CommandPrimitive.Input.displayName

const CommandList = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.List
    ref={ref}
    className={cn(
      'max-h-[500px] overflow-y-auto custom-scrollbar-stronger overflow-x-hidden',
      className
    )}
    {...props}
  />
))

CommandList.displayName = CommandPrimitive.List.displayName

const CommandEmpty = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Empty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>((props, ref) => {
  return <CommandPrimitive.Empty ref={ref} className="py-2.5 px-3 text-left text-sm" {...props} />
})

CommandEmpty.displayName = CommandPrimitive.Empty.displayName

const CommandGroup = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Group>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Group
    ref={ref}
    className={cn(
      'overflow-y-auto overflow-x-hidden text-foreground [&_[cmdk-group-heading]]:px-1 py-1 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-[11px] [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-muted-foreground',
      className
    )}
    {...props}
  />
))

CommandGroup.displayName = CommandPrimitive.Group.displayName

const CommandSeparator = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Separator
    ref={ref}
    className={cn('-mx-1 my-1 h-px bg-black/10 dark:bg-white/5', className)}
    {...props}
  />
))
CommandSeparator.displayName = CommandPrimitive.Separator.displayName

const CommandItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item> & {
    Icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>
  }
>(({ className, Icon, ...props }, ref) => (
  <CommandPrimitive.Item
    ref={ref}
    className={cn(
      'relative flex dropdown-item cursor-default select-none items-center text-sm outline-none aria-selected:active-dropdown-item data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      className
    )}
    {...props}
  >
    {Icon && (
      <span className="secondary-svg mr-1.5 flex-shrink-0">
        <Icon />
      </span>
    )}
    {props.children}
  </CommandPrimitive.Item>
))

CommandItem.displayName = CommandPrimitive.Item.displayName

const CommandShortcut = ({ className, ...props }: React.HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span
      className={cn('ml-auto text-xs tracking-widest text-muted-foreground', className)}
      {...props}
    />
  )
}
CommandShortcut.displayName = 'CommandShortcut'

const CommandHeading = ({ icon, text, ...props }: { icon: JSX.Element; text: string }) => {
  return (
    <div className="flex items-center justify-between dark:bg-border/60 -mx-1 -mb-0.5 px-1.5 -mt-2.5 py-0.5 uppercase border-b border-black/5 dark:border-border shadow-sm tracking-wide">
      {text}
      <div className="w-3 h-3 secondary-svg">{icon}</div>
    </div>
  )
}

const SubItem = (props: any) => {
  const search = useCommandState((state) => state.search)
  if (!search) return null
  return <CommandItem {...props} />
}

const cmdKCustomerFilterFunction = (
  value: string,
  search: string,
  array: Array<any>,
  keyName: string
) => {
  if (
    array
      ?.find((elem) => {
        if (elem?._id) {
          return elem._id === value
        } else {
          return elem.id === value
        }
      })
      ?.[keyName]?.toLocaleLowerCase()
      ?.includes(search?.toLocaleLowerCase())
  ) {
    return 1
  } else {
    return 0
  }
}

export {
  Command,
  CommandDialog,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandShortcut,
  CommandSeparator,
  SubItem,
  cmdKCustomerFilterFunction,
  CommandHeading,
}
