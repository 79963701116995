import { mergeAttributes, Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import CalloutComponent from './CalloutComponent'

export default Node.create({
  name: 'calloutComponent',
  group: 'block',
  content: 'block*',
  draggable: true,

  parseHTML() {
    return [
      {
        tag: 'callout-component',
      },
    ]
  },

  addAttributes() {
    return {
      color: {
        default: 'Accent',
      },
    }
  },

  renderHTML({ HTMLAttributes }) {
    return ['callout-component', mergeAttributes(HTMLAttributes), 0]
  },

  addNodeView() {
    return ReactNodeViewRenderer(CalloutComponent)
  },
})
