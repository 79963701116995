import { useRouter } from 'next/router'
import DashboardWrapper from './DashboardWrapper'
import SettingsSidebar from './dashboard/menu/SettingsSidebar'
import DashboardUpperBar from './DashboardUpperBar'
import { navigationSections } from './dashboard/menu/SettingsSidebar'
import { useMemo } from 'react'
import { ArrowRightIcon } from '@heroicons/react/solid'
import { BookIcon } from 'lucide-react'

interface ActiveNavItem {
  section: string
  item: string
  subItem?: string
}

const useActiveNavigationItem = (pathname: string): ActiveNavItem | null => {
  return useMemo(() => {
    for (const section of navigationSections) {
      for (const item of section.items) {
        // Check main item
        if (item.url && pathname.includes(item.url)) {
          return {
            section: section.title,
            item: item.title,
            itemUrl: item.url,
            // @ts-ignore
            helpLink: item?.helpLink,
          }
        }

        // @ts-ignore
        if (item.items) {
          // @ts-ignore
          for (const subItem of item.items) {
            if (subItem.url && pathname.includes(subItem.url)) {
              return {
                section: section.title,
                item: item.title,
                subItem: subItem.title,
                subItemUrl: subItem.url,
                // @ts-ignore
                helpLink: subItem?.helpLink,
              }
            }
          }
        }
      }
    }
    return null
  }, [pathname])
}

const SettingsWrapper: React.FC<{ children: React.ReactNode; wide?: boolean; title: string }> = ({
  children,
  wide,
  title,
}) => {
  const router = useRouter()
  const checkIfSettingsPricing = router.pathname.includes('settings/pricing')
  const activeNavItem = useActiveNavigationItem(router.pathname)

  const nameMaps = {
    'Pricing Plan': 'Pricing',
    'Branding & Customizations': 'Branding',
    Roadmap: 'Roadmaps',
    Team: 'Team members',
    'Portal menu': 'Customizing modules',
    'Custom domain': 'Custom domains',
    Access: 'Organization access',
    Changelog: 'Changelogs',
    'Help center': 'the Help center',
  }

  return (
    <DashboardWrapper
      darkerLightmodeBg={true}
      sidebarContent={<SettingsSidebar />}
      title={title + ' - Settings'}
      upperBar={<></>}
    >
      <div className="">
        <div
          className={`${
            checkIfSettingsPricing ? 'max-w-5xl' : wide ? 'max-w-7xl' : 'max-w-3xl'
          } mx-auto mt-7 mb-20 dashboard-padding`}
        >
          {children}
          {/* @ts-ignore */}
          {activeNavItem?.helpLink && (
            <div className="mt-auto w-full pt-8">
              <div className="flex px-6 py-4 w-full rounded-lg bg-white/60 border border-gray-100/50 dark:border-transparent dark:bg-secondary/60 items-center justify-between gap-2 mx-auto">
                <p className="text-sm font-normal text-gray-300 dark:text-foreground/80">
                  Have a question about{' '}
                  <span className="font-medium">
                    {nameMaps?.[title as keyof typeof nameMaps] || title}
                  </span>
                  ?
                </p>
                <p className="text-sm font-medium">
                  <a
                    // @ts-ignore
                    href={activeNavItem?.helpLink}
                    target="_blank"
                    rel="noreferrer"
                    className="dark:text-foreground/80 text-foreground hover:text-gray-700 dark:hover:text-gray-100 main-transition flex items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="secondary-svg mr-1.5"
                    >
                      <path d="M10.75 16.82A7.462 7.462 0 0 1 15 15.5c.71 0 1.396.098 2.046.282A.75.75 0 0 0 18 15.06v-11a.75.75 0 0 0-.546-.721A9.006 9.006 0 0 0 15 3a8.963 8.963 0 0 0-4.25 1.065V16.82ZM9.25 4.065A8.963 8.963 0 0 0 5 3c-.85 0-1.673.118-2.454.339A.75.75 0 0 0 2 4.06v11a.75.75 0 0 0 .954.721A7.506 7.506 0 0 1 5 15.5c1.579 0 3.042.487 4.25 1.32V4.065Z" />
                    </svg>
                    View documentation
                  </a>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </DashboardWrapper>
  )
}

export default SettingsWrapper
