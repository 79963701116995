import React, { useRef, useState } from 'react'
import { z } from 'zod'
import { toast } from 'sonner'
import { ArrowLeftIcon } from '@heroicons/react/solid'
import { cn } from '@/lib/utils'
import Loader from './Loader'
import { acceptInvite, registerWithPassword } from 'network/lib/user' // Update the import path as needed
import { useTranslation } from 'next-i18next'
import { Button } from './radix/Button'
import { Input } from './radix/Input'
import ReCAPTCHA from 'react-google-recaptcha'
import { getRecaptchaToken } from '@/lib/authHandler'
import { useCurrentOrganization } from '@/data/organization'
import { useUser } from '@/data/user'

const schema = z.object({
  name: z.string().nonempty('Name is required'),
  email: z.string().email('Invalid email format'),
  password: z.string().min(5, 'Password must be at least 5 characters long'),
  type: z.enum(['admin', 'customer']).default('customer'),
  inviteId: z.string().optional(),
})

const InviteRegisterForm: React.FC<{
  email: string
  inviteId: string
  type?: 'admin' | 'customer'
  resetAcceptInviteAction?: () => void
  onSuccess?: () => void
}> = ({ email, inviteId, type = 'customer', resetAcceptInviteAction, onSuccess }) => {
  const { t } = useTranslation()
  const [registerData, setRegisterData] = useState({
    name: '',
    email: email || '',
    password: '',
    // confirmPassword: '',
    type: type,
    inviteId: inviteId,
  })
  const recaptchaRef = useRef<any>()

  const { org } = useCurrentOrganization()
  const { user } = useUser()

  const [errorName, setErrorName] = useState(false)
  const [errorEmail, setErrorEmail] = useState(false)
  const [errorPassword, setErrorPassword] = useState(false)
  // const [errorConfirmPassword, setErrorConfirmPassword] = useState(false)

  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    try {
      // Validate form data
      schema.parse(registerData)
      setLoading(true)

      const { inviteId, ...registerInfo } = registerData

      registerWithPassword({
        ...registerInfo,
        recaptchaToken: await getRecaptchaToken(recaptchaRef, org, user, false),
      })
        .then(async (res) => {
          if (res.data.success) {
            await acceptInvite({
              inviteId,
            })
              .then((res) => {
                if (res.data.success) {
                  toast.success('Successfully registered, reloading page...')
                  if (onSuccess) {
                    onSuccess()
                  } else {
                    window.location.href = '/'
                  }
                }
              })
              .catch((err) => {
                toast.error(err.response?.data?.message || t('something-went-wrong'))
              })
          } else {
            toast.error(res.data.message || t('something-went-wrong'))
          }
        })
        .catch((err) => {
          if (err.response?.data?.message === 'User already registered') {
            resetAcceptInviteAction && resetAcceptInviteAction()
            toast.error(
              'You have already registered with this email, please sign in with the account you created.'
            )
          } else {
            toast.error(err.response?.data?.message || t('something-went-wrong'))
          }
        })
        .finally(() => {
          setLoading(false)
        })
    } catch (error) {
      if (error instanceof z.ZodError) {
        console.error(error.errors)
        setErrorName(!!error.errors.find((e) => e.path.includes('name')))
        setErrorEmail(!!error.errors.find((e) => e.path.includes('email')))
        setErrorPassword(!!error.errors.find((e) => e.path.includes('password')))
        // setErrorConfirmPassword(!!error.errors.find((e) => e.path.includes('confirmPassword')))
      } else {
        toast.error(t('something-went-wrong'))
      }
    }
  }

  return (
    <div>
      <div className="relative flex items-center justify-center w-10 h-10 p-4 mb-3">
        <div className="absolute inset-0 dark:bg-emerald-500/25 bg-emerald-300/30 blur-md"></div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="relative flex-none w-10 h-10"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2Z"
            opacity=".3"
            className="fill-emerald-200/80 stroke-emerald-300 dark:fill-emerald-500/60 dark:stroke-emerald-400/80"
          ></path>
          <path
            fill="currentColor"
            className="text-emerald-400"
            d="m15.535 8.381-4.95 4.95-2.12-2.121a1 1 0 1 0-1.415 1.414l2.758 2.758a1.1 1.1 0 0 0 1.556 0l5.586-5.586a1 1 0 1 0-1.415-1.415Z"
          ></path>
        </svg>
      </div>

      <h2 className="text-lg font-medium text-gray-600 dark:text-white">
        {t('complete-your-registration')}
      </h2>
      <p className="mt-1 text-sm">{t('please-provide-your-details')}</p>

      <div className="mt-3 flex flex-col gap-3">
        <Input
          type="text"
          value={registerData.name}
          onChange={(e) => {
            setRegisterData((prev) => ({ ...prev, name: e.target.value }))
            setErrorName(false)
          }}
          error={errorName}
          placeholder={t('username')}
        />
        <Input
          type="email"
          value={registerData.email}
          disabled
          error={errorEmail}
          placeholder={t('email')}
        />
        <Input
          type="password"
          value={registerData.password}
          onChange={(e) => {
            setRegisterData((prev) => ({ ...prev, password: e.target.value }))
            setErrorPassword(false)
          }}
          placeholder={t('password')}
          error={errorPassword}
        />
      </div>

      <div className="flex justify-between gap-2 mt-4 ">
        {/* {onBack && (
          <button
            onClick={onBack}
            className="flex items-center justify-center mt-1 text-xs text-background-accent cursor-pointer dark:text-foreground hover:underline main-transition unstyled-button"
          >
            <ArrowLeftIcon className="w-4 h-3 mr-1" /> {t('back')}
          </button>
        )} */}
        <div></div>
        <Button loading={loading} onClick={handleSubmit}>
          {t('save-and-continue')}
        </Button>
      </div>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6Le2uucZAAAAABsXNpeE8_pJ400DDC9aKVsJQKJy"
      />
    </div>
  )
}

export default InviteRegisterForm
