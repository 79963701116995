import React from 'react'
import FeaturedIcon from './docs/FeaturedIcon'
import { IHelpCenterIcon } from '@/interfaces/IHelpCenter'
import { cn } from '@/lib/utils'

const BoardIcon: React.FC<{
  icon?: IHelpCenterIcon
  className?: string
  small?: boolean
  smallMargin?: boolean
  noMargin?: boolean
}> = ({ icon, className, small = true, smallMargin = true, noMargin = false }) => {
  if (!icon) return null
  return (
    <span
      className={cn(
        noMargin ? '' : smallMargin ? 'mr-1' : 'mr-1.5',
        icon?.type === 'emoji' ? (noMargin ? '' : '-ml-[2px]') : 'secondary-svg',
        className
      )}
    >
      <FeaturedIcon unsized={small} icon={icon as any} inButton={true} small={small} />
    </span>
  )
}

export default BoardIcon
