import { cn } from '@/lib/utils'
import React from 'react'

const SurveyItem: React.FC<{
  content: string
  index: number
  isVoteSurvey?: boolean
  small?: boolean
  callback: () => void
}> = ({ content, index, isVoteSurvey, small, callback }) => {
  const getUrgencyColor = (index: number) => {
    switch (index) {
      case 0:
        return 'bg-gray-500'
      case 1:
        return 'bg-green-500'
      case 2:
        return 'bg-yellow-500'
      case 3:
        return 'bg-red-500'
    }
  }

  return (
    <button
      onClick={callback}
      tabIndex={-1}
      className={cn(
        'main-transition w-full min-w-[80px] shadow-sm whitespace-nowrap dark:ring-0 overflow-hidden overflow-ellipsis line-clamp-1 flex hover:shadow-md dark:hover:shadow hover:bg-gray-50/50 items-center justify-center px-2 cursor-pointer py-1.5 text-xs font-medium text-background-accent border rounded-md dark:shadow-none dark:border-border dark:text-gray-100 dark:hover:bg-border dark:hover:border-dark-accent dark:bg-transparent up-element',
        isVoteSurvey &&
          'dark:hover:bg-dark-accent dark:border-dark-accent dark:hover:border-gray-500 dark:bg-border',
        small && 'py-1'
      )}
    >
      {content}
    </button>
  )
}

export default SurveyItem
