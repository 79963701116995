import React from 'react'

const InlineError: React.FC<{ error?: string }> = ({ error }) => {
  if (error) {
    return <p className="text-sm text-red-500/80 mt-0.5">{error}</p>
  } else {
    return null
  }
}

export default InlineError
