import { IPostCategory } from '@/interfaces/ISubmission'
import { LockClosedIcon } from '@heroicons/react/solid'
import { TooltipProvider, Tooltip, TooltipContent, TooltipTrigger } from '../radix/Tooltip'
import { activeCategoryName, cn } from '@/lib/utils'
import { useTranslation } from 'next-i18next'
import BoardIcon from '../BoardIcon'

export const CategoryContentDisplayer: React.FC<{ category?: IPostCategory }> = ({ category }) => {
  const { i18n, t } = useTranslation()

  const activeName = category ? activeCategoryName(category, i18n?.language) : ''

  return (
    <>
      <BoardIcon smallMargin={true} small={true} icon={category?.icon} />
      {activeName}
      {category?.private && (
        <span
          className={cn(
            'bg-gray-100/40 dark:bg-gray-200/[8%] main-transition leading-none ml-2 flex-shrink-0 text-[11px] backdrop-blur font-semibold py-0.5 px-2 text-foreground rounded-full'
          )}
        >
          {t('internal')}
        </span>
      )}
    </>
  )
}
