import { analyticsEventOriginAtom } from '@/atoms/widgetAtom'
import { useCurrentOrganization } from '@/data/organization'
import { useAtom, useAtomValue } from 'jotai'
import { useUser } from '@/data/user'
import Script from 'next/script'
import React, { useState, useEffect } from 'react'
import { helpCenterUrlPartsAtom } from '@/atoms/orgAtom'
import { i18n } from 'next-i18next'
import UAParser from 'ua-parser-js' // Import ua-parser-js
import { fallbackSessionIdAtom } from '@/atoms/authAtom'
import { getTinybirdFallbackSessionId } from 'network/apiClient'

const Tinybird: React.FC<{ module?: string; customValues?: any }> = ({ module, customValues }) => {
  const { org } = useCurrentOrganization()
  const { user } = useUser()

  // Get active locale
  const locale = i18n?.language === 'default' ? 'en' : i18n?.language

  const eventOrigin = useAtomValue(analyticsEventOriginAtom)
  const helpCenterUrlParts = useAtomValue(helpCenterUrlPartsAtom)

  const [gettingBrowserInfo, setGettingBrowserInfo] = useState(true)
  const fallbackSessionId = getTinybirdFallbackSessionId()

  // State to hold browser and device information
  const [browserInfo, setBrowserInfo] = useState<{ name: string; version: string }>({
    name: 'unknown',
    version: 'unknown',
  })
  const [deviceType, setDeviceType] = useState<string>('unknown')

  useEffect(() => {
    if (typeof navigator !== 'undefined') {
      try {
        const ua = navigator.userAgent
        const parser = new UAParser()
        parser.setUA(ua)
        const result = parser.getResult()

        // Set browser information
        setBrowserInfo({
          name: result.browser.name || 'unknown',
          version: result.browser.version || 'unknown',
        })

        // Determine device type
        const device = result.device.type
        if (device === 'mobile') {
          setDeviceType('mobile')
        } else if (device === 'tablet') {
          setDeviceType('tablet')
        } else {
          setDeviceType('desktop')
        }
      } catch (error) {
        console.error('Error getting browser info', error)
      }
    }
    setGettingBrowserInfo(false)
  }, [])

  // if (
  //   !(
  //     org.name === 'feedback' ||
  //     org.name === 'robiorg4' ||
  //     org.name === 'senja' ||
  //     org.name === 's2f'
  //   )
  // ) {
  //   return null
  // }

  if (!browserInfo?.name) return null

  if (gettingBrowserInfo) return null

  if (process.env.NEXT_PUBLIC_ENV === 'development') {
    return null
  }

  return (
    <Script
      src="https://tb.featurebaseapp.com/tb.js"
      data-host="https://tb.featurebaseapp.com"
      data-token="p.eyJ1IjogIjhiNzg4Mzc5LWEyZGQtNDAxZi1hZjMzLTM2Y2MxYTRiMGQ5YiIsICJpZCI6ICI0N2EwYmNiZC04M2MxLTQwNzAtODQzMS05MGE2ZjBkNWJiYTkiLCAiaG9zdCI6ICJhd3MtZXUtY2VudHJhbC0xIn0.tfMb9G_9NE6EG7qnt30xS2dQOkx62J8ZzA3EAELuBnY"
      // @ts-ignore
      tb_org_id={org?.id || null}
      tb_user_id={user?.id || null}
      tb_user_type={user?.type || null}
      tb_module={module || null}
      tb_event_origin={eventOrigin || null}
      tb_help_center_id={helpCenterUrlParts?.helpCenterId || null}
      tb_locale={locale || null}
      tb_browser={browserInfo.name}
      tb_device={deviceType}
      tb_fallback_session_id={fallbackSessionId}
      {...customValues}
    />
  )
}

export default Tinybird
