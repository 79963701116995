import React, { Fragment } from 'react'
import {
  ArrowUpIcon,
  BanIcon,
  ChatAlt2Icon,
  ChevronUpIcon,
  ClockIcon,
  CogIcon,
  CollectionIcon,
  ExclamationIcon,
  FlagIcon,
  IdentificationIcon,
  InformationCircleIcon,
  LightningBoltIcon,
  LocationMarkerIcon,
  MailIcon,
  OfficeBuildingIcon,
  PlusCircleIcon,
  StarIcon,
  TagIcon,
  UserIcon,
  XIcon,
} from '@heroicons/react/solid'
import RoleChanger from './RoleChanger'
import Image from 'next/legacy/image'
import { useCurrentOrganization } from '../data/organization'
import { KeyedMutator } from 'swr'
import { dateDifference } from './MainPostView'
import { ICustomer } from '../interfaces/IUser'
import Tooltip from './Tooltip'
import { toast } from 'sonner'
import DisplayMemberCheckmark from './AdminCheck'
import UserActivityFeed from './UserActivityFeed'
import { banUser, removeTrackedUser } from 'network/lib/organization'
import { Button } from './radix/Button'
import ConfirmationModal from './ConfirmationModal'
import Loader from './Loader'
import { isMember } from '@/lib/acl'
import { cn } from '@/lib'
import { isPlan } from '@/lib/utils'
import { upgradePlanAtom } from '@/atoms/orgAtom'
import { useAtom } from 'jotai'
import { useUserLocation } from '@/data/user'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './radix/DropdownMenu'
import { BanUserModal } from '@/pages/dashboard/settings/banned-users'
import Link from 'next/link'
import { motion } from 'framer-motion'

export const Description: React.FC<{ content: string }> = ({ content }) => {
  return (
    <Tooltip
      notCentered={true}
      dropDown={
        <>
          <p className="flex items-center text-xs">Click to copy</p>
        </>
      }
      child={
        <>
          <button
            onClick={() => {
              if (navigator.clipboard) {
                toast.success('Copied to clipboard')
                navigator.clipboard.writeText(content)
              }
            }}
            className="w-full text-sm font-medium text-gray-600 truncate cursor-pointer unstyled-button dark:text-gray-100"
          >
            {content}
          </button>
        </>
      }
    />
  )
}

export const CopyText: React.FC<{ children: any; value: string }> = ({ children, value }) => {
  return (
    <Tooltip
      notCentered={true}
      dropDown={
        <>
          <p className="flex items-center text-xs">Click to copy</p>
        </>
      }
      noAlignment={true}
      child={
        <>
          <button
            onClick={(e) => {
              if (navigator.clipboard) {
                e.stopPropagation()
                toast.success('Copied to clipboard')
                navigator.clipboard.writeText(value)
              }
            }}
            className="text-sm cursor-pointer unstyled-button"
          >
            {children}
          </button>
        </>
      }
    />
  )
}

const UsersInfo: React.FC<{
  activeUser: ICustomer
  mutateTrackedUsers?: KeyedMutator<any[]>
  activity?: boolean
  postView?: boolean
  setOpenSlideOver?: any
}> = ({ activeUser, mutateTrackedUsers, activity = false, postView = false, setOpenSlideOver }) => {
  const { org } = useCurrentOrganization()
  const { userId, customFields: identifiedUserCustomFields } = activeUser
  const [confirmDelete, setConfirmDelete] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const [activeView, setActiveView] = React.useState('details')

  const [banUserModalOpen, setBanUserModalOpen] = React.useState(false)
  const [upgradePlan, setUpgradePlan] = useAtom(upgradePlanAtom)

  const { longitude, latitude, location } = useUserLocation(activeUser?.id || '')

  const [imageLoaded, setImageLoaded] = React.useState(false)

  const getRoleNameFromId = (id: string) => {
    return org?.roles?.find((role) => role.id === id)?.role || 'Removed role'
  }

  const deleteUser = () => {
    if (!activeUser?.id) return toast.error('User not found')
    setLoading(true)
    removeTrackedUser(activeUser?.id || '')
      .then(() => {
        toast.success('User removed')
        mutateTrackedUsers && mutateTrackedUsers()
        setOpenSlideOver && setOpenSlideOver(false)
      })
      .catch((err) => {
        toast.error(
          'Failed to remove user, ' + err?.response?.data?.message ||
            'please contact support or try again.'
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleBanUser = async (email: string, reason?: string) => {
    try {
      await banUser({ email, reason })
      mutateTrackedUsers && mutateTrackedUsers()
      toast.success('User banned successfully')
    } catch (err: any) {
      toast.error(err?.response?.data?.message || 'Failed to ban user')
    }
  }

  const handleImageLoad = () => {
    setImageLoaded(true)
  }

  return (
    <>
      {!activity && (
        <div className="">
          <div className={!postView ? '' : ''}>
            {!postView && (
              <>
                <ConfirmationModal
                  open={confirmDelete}
                  setOpen={setConfirmDelete}
                  title={`Are you sure you want to remove ${activeUser?.name}?`}
                  callBack={deleteUser}
                  description="All actions this user made in your organization will be deleted."
                  buttonTxt="Remove user"
                />
                {activeUser?.email && (
                  <BanUserModal
                    presetValues={{ email: activeUser?.email, reason: '' }}
                    isOpen={banUserModalOpen}
                    setIsOpen={setBanUserModalOpen}
                    onBanUser={handleBanUser}
                  />
                )}
                <div className="relative">
                  <div className="absolute inset-0">
                    <div className="w-full h-full relative dark:bg-[#13151B]">
                      {longitude && latitude ? (
                        <>
                          <motion.img
                            src={`https://api.mapbox.com/styles/v1/brunofeaturebase/cm45rrdqk00yf01si04i10lz0/static/${longitude},${latitude},2.6/896x180?access_token=pk.eyJ1IjoiYnJ1bm9mZWF0dXJlYmFzZSIsImEiOiJjbTQ1cjFyMXMwdW5pMnFzNG13Zmk5cW90In0.-p0wWRXqzLFgMu23GgRLww&logo=false&attribution=false`}
                            alt="logo"
                            onLoad={handleImageLoad}
                            initial={{ opacity: 0, filter: 'blur(10px)' }}
                            animate={{
                              opacity: imageLoaded ? 1 : 0,
                              filter: imageLoaded ? 'blur(0px)' : 'blur(10px)',
                            }}
                            transition={{ duration: 0.4 }}
                            className="absolute dark:block hidden inset-0 h-full w-full object-cover rounded-none"
                          />
                          <motion.img
                            src={`https://api.mapbox.com/styles/v1/brunofeaturebase/cm45vurra013r01sid4ct7ctb/draft/static/${longitude},${latitude},2.6/896x180?access_token=pk.eyJ1IjoiYnJ1bm9mZWF0dXJlYmFzZSIsImEiOiJjbTQ1cjFyMXMwdW5pMnFzNG13Zmk5cW90In0.-p0wWRXqzLFgMu23GgRLww&logo=false&attribution=false`}
                            alt="logo"
                            onLoad={handleImageLoad}
                            initial={{ opacity: 0, filter: 'blur(10px)' }}
                            animate={{
                              opacity: imageLoaded ? 1 : 0,
                              filter: imageLoaded ? 'blur(0px)' : 'blur(10px)',
                            }}
                            transition={{ duration: 0.4 }}
                            className="absolute dark:hidden inset-0 h-full w-full object-cover rounded-none"
                          />
                          <div className="absolute z-10 inset-0 w-full h-full flex items-center justify-center bg-gradient-to-b from-white/20 to-white/70 dark:from-popover/30 dark:to-popover/60">
                            <div className="w-2.5 border border-accent h-2.5 bg-accent/60 rounded-full"></div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="p-6 z-40 flex flex-col justify-between relative">
                    <div className="flex items-start justify-between">
                      <div className="flex items-center gap-5">
                        <div className="relative">
                          {/* <div className="absolute top-1 right-1">
                            <DisplayMemberCheckmark authorId={activeUser?.id} org={org} />
                          </div> */}
                          {activeUser?.profilePicture ? (
                            <Image
                              unoptimized
                              width={74}
                              height={74}
                              className="rounded-full"
                              src={activeUser?.profilePicture}
                              alt=""
                            />
                          ) : (
                            <div className="flex items-center justify-center uppercase bg-white border border-gray-100 rounded-full shadow-lg h-14 w-14 dark:border-gray-400 dark:bg-gray-500">
                              {activeUser?.name ? (
                                <span className="text-xl font-medium text-gray-400 dark:text-gray-100">
                                  {activeUser?.name[0]}
                                  {activeUser?.name?.split(' ').length >= 2 &&
                                    activeUser?.name?.split(' ')[1].length >= 1 &&
                                    activeUser?.name?.split(' ')[1][0]}
                                </span>
                              ) : (
                                <span className="text-xs font-medium text-gray-400 dark:text-gray-100">
                                  <UserIcon className="w-6 h-6" />
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                        <div>
                          <div className="flex items-center gap-3">
                            <h2 className="text-xl truncate leading-tight max-w-[270px] font-semibold text-gray-700 dark:text-white">
                              {activeUser?.name ? activeUser?.name : 'Name not assigned yet'}{' '}
                            </h2>
                            <p className="bg-gray-100/40 dark:bg-secondary/70 text-xs backdrop-blur dark:backdrop-contrast-100 backdrop-contrast-200 font-semibold p-1 px-2.5 text-foreground rounded-full">
                              {isMember(activeUser?.id, org) ? 'Team member' : 'User'}
                            </p>
                          </div>
                          <div className="flex flex-wrap gap-3 mt-2.5">
                            {activeUser?.companies?.map(({ id, name }: any) => {
                              return (
                                <p
                                  key={id}
                                  className="flex items-center leading-none space-x-2 text-sm font-medium text-gray-600 dark:text-foreground first-letter:capitalize"
                                >
                                  <OfficeBuildingIcon className="mr-1 secondary-svg" />
                                  {name}
                                </p>
                              )
                            })}
                            {location && (
                              <p className="flex items-center space-x-2 leading-none text-sm font-medium text-gray-600 dark:text-foreground first-letter:capitalize">
                                <LocationMarkerIcon className="mr-1 secondary-svg" />
                                {location}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center gap-3">
                        {isMember(activeUser?.id, org) ? (
                          <Link target="_blank" rel="noreferrer" href={`/dashboard/settings/team`}>
                            <Button
                              tabIndex={-1}
                              variant={'outline'}
                              className="dark:bg-secondary/30 backdrop-blur-sm dark:border-border/30 text-[13px] py-1 text-foreground"
                              size={'sm'}
                              icon={CogIcon}
                            >
                              Manage Team Members
                            </Button>
                          </Link>
                        ) : (
                          <DropdownMenu modal={false}>
                            <DropdownMenuTrigger asChild>
                              <Button
                                tabIndex={-1}
                                variant={'outline'}
                                className="dark:bg-secondary/30 backdrop-blur-sm dark:border-border/30 text-[13px] py-1 text-foreground"
                                size={'sm'}
                                icon={FlagIcon}
                              >
                                Actions
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end" className="w-48">
                              {activeUser?.email && (
                                <DropdownMenuItem
                                  onSelect={() => setBanUserModalOpen(true)}
                                  icon={BanIcon}
                                >
                                  Ban
                                </DropdownMenuItem>
                              )}
                              <DropdownMenuItem
                                onSelect={() => setConfirmDelete(true)}
                                icon={XIcon}
                              >
                                Remove
                              </DropdownMenuItem>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        )}
                        {/* tabIndex={-1}
                          variant={'outline'}
                          className="dark:bg-secondary/30 backdrop-blur-sm dark:border-border/30 text-[13px] py-1 text-foreground"
                          size={'sm'}
                          loading={loading}
                          icon={BanIcon}
                          onClick={() => setConfirmDelete(true)}
                        >
                          Ban
                        </Button> */}
                        {/* <Button
                          tabIndex={-1}
                          variant={'outline'}
                          className="dark:bg-secondary/30 backdrop-blur-sm dark:border-border/30 text-[13px] py-1 text-foreground"
                          size={'sm'}
                          loading={loading}
                          icon={XIcon}
                          onClick={() => setConfirmDelete(true)}
                        >
                          Remove
                        </Button> */}
                      </div>
                    </div>
                    <div className="flex items-center gap-3 mt-auto pt-7">
                      {[
                        { id: 'details', label: 'Details', icon: InformationCircleIcon },
                        { id: 'all', label: 'Actions', icon: LightningBoltIcon },
                        { id: 'comments', label: 'Comments', icon: ChatAlt2Icon },
                        { id: 'feedback', label: 'Feedback', icon: CollectionIcon },
                        { id: 'upvotes', label: 'Upvotes', icon: ArrowUpIcon },
                      ].map((item) => (
                        <Button
                          key={item.id}
                          variant={'outline'}
                          tabIndex={-1}
                          icon={item.icon}
                          onClick={() => {
                            if (item.id === 'details') {
                              setActiveView(item.id)
                            } else {
                              if (!isPlan(org?.plan, 'premium')) {
                                setUpgradePlan({
                                  title: 'User specific actions are',
                                  plan: 'premium',
                                })
                              } else {
                                setActiveView(item.id)
                              }
                            }
                          }}
                          className={cn(
                            'backdrop-blur-sm text-[13px] py-1',
                            activeView === item.id
                              ? 'dark:bg-secondary/60 dark:border-border/60 shadow dark:shadow shadow-gray-200/30'
                              : 'dark:bg-secondary/30 dark:border-border/30 border-gray-100/60 shadow-none dark:text-foreground text-foreground bg-transparent'
                          )}
                        >
                          {item.label}
                        </Button>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            )}
            {activeView === 'details' && (
              <>
                <div className="">
                  <div
                    className={`${
                      !postView && 'border-t divide-y'
                    }   text-sm dashboard-divide divide-border dashboard-border`}
                  >
                    <div
                      className={`flex truncate ${
                        !postView ? 'p-4' : 'px-4 py-3'
                      } items-center justify-between`}
                    >
                      <p className="flex items-center flex-shrink-0 font-medium w-28">
                        {!postView && <MailIcon className="secondary-svg mr-1.5" />}
                        Email
                      </p>

                      <Description content={activeUser?.email || ''} />
                    </div>
                    {userId ? (
                      <div
                        className={`flex ${
                          !postView ? 'p-4' : 'px-4 py-3'
                        } items-center justify-between`}
                      >
                        <p className="flex items-center flex-shrink-0 font-medium w-28">
                          {!postView && <IdentificationIcon className="secondary-svg mr-1.5" />}
                          UserID
                        </p>
                        <Description content={userId} />
                      </div>
                    ) : null}
                    {org?.enabledFeatures?.userRoles && !isMember(activeUser?.id, org) ? (
                      !postView ? (
                        <div
                          className={`flex items-center justify-between ${
                            !postView ? 'p-4 py-[11px]' : 'px-4 py-3'
                          }`}
                        >
                          <p className="flex items-center flex-shrink-0 font-medium w-28">
                            <TagIcon className="secondary-svg mr-1.5" /> User tags
                          </p>
                          <RoleChanger
                            activeUser={activeUser}
                            getRoleNameFromId={getRoleNameFromId}
                            mutateTrackedUserActivity={() => {}}
                            mutateTrackedUsers={mutateTrackedUsers}
                          />
                        </div>
                      ) : (
                        activeUser?.roleId && (
                          <div
                            className={`flex items-center justify-between ${
                              !postView ? 'p-4 py-[11px]' : 'px-4 py-3'
                            }`}
                          >
                            <p className="flex items-center flex-shrink-0 font-medium w-28">Role</p>
                            <Description
                              content={
                                org?.roles?.find((role) => role?.id === activeUser?.roleId)?.role ||
                                'Unassigned'
                              }
                            />
                          </div>
                        )
                      )
                    ) : null}
                  </div>
                </div>
              </>
            )}
          </div>
          {activeView === 'details' && (
            <div
              className={cn(postView && 'xl:max-h-[20vh] custom-scrollbar-stronger overflow-auto')}
            >
              <div
                className={`${
                  !postView && 'border-t divide-y dark:text-gray-100'
                }   text-sm dashboard-divide text-gray-500 dashboard-border dark:text-foreground`}
              >
                {activeUser?.lastActivity ? (
                  <div
                    className={`flex items-center justify-between ${
                      !postView ? 'p-4' : 'px-4 py-3'
                    }`}
                  >
                    <p className="flex items-center flex-shrink-0 font-medium w-28">
                      {!postView && <ClockIcon className="secondary-svg mr-1.5" />}
                      Last action{' '}
                    </p>
                    <Description
                      content={`${
                        dateDifference(activeUser?.lastActivity)[0]?.toUpperCase() +
                        dateDifference(activeUser?.lastActivity)?.slice(1)
                      }`}
                    />
                  </div>
                ) : null}
                {location ? (
                  <div
                    className={`flex items-center justify-between ${
                      !postView ? 'p-4' : 'px-4 py-3'
                    }`}
                  >
                    <p className="flex items-center flex-shrink-0 font-medium w-28">
                      {!postView && <LocationMarkerIcon className="secondary-svg mr-1.5" />}
                      Location{' '}
                    </p>
                    <Description content={location} />
                  </div>
                ) : null}
                {activeUser?.createdAt && !activeUser?.createdAt?.includes('1970-') ? (
                  <div
                    className={`flex items-center justify-between ${
                      !postView ? 'p-4' : 'px-4 py-3'
                    }`}
                  >
                    <p className="flex items-center flex-shrink-0 font-medium w-28">
                      {!postView && <PlusCircleIcon className="secondary-svg mr-1.5" />}
                      Created at
                    </p>
                    <Description
                      content={`${
                        dateDifference(activeUser?.createdAt)[0]?.toUpperCase() +
                        dateDifference(activeUser?.createdAt)?.slice(1)
                      }`}
                    />
                  </div>
                ) : null}

                {identifiedUserCustomFields &&
                  Object.entries(identifiedUserCustomFields).map(([key, value]: any) => {
                    return (
                      <div
                        key={key}
                        className={`flex ${
                          !postView ? 'p-4' : 'px-4 py-3'
                        } items-center justify-between dashboard-border`}
                      >
                        <p className="flex-shrink-0 font-medium truncate w-28 first-letter:uppercase">
                          {key}
                        </p>
                        <Description content={value} />
                      </div>
                    )
                  })}
              </div>
              {activeUser?.companies?.length > 0 && (
                <div>
                  <div className="text-sm text-gray-500 border-t dashboard-divide dashboard-border divide-y dark:text-foreground">
                    {activeUser?.companies?.map(({ id, monthlySpend, customFields, name }: any) => {
                      return (
                        <div key={id} className="p-4 space-y-4">
                          <div className="flex items-center justify-between ">
                            <p
                              className={cn(
                                'flex items-center space-x-2 font-semibold text-gray-500 dark:text-gray-100 first-letter:capitalize',
                                postView ? 'text-sm' : 'text-[15px]'
                              )}
                            >
                              <span className="p-2 bg-gray-50 dark:bg-secondary rounded-md mr-2.5">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  className="secondary-svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M3 2.25a.75.75 0 0 0 0 1.5v16.5h-.75a.75.75 0 0 0 0 1.5H15v-18a.75.75 0 0 0 0-1.5H3ZM6.75 19.5v-2.25a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75v2.25a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1-.75-.75ZM6 6.75A.75.75 0 0 1 6.75 6h.75a.75.75 0 0 1 0 1.5h-.75A.75.75 0 0 1 6 6.75ZM6.75 9a.75.75 0 0 0 0 1.5h.75a.75.75 0 0 0 0-1.5h-.75ZM6 12.75a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 0 1.5h-.75a.75.75 0 0 1-.75-.75ZM10.5 6a.75.75 0 0 0 0 1.5h.75a.75.75 0 0 0 0-1.5h-.75Zm-.75 3.75A.75.75 0 0 1 10.5 9h.75a.75.75 0 0 1 0 1.5h-.75a.75.75 0 0 1-.75-.75ZM10.5 12a.75.75 0 0 0 0 1.5h.75a.75.75 0 0 0 0-1.5h-.75ZM16.5 6.75v15h5.25a.75.75 0 0 0 0-1.5H21v-12a.75.75 0 0 0 0-1.5h-4.5Zm1.5 4.5a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75h-.008a.75.75 0 0 1-.75-.75v-.008Zm.75 2.25a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75v-.008a.75.75 0 0 0-.75-.75h-.008ZM18 17.25a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75h-.008a.75.75 0 0 1-.75-.75v-.008Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </span>
                              {name}
                            </p>
                          </div>
                          <div className="flex items-center justify-between">
                            <p className="flex-shrink-0 font-medium w-28">CompanyID</p>
                            <Description content={id} />
                          </div>
                          {monthlySpend ? (
                            <div className="flex items-center justify-between">
                              <p className="flex-shrink-0 font-medium w-28">Company MRR</p>
                              <Description content={`$${monthlySpend} MRR`} />
                            </div>
                          ) : null}
                          {customFields
                            ? Object.entries(customFields).map(([key, value]) => {
                                return (
                                  <div key={key} className="flex items-center justify-between">
                                    <p className="flex-shrink-0 font-medium truncate w-28 first-letter:uppercase">
                                      {key}
                                    </p>
                                    <Description content={`${value}`} />
                                  </div>
                                )
                              })
                            : null}
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {activeView !== 'details' && activeUser.id && (
        <UserActivityFeed activeView={activeView} activeUserId={activeUser.id} />
      )}
    </>
  )
}

export default UsersInfo
