import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/lib/utils'
import Loader from '../Loader'

const buttonVariants = cva(
  'inline-flex main-transition items-center justify-center whitespace-nowrap rounded-md text-sm font-medium  focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'dashboard-primary',
        destructive: 'bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90',
        outline:
          'border shadow-sm dark:shadow dark:hover:border-dark-accent hover:shadow border-border hover:border-input bg-secondary shadow-sm hover:bg-background dark:hover:bg-border !text-foreground hover:!text-foreground dark:!text-foreground dark:hover:!text-dark-accent-foreground',
        menu: 'bg-transparent shadow-none select-none focus:!ring-0 dark:foucs:!ring-0 focus:outline-none dark:shadow-none leading-none items-center h-8 !text-[13px] justify-start w-full hover:bg-gray-100/40 dark:hover:bg-secondary/60 !text-foreground hover:!text-gray-600 dark:!text-foreground dark:hover:!text-dark-accent-foreground',
        menuActive:
          'bg-secondary/20 shadow-none bg-gray-100/40 hover:bg-gray-100/60 select-none dark:bg-secondary/60 focus:!ring-0 dark:focus:!ring-0 focus:outline-none dark:shadow-none leading-none items-center h-8 !text-[13px] justify-start w-full dark:hover:bg-secondary !text-gray-600 dark:!text-gray-50 dark:hover:!text-gray-50',
        secondary: 'bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80',
        ghost: 'hover:bg-gray-50 dark:hover:bg-dark-accent hover:text-dark-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'px-2.5 py-1.5',
        sm: 'h-8 rounded-md px-2 text-xs',
        xs: 'h-7 rounded-md px-2 text-xs',
        lg: 'h-10 rounded-md px-8',
        icon: 'h-9 w-9',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}
const Button = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & { loading?: boolean; icon?: React.ComponentType<React.SVGProps<SVGSVGElement>> }
>(({ className, variant, size, asChild = false, loading, icon: Icon, ...props }, ref) => {
  const Comp = asChild ? Slot : 'button'
  return (
    <Comp
      className={cn(buttonVariants({ variant, size, className }))}
      ref={ref}
      disabled={loading || props.disabled}
      {...props}
    >
      {loading && (
        <div className="w-4 h-4 mr-1 opacity-80">
          <Loader />
        </div>
      )}
      {!loading && Icon && (
        <Icon
          className={cn(
            'mr-1.5',
            !variant ? '' : 'secondary-svg',
            variant === 'menu' || variant === 'menuActive' ? 'mr-2' : ''
          )}
        />
      )}
      {props.children}
    </Comp>
  )
})
Button.displayName = 'Button'

export { Button, buttonVariants }
