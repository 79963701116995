import { ReactNodeViewRenderer } from '@tiptap/react'
import { mergeAttributes, Range } from '@tiptap/core'

import { ImageBlockView } from './components/ImageBlockView'
import { Image } from '../Image'
import { UploadImagesPlugin } from '../HandleNonCommandImage/regular-upload-images'
import { cn } from '@/lib'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    imageBlock: {
      setImageBlock: (attributes: { src: string; type: 'image' | 'video' }) => ReturnType
      setImageBlockAt: (attributes: {
        src: string
        type: 'image' | 'video'
        pos: number | Range
      }) => ReturnType
      setImageBlockAlign: (align: 'left' | 'center' | 'right') => ReturnType
      setImageBlockWidth: (width: number) => ReturnType
    }
  }
}

export const ImageBlock = Image.extend({
  // name: 'imageBlock',

  group: 'block',

  defining: true,
  draggable: true,

  addProseMirrorPlugins() {
    return [
      UploadImagesPlugin({
        imageClass: cn('opacity-40 border border-accent/10'),
      }),
    ]
  },

  addAttributes() {
    return {
      src: {
        default: '',
        parseHTML: (element) => element.getAttribute('src'),
        renderHTML: (attributes) => ({
          src: attributes.src,
        }),
      },
      type: {
        default: 'image',
        parseHTML: (element) => element.tagName.toLowerCase(),
        renderHTML: (attributes) => ({
          'data-type': attributes.type,
        }),
      },
      width: {
        default: '100%',
        parseHTML: (element) => element.getAttribute('data-width'),
        renderHTML: (attributes) => ({
          'data-width': attributes.width,
        }),
      },
      align: {
        default: 'left',
        parseHTML: (element) => element.getAttribute('data-align'),
        renderHTML: (attributes) => ({
          'data-align': attributes.align,
        }),
      },
      alt: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('alt'),
        renderHTML: (attributes) => ({
          alt: attributes.alt,
        }),
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'img',
      },
      {
        tag: 'video',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const { 'data-type': type, ...attrs } = HTMLAttributes
    const Tag = type === 'video' ? 'video' : 'img'

    return [Tag, mergeAttributes(this.options.HTMLAttributes, attrs)]
  },

  addCommands() {
    return {
      setImageBlock:
        (attrs) =>
        ({ commands }) => {
          return commands.insertContent({ type: this.name, attrs })
        },

      setImageBlockAt:
        (attrs) =>
        ({ commands }) => {
          const { pos, ...mediaAttrs } = attrs
          return commands.insertContentAt(pos, {
            type: this.name,
            attrs: mediaAttrs,
          })
        },

      setImageBlockAlign:
        (align) =>
        ({ commands }) =>
          commands.updateAttributes(this.name, { align }),

      setImageBlockWidth:
        (width) =>
        ({ commands }) =>
          commands.updateAttributes(this.name, {
            width: `${Math.max(0, Math.min(100, width))}%`,
          }),
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(ImageBlockView as any)
  },
})

export default ImageBlock
