import React, { useCallback, useMemo } from 'react'
import { Sidebar } from '@/components/radix/Sidebar'
import { useCurrentOrganization, usePendingModerationCount } from '@/data/organization'
import { Button } from './radix/Button'
import SimpleTooltip from './SimpleTooltip'
import OrganizationPicker from './dashboard/menu/OrganizationPicker'
import ProfileDropdown from './ProfileDropdown'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { cn } from '@/lib'
import Notifications from './Notifications'
import { differenceInDays } from 'date-fns'
import { orgSettingsChanger } from '@/lib/organizatioMutator'

const DashboardFixedSidebar = ({ ...props }: React.ComponentProps<typeof Sidebar>) => {
  const { org, mutateCurrentOrg } = useCurrentOrganization()
  const { pendingCount } = usePendingModerationCount()
  const router = useRouter()

  // Check if a module is enabled for the current organization
  const checkIfModuleIsEnabled = useCallback(
    (moduleName: string) => {
      if (!org?.onboardingData?.modules || org?.onboardingData?.modules?.length === 0) {
        return true
      }

      return org?.onboardingData?.modules?.some((m: any) => m.id === moduleName)
    },
    [org]
  )

  // Memoize enabled modules to avoid recomputations
  const modulesEnabled = useMemo(() => {
    return {
      feedback: checkIfModuleIsEnabled('feedback'),
      changelogs: checkIfModuleIsEnabled('changelogs'),
      surveys: checkIfModuleIsEnabled('surveys'),
      knowledgeBase: checkIfModuleIsEnabled('knowledge-base'),
    }
  }, [checkIfModuleIsEnabled])

  const navData = useMemo(() => {
    return {
      navMain: [
        {
          title: 'Moderate',
          icon: (props: any) => (
            <svg
              {...props}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M12.516 2.17a.75.75 0 0 0-1.032 0 11.209 11.209 0 0 1-7.877 3.08.75.75 0 0 0-.722.515A12.74 12.74 0 0 0 2.25 9.75c0 5.942 4.064 10.933 9.563 12.348a.749.749 0 0 0 .374 0c5.499-1.415 9.563-6.406 9.563-12.348 0-1.39-.223-2.73-.635-3.985a.75.75 0 0 0-.722-.516l-.143.001c-2.996 0-5.717-1.17-7.734-3.08Zm3.094 8.016a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                clipRule="evenodd"
              />
            </svg>
          ),
          url: '/moderate',
          moderation: true,
          moduleEnabled: modulesEnabled.feedback || modulesEnabled.changelogs,
        },
        {
          title: 'Inbox',
          icon: (props: any) => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              {...props}
            >
              <path
                fillRule="evenodd"
                d="M6.912 3a3 3 0 0 0-2.868 2.118l-2.411 7.838a3 3 0 0 0-.133.882V18a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3v-4.162c0-.299-.045-.596-.133-.882l-2.412-7.838A3 3 0 0 0 17.088 3H6.912Zm13.823 9.75-2.213-7.191A1.5 1.5 0 0 0 17.088 4.5H6.912a1.5 1.5 0 0 0-1.434 1.059L3.265 12.75H6.11a3 3 0 0 1 2.684 1.658l.256.513a1.5 1.5 0 0 0 1.342.829h3.218a1.5 1.5 0 0 0 1.342-.83l.256-.512a3 3 0 0 1 2.684-1.658h2.844Z"
                clipRule="evenodd"
              />
            </svg>
          ),
          // Instead of inline components, we directly reference Notifications:
          component: Notifications,
          moduleEnabled: modulesEnabled.feedback || modulesEnabled.changelogs,
        },
        {
          title: 'Feedback',
          url: '/posts',
          icon: (props: any) => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              {...props}
            >
              <path d="M5.566 4.657A4.505 4.505 0 0 1 6.75 4.5h10.5c.41 0 .806.055 1.183.157A3 3 0 0 0 15.75 3h-7.5a3 3 0 0 0-2.684 1.657ZM2.25 12a3 3 0 0 1 3-3h13.5a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3v-6ZM5.25 7.5c-.41 0-.806.055-1.184.157A3 3 0 0 1 6.75 6h10.5a3 3 0 0 1 2.683 1.657A4.505 4.505 0 0 0 18.75 7.5H5.25Z" />
            </svg>
          ),
          moduleEnabled: modulesEnabled.feedback,
          onClickEnableModule: 'feedback',
        },
        {
          title: 'Roadmaps',
          url: '/roadmap',
          icon: (props: any) => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              {...props}
            >
              <path
                fillRule="evenodd"
                d="M8.161 2.58a1.875 1.875 0 0 1 1.678 0l4.993 2.498c.106.052.23.052.336 0l3.869-1.935A1.875 1.875 0 0 1 21.75 4.82v12.485c0 .71-.401 1.36-1.037 1.677l-4.875 2.437a1.875 1.875 0 0 1-1.676 0l-4.994-2.497a.375.375 0 0 0-.336 0l-3.868 1.935A1.875 1.875 0 0 1 2.25 19.18V6.695c0-.71.401-1.36 1.036-1.677l4.875-2.437ZM9 6a.75.75 0 0 1 .75.75V15a.75.75 0 0 1-1.5 0V6.75A.75.75 0 0 1 9 6Zm6.75 3a.75.75 0 0 0-1.5 0v8.25a.75.75 0 0 0 1.5 0V9Z"
                clipRule="evenodd"
              />
            </svg>
          ),
          moduleEnabled: modulesEnabled.feedback,
          onClickEnableModule: 'feedback',
        },
        {
          title: 'Changelog',
          url: '/changelog',
          icon: (props: any) => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              {...props}
            >
              <path d="M16.881 4.345A23.112 23.112 0 0 1 8.25 6H7.5a5.25 5.25 0 0 0-.88 10.427 21.593 21.593 0 0 0 1.378 3.94c.464 1.004 1.674 1.32 2.582.796l.657-.379c.88-.508 1.165-1.593.772-2.468a17.116 17.116 0 0 1-.628-1.607c1.918.258 3.76.75 5.5 1.446A21.727 21.727 0 0 0 18 11.25c0-2.414-.393-4.735-1.119-6.905ZM18.26 3.74a23.22 23.22 0 0 1 1.24 7.51 23.22 23.22 0 0 1-1.41 7.992.75.75 0 1 0 1.409.516 24.555 24.555 0 0 0 1.415-6.43 2.992 2.992 0 0 0 .836-2.078c0-.807-.319-1.54-.836-2.078a24.65 24.65 0 0 0-1.415-6.43.75.75 0 1 0-1.409.516c.059.16.116.321.17.483Z" />
            </svg>
          ),
          moduleEnabled: modulesEnabled.changelogs,
          onClickEnableModule: 'changelogs',
        },
        {
          title: 'Surveys',
          url: '/surveys',
          icon: (props: any) => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              {...props}
            >
              <path
                fillRule="evenodd"
                d="M7.502 6h7.128A3.375 3.375 0 0 1 18 9.375v9.375a3 3 0 0 0 3-3V6.108c0-1.505-1.125-2.811-2.664-2.94a48.972 48.972 0 0 0-.673-.05A3 3 0 0 0 15 1.5h-1.5a3 3 0 0 0-2.663 1.618c-.225.015-.45.032-.673.05C8.662 3.295 7.554 4.542 7.502 6ZM13.5 3A1.5 1.5 0 0 0 12 4.5h4.5A1.5 1.5 0 0 0 15 3h-1.5Z"
                clipRule="evenodd"
              />
              <path
                fillRule="evenodd"
                d="M3 9.375C3 8.339 3.84 7.5 4.875 7.5h9.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V9.375ZM6 12a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V12Zm2.25 0a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75ZM6 15a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V15Zm2.25 0a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75ZM6 18a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V18Zm2.25 0a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75Z"
                clipRule="evenodd"
              />
            </svg>
          ),
          moduleEnabled: modulesEnabled.surveys,
          onClickEnableModule: 'surveys',
        },
        {
          title: 'Help Center',
          url: '/articles',
          icon: (props: any) => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              {...props}
            >
              <path d="M11.25 4.533A9.707 9.707 0 0 0 6 3a9.735 9.735 0 0 0-3.25.555.75.75 0 0 0-.5.707v14.25a.75.75 0 0 0 1 .707A8.237 8.237 0 0 1 6 18.75c1.995 0 3.823.707 5.25 1.886V4.533ZM12.75 20.636A8.214 8.214 0 0 1 18 18.75c.966 0 1.89.166 2.75.47a.75.75 0 0 0 1-.708V4.262a.75.75 0 0 0-.5-.707A9.735 9.735 0 0 0 18 3a9.707 9.707 0 0 0-5.25 1.533v16.103Z" />
            </svg>
          ),
          moduleEnabled: modulesEnabled.knowledgeBase,
          onClickEnableModule: 'knowledge-base',
        },
        {
          title: 'Users',
          url: '/users',
          icon: (props: any) => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              {...props}
            >
              <path
                fillRule="evenodd"
                d="M8.25 6.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM15.75 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM2.25 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM6.31 15.117A6.745 6.745 0 0 1 12 12a6.745 6.745 0 0 1 6.709 7.498.75.75 0 0 1-.372.568A12.696 12.696 0 0 1 12 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 0 1-.372-.568 6.787 6.787 0 0 1 1.019-4.38Z"
                clipRule="evenodd"
              />
              <path d="M5.082 14.254a8.287 8.287 0 0 0-1.308 5.135 9.687 9.687 0 0 1-1.764-.44l-.115-.04a.563.563 0 0 1-.373-.487l-.01-.121a3.75 3.75 0 0 1 3.57-4.047ZM20.226 19.389a8.287 8.287 0 0 0-1.308-5.135 3.75 3.75 0 0 1 3.57 4.047l-.01.121a.563.563 0 0 1-.373.486l-.115.04c-.567.2-1.156.349-1.764.441Z" />
            </svg>
          ),
          moduleEnabled: true,
        },
        {
          title: 'Visit your live web portal',
          url: '/live',
          icon: (props: any) => (
            <svg
              {...props}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M21.721 12.752a9.711 9.711 0 0 0-.945-5.003 12.754 12.754 0 0 1-4.339 2.708 18.991 18.991 0 0 1-.214 4.772 17.165 17.165 0 0 0 5.498-2.477ZM14.634 15.55a17.324 17.324 0 0 0 .332-4.647c-.952.227-1.945.347-2.966.347-1.021 0-2.014-.12-2.966-.347a17.515 17.515 0 0 0 .332 4.647 17.385 17.385 0 0 0 5.268 0ZM9.772 17.119a18.963 18.963 0 0 0 4.456 0A17.182 17.182 0 0 1 12 21.724a17.18 17.18 0 0 1-2.228-4.605ZM7.777 15.23a18.87 18.87 0 0 1-.214-4.774 12.753 12.753 0 0 1-4.34-2.708 9.711 9.711 0 0 0-.944 5.004 17.165 17.165 0 0 0 5.498 2.477ZM21.356 14.752a9.765 9.765 0 0 1-7.478 6.817 18.64 18.64 0 0 0 1.988-4.718 18.627 18.627 0 0 0 5.49-2.098ZM2.644 14.752c1.682.971 3.53 1.688 5.49 2.099a18.64 18.64 0 0 0 1.988 4.718 9.765 9.765 0 0 1-7.478-6.816ZM13.878 2.43a9.755 9.755 0 0 1 6.116 3.986 11.267 11.267 0 0 1-3.746 2.504 18.63 18.63 0 0 0-2.37-6.49ZM12 2.276a17.152 17.152 0 0 1 2.805 7.121c-.897.23-1.837.353-2.805.353-.968 0-1.908-.122-2.805-.353A17.151 17.151 0 0 1 12 2.276ZM10.122 2.43a18.629 18.629 0 0 0-2.37 6.49 11.266 11.266 0 0 1-3.746-2.504 9.754 9.754 0 0 1 6.116-3.985Z" />
            </svg>
          ),
          moduleEnabled: true,
        },
        {
          title: 'Analytics',
          url: '/analytics',
          icon: (props: any) => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              {...props}
            >
              <path
                fillRule="evenodd"
                d="M3 6a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V6Zm4.5 7.5a.75.75 0 0 1 .75.75v2.25a.75.75 0 0 1-1.5 0v-2.25a.75.75 0 0 1 .75-.75Zm3.75-1.5a.75.75 0 0 0-1.5 0v4.5a.75.75 0 0 0 1.5 0V12Zm2.25-3a.75.75 0 0 1 .75.75v6.75a.75.75 0 0 1-1.5 0V9.75A.75.75 0 0 1 13.5 9Zm3.75-1.5a.75.75 0 0 0-1.5 0v9a.75.75 0 0 0 1.5 0v-9Z"
                clipRule="evenodd"
              />
            </svg>
          ),
          showInBottomBar: true,
          moduleEnabled: true,
        },
        {
          title: 'Settings',
          url: '/settings',
          icon: (props: any) => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              {...props}
            >
              <path
                fillRule="evenodd"
                d="M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 0 0-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 0 0-2.282.819l-.922 1.597a1.875 1.875 0 0 0 .432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 0 0 0 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 0 0-.432 2.385l.922 1.597a1.875 1.875 0 0 0 2.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 0 0 2.28-.819l.923-1.597a1.875 1.875 0 0 0-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 0 0 0-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 0 0-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 0 0-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 0 0-1.85-1.567h-1.843ZM12 15.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z"
                clipRule="evenodd"
              />
            </svg>
          ),
          showInBottomBar: true,
          moduleEnabled: true,
        },
      ],
    }
  }, [modulesEnabled])

  const sortedNavMain = useMemo(() => {
    const mainItems = navData.navMain
    const enabledItems = mainItems.filter((item) => item.moduleEnabled)
    const disabledItems = mainItems.filter((item) => !item.moduleEnabled)
    return { enabledItems, disabledItems }
  }, [navData])

  const enableModule = useCallback(
    (moduleName: string) => {
      orgSettingsChanger(
        {
          onboardingData: {
            ...org.onboardingData,
            modules: [...org.onboardingData.modules, { id: moduleName }],
          },
        },
        {
          ...org,
          onboardingData: {
            ...org.onboardingData,
            modules: [...org.onboardingData.modules, { id: moduleName }],
          },
        },
        mutateCurrentOrg,
        false
      )
    },
    [org]
  )

  const renderNavItem = useCallback(
    (item: any, showInBottomBar = false) => {
      const isActive = item.url ? router.pathname.startsWith('/dashboard' + item.url) : false

      if (item.showInBottomBar && !showInBottomBar) {
        return null
      } else if (showInBottomBar && !item.showInBottomBar) {
        return null
      }

      if (
        !org?.settings?.postModerationEnabled &&
        !org?.settings?.commentModerationEnabled &&
        item?.moderation &&
        pendingCount?.total === 0
      ) {
        return null
      }

      const content = (
        <MenuItem
          tooltip={item.title}
          isActive={isActive}
          className={cn(!item.moduleEnabled && !isActive && 'opacity-40')}
          onClick={() => {
            if (!item.moduleEnabled && item?.onClickEnableModule) {
              enableModule(item.onClickEnableModule)
            }
          }}
        >
          <item.icon
            className={cn(
              '!h-[18px] !w-[18px]',
              !isActive && 'secondary-svg text-foreground/70',
              isActive && 'text-primary-modified dark:text-foreground/[60%]'
            )}
          />
          {item.moderation && pendingCount?.total > 0 && (
            <div className="absolute top-0.5 pointer-events-none px-[4px] leading-3 py-px -right-0.5 text-[9px] menu-entry-badge-active">
              {pendingCount.total}
            </div>
          )}
        </MenuItem>
      )

      if (item.component) {
        // Render the provided component with stable props
        const Component = item.component
        return <Component key={item.title} disabled={!item.moduleEnabled} customButton={content} />
      }

      return (
        <Link
          key={item.title}
          href={
            item.url === '/live'
              ? '/'
              : item.url === '/settings'
              ? '/dashboard/settings/team'
              : '/dashboard' + (item.url || '#')
          }
        >
          {content}
        </Link>
      )
    },
    [
      router.pathname,
      pendingCount?.total,
      org?.settings?.postModerationEnabled,
      org?.settings?.commentModerationEnabled,
    ]
  )

  const renderedNavMain = useMemo(() => {
    const { enabledItems, disabledItems } = sortedNavMain
    const hasSeparator = enabledItems.length > 0 && disabledItems.length > 0

    return (
      <>
        {enabledItems.map((item) => renderNavItem(item))}
        {hasSeparator && disabledItems.length > 0 && (
          <div key="separator" className="w-full h-px bg-gray-100/80 dark:bg-border/50 my-2" />
        )}
        {disabledItems.map((item) => renderNavItem(item))}
      </>
    )
  }, [renderNavItem, sortedNavMain])

  const renderedNavBottom = useMemo(() => {
    return sortedNavMain.enabledItems.map((item) => renderNavItem(item, true))
  }, [renderNavItem, sortedNavMain])

  return (
    <div className="pt-5 pb-3 px-2 items-center flex flex-col min-h-screen flex-shrink-0">
      <OrganizationPicker>
        <MenuItem>
          <img width={32} height={32} src={org?.picture} alt="" className="rounded-md" />
        </MenuItem>
      </OrganizationPicker>
      <div className="flex flex-col justify-between gap-3 h-full">
        <div className="flex flex-col gap-3 mt-6">{renderedNavMain}</div>
        <div className="flex flex-col gap-3">
          {renderedNavBottom}
          {org.subscriptionStatus === 'trial' && org?.trialEndDate && (
            <Link href={'/dashboard/settings/pricing'}>
              <MenuItem
                isActive={false}
                variant="default"
                tooltip={
                  differenceInDays(new Date(org.trialEndDate), Date.now()) +
                  ' days left on trial, upgrade now'
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className={cn('!h-[18px] !w-[18px]')}
                >
                  <path
                    fillRule="evenodd"
                    d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z"
                    clipRule="evenodd"
                  />
                </svg>

                <div className="absolute -top-0.5 px-[4px] leading-3 py-px -right-2 text-[9px] menu-entry-badge-active">
                  {differenceInDays(new Date(org.trialEndDate), Date.now())}
                </div>
              </MenuItem>
            </Link>
          )}
          <MenuItem>
            <ProfileDropdown dashboardFixedSidebar={true} />
          </MenuItem>
        </div>
      </div>
    </div>
  )
}

const MenuItem: React.FC<
  React.ComponentProps<typeof Button> & { tooltip?: string; isActive?: boolean }
> = ({ tooltip, isActive, ...props }) => {
  const ButtonElement = (
    <Button
      variant={'outline'}
      {...props}
      className={cn(
        'h-9 w-9 p-0 relative',
        !isActive &&
          !props.variant &&
          'unstyled-button border-transparent shadow-none bg-transparent dark:shadow-none dark:bg-transparent dark:hover:bg-secondary',
        props.className
      )}
    >
      {props.children}
    </Button>
  )

  return tooltip ? (
    <SimpleTooltip contentProps={{ side: 'right' }} content={tooltip}>
      {ButtonElement}
    </SimpleTooltip>
  ) : (
    ButtonElement
  )
}

export default DashboardFixedSidebar
