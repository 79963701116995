import React, { useEffect, useState } from 'react'
import {
  linkGithubIssue,
  searchGithubIssues,
} from '../../network/lib/organization'
import { ISubmission } from '../interfaces/ISubmission'
import Loader from './Loader'
import { toast } from 'sonner'

interface IGithubSearchResult {
  id: string
  title: string
  html_url: string
  number: string
  repository_url: string
  repository_full_name: string
  state: 'open' | 'closed'
  state_reason: 'completed' | 'reopened' | 'not_planned'
}
const LinkExistingGithubIssue: React.FC<{
  submission: ISubmission
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  callback?: () => void
}> = ({ submission, setOpen, callback }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState<IGithubSearchResult[]>([])
  const [noResults, setNoResults] = useState(false)
  const [loading, setLoading] = useState(false)
  const [linkLoading, setLinkLoading] = useState(false)
  const [activeResult, setActiveResult] = useState('')
  useEffect(() => {
    setLoading(true)
    const delayDebounceFn = setTimeout(() => {
      searchGithubIssues(searchTerm, submission.id)
        .then((res) => {
          setSearchResults(res.data.issues)
          setNoResults(res.data.length === 0)
          setLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
        })
      // Send Axios request here
    }, 200)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  const linkIssue = (issueNumber: string, repoFullName: string) => {
    setActiveResult(issueNumber)
    setLinkLoading(true)
    linkGithubIssue(submission.id, issueNumber, repoFullName)
      .then((res) => {
        if (res.data.success) {
          setOpen(false)
          callback && callback()
          setLinkLoading(false)
          toast.success('Issue linked to ClickUp')
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || err.message)
        setOpen(false)
        setLinkLoading(false)
      })
  }

  return (
    <div className="mt-3">
      <div className="relative">
        <input
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm((prev) => event.target.value)
            setNoResults(false)
          }}
          autoFocus={true}
          placeholder="Search for issues..."
          className="mb-2"
        />
        {loading && (
          <div className="flex absolute right-2 inset-y-2 items-center justify-center">
            <div className="!w-4 !h-4 secondary-svg">
              <Loader />
            </div>
          </div>
        )}
      </div>
      {noResults && <p className="text-sm text-gray-400 dark:text-foreground">No results found</p>}
      <div className="divide-y divide-gray-100 dark:divide-dark-accent max-h-[400px] custom-scrollbar-stronger overflow-y-auto">
        {searchResults.map((result, idx: number) => {
          return (
            <div key={result.id + idx.toString()} className="py-3 text-white dark:shadow-sm">
              <div className="flex">
                <p className="p-1 px-1.5 mr-2 text-xs up-element text-gray-400 bg-gray-50 dark:text-foreground tracking-wide font-medium rounded-md dark:shadow-none dark:border-dark-accent dark:bg-border">
                  {result.repository_full_name}
                </p>
                <p className="p-1 px-1.5 mr-2 text-xs up-element text-gray-400 bg-gray-50 dark:text-foreground tracking-wide font-medium rounded-md dark:shadow-none dark:border-dark-accent dark:bg-border">
                  #{result.number}
                </p>
                <p className="p-1 px-1.5 text-xs up-element text-gray-400 bg-gray-50 dark:text-foreground tracking-wide font-medium rounded-md dark:shadow-none dark:border-dark-accent dark:bg-border">
                  {result.state}
                </p>
              </div>
              <p className="pt-2 pb-1 text-gray-900 dark:text-white">
                {result.title?.substring(0, 50)}
              </p>
              {/* <p className="pb-1.5 text-background-accent text-sm truncate dark:text-foreground">
                {result.}
              </p> */}
              <div className="flex justify-end">
                <button
                  className="text-xs items-centeras dashboard-primary"
                  onClick={() => {
                    linkIssue(result.number, result.repository_full_name)
                  }}
                >
                  {linkLoading && activeResult === result.id && (
                    <div className="secondary-svg mr-1.5 h-3.5 w-3.5">
                      <Loader />
                    </div>
                  )}
                  Link to issue
                </button>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default LinkExistingGithubIssue
