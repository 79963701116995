import { addIframeEmbed, addYoutubeVideo } from '@/lib/utils'
import { Group } from './types'
import { uploadFn } from '../../image-upload'
import { CubeIcon } from '@heroicons/react/solid'

export const GROUPS: Group[] = [
  {
    name: 'format',
    title: 'Format',
    commands: [
      {
        name: 'heading1',
        label: 'Heading 1',
        iconName: 'Heading1',
        description: 'High priority section title',
        aliases: ['h1'],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 1 }).run()
        },
        showOnChangelog: true,
      },
      {
        name: 'heading2',
        label: 'Heading 2',
        iconName: 'Heading2',
        description: 'Medium priority section title',
        aliases: ['h2'],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 2 }).run()
        },
        showOnChangelog: true,
      },
      {
        name: 'heading3',
        label: 'Heading 3',
        iconName: 'Heading3',
        description: 'Low priority section title',
        aliases: ['h3'],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 3 }).run()
        },
        showOnChangelog: true,
      },
      {
        name: 'bulletList',
        label: 'Bullet List',
        iconName: 'List',
        description: 'Unordered list of items',
        aliases: ['ul'],
        action: (editor) => {
          editor.chain().focus().toggleBulletList().run()
        },
        showOnChangelog: true,
      },
      {
        name: 'numberedList',
        label: 'Numbered List',
        iconName: 'ListOrdered',
        description: 'Ordered list of items',
        aliases: ['ol'],
        action: (editor) => {
          editor.chain().focus().toggleOrderedList().run()
        },
        showOnChangelog: true,
      },
      {
        name: 'taskList',
        label: 'Task List',
        iconName: 'ListTodo',
        description: 'Task list with todo items',
        hideOnCompactMode: true,
        aliases: ['todo'],
        action: (editor) => {
          editor.chain().focus().toggleTaskList().run()
        },
      },
      {
        name: 'blockquote',
        label: 'Blockquote',
        iconName: 'Quote',
        hideOnCompactMode: true,
        description: 'Element for quoting',
        action: (editor) => {
          editor.chain().focus().setBlockquote().run()
        },
      },
      {
        name: 'accordion',
        label: 'Accordion',
        hideOnCompactMode: true,
        iconName: 'ListPlus',
        description: 'Collapsible content section',
        action: (editor) => {
          editor.chain().focus().setDetails().run()
        },
      },
    ],
  },
  {
    name: 'insert',
    title: 'Insert',
    commands: [
      {
        name: 'table',
        label: 'Table',
        iconName: 'Table',
        hideOnCompactMode: true,
        showOnChangelog: true,
        description: 'Insert a table',
        shouldBeHidden: (editor) => editor.isActive('columns'),
        action: (editor) => {
          editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: false }).run()
        },
      },
      {
        name: 'multicodeComponent',
        label: 'Multi-Code Block',
        hideOnCompactMode: true,
        iconName: 'SquareCode',
        description: 'Code block with syntax highlighting',
        shouldBeHidden: (editor) => false,
        action: (editor) => {
          editor
            .chain()
            .focus()
            .command(({ tr, dispatch }) => {
              if (dispatch) {
                const { $from, $to } = tr.selection

                // Delete the entire line, whether it's empty or not
                const lineStart = $from.start()
                const lineEnd = $to.end()
                tr.delete(lineStart, lineEnd)

                // Create the multicodeComponent node with a paragraph inside
                const multicodeNode = editor.schema.nodes.multicodeComponent.create(
                  null,
                  editor.schema.nodes.paragraph.create()
                )

                // Insert the multicodeComponent at the current position
                tr.insert(lineStart, multicodeNode)
              }
              return true
            })
            .run()
        },
      },
      {
        name: 'multicodeComponent',
        label: 'Regular Code-Block',
        hideOnCompactMode: false,
        iconName: 'SquareCode',
        description: 'Code block with syntax highlighting',
        shouldBeHidden: (editor) => false,
        action: (editor) => {
          editor.chain().focus().setCodeBlock().run()
        },
        showOnChangelog: true,
      },
      {
        name: 'callout',
        label: 'Callout',
        iconName: 'AlertCircle',
        hideOnCompactMode: true,
        showOnChangelog: true,
        description: 'Insert a callout',
        shouldBeHidden: (editor) => editor.isActive('callout-component'),
        action: (editor) => {
          editor
            .chain()
            .focus()
            // .deleteRange(range)
            .insertContent({
              type: 'calloutComponent',
              content: [{ type: 'paragraph' }],
            })
            .run()
        },
      },
      {
        name: 'image',
        label: 'Image or MP4 Video',
        hideOnCompactMode: true,
        showOnChangelog: true,
        iconName: 'Image',
        description: 'Insert an image',
        aliases: ['img'],
        action: (editor) => {
          editor.chain().focus().setImageUpload().run()
        },
      },
      {
        name: 'image',
        label: 'Image or MP4 Video',
        iconName: 'Image',
        description: 'Insert an image',
        aliases: ['img'],
        onlyCompactMode: true,
        action: (editor) => {
          editor.chain().focus().run()
          const input = document.createElement('input')
          input.type = 'file'
          input.accept = 'image/*, video/mp4'
          input.onchange = async () => {
            if (input.files?.length) {
              const file = input.files[0]
              const pos = editor.view.state.selection.from
              if (file.type.startsWith('image/') || file.type === 'video/mp4') {
                uploadFn(file, editor.view, pos)
              } else {
                console.error('Unsupported file type')
                // Optionally, you can show an error message to the user here
              }
            }
          }
          input.click()
        },
      },
      {
        name: 'columns',
        label: 'Columns',
        hideOnCompactMode: true,
        iconName: 'Columns',
        description: 'Add two column content',
        aliases: ['cols'],
        shouldBeHidden: (editor) => editor.isActive('columns'),
        action: (editor) => {
          editor
            .chain()
            .focus()
            .setColumns()
            .focus(editor.state.selection.head - 1)
            .run()
        },
      },
      {
        name: 'horizontalRule',
        label: 'Horizontal Rule',
        iconName: 'Minus',
        description: 'Insert a horizontal divider',
        aliases: ['hr'],
        action: (editor) => {
          editor.chain().focus().setHorizontalRule().run()
        },
        showOnChangelog: true,
      },
      // {
      //   name: 'toc',
      //   label: 'Table of Contents',
      //   iconName: 'Book',
      //   aliases: ['outline'],
      //   description: 'Insert a table of contents',
      //   shouldBeHidden: (editor) => editor.isActive('columns'),
      //   action: (editor) => {
      //     editor.chain().focus().insertTableOfContents().run()
      //   },
      // },
    ],
  },
  {
    name: 'embeds',
    title: 'Embeds',
    commands: [
      {
        name: 'iframe',
        label: 'Custom iframe',
        iconName: <CubeIcon className="" />,
        description: 'Embed anything with an iframe',
        aliases: ['iframe'],
        action: (editor) => {
          addIframeEmbed(editor)
        },
        hideOnCompactMode: true,
        showOnChangelog: true,
      },
      {
        name: 'youtube',
        label: 'Youtube',
        iconName: 'Youtube',
        description: 'Embed a Youtube video',
        aliases: ['video', 'youtube'],
        action: (editor) => {
          // editor.chain().focus().setImageUpload().run()
          addYoutubeVideo(editor)
        },
        showOnChangelog: true,
      },
      {
        name: 'Descript',
        label: 'Descript',
        iconName: (
          <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_7039_6637)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.5117 126.51C25.5117 133.35 29.8817 137.79 36.6117 137.79H69.0417C88.3917 137.79 104.222 131.14 114.972 119.49H25.5117V126.51Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M69.0417 12.5898H36.6117C29.8817 12.5898 25.5117 17.0298 25.5117 23.8698V30.8898H114.982C104.222 19.2398 88.3917 12.5898 69.0517 12.5898H69.0417Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M97.3516 93.0299C97.3516 98.5599 100.902 102.16 106.372 102.16H125.802C128.002 96.5999 129.482 90.4899 130.172 83.8999H106.372C100.902 83.8999 97.3516 87.4899 97.3516 93.0299Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M70.4102 57.3898C70.4102 62.9298 73.9602 66.5198 79.4302 66.5198H130.17C129.48 59.9298 128 53.8198 125.8 48.2598H79.4302C73.9602 48.2598 70.4102 51.8498 70.4102 57.3898Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M79.4217 93.0299C79.4217 87.4899 75.8717 83.8999 70.4017 83.8999H25.5117V102.16H70.4017C75.8717 102.16 79.4217 98.5699 79.4217 93.0299Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M52.4817 57.3898C52.4817 51.8498 48.9317 48.2598 43.4617 48.2598H25.5117V66.5198H43.4717C48.9417 66.5198 52.4917 62.9298 52.4917 57.3898H52.4817Z"
                fill="currentColor"
              />
            </g>
            <defs>
              <clipPath id="clip0_7039_6637">
                <rect width="150" height="150" fill="currentColor" />
              </clipPath>
            </defs>
          </svg>
        ),
        description: 'Embed a Descript video',
        aliases: ['video', 'Descript'],
        action: (editor) => {
          // editor.chain().focus().setImageUpload().run()
          addYoutubeVideo(editor)
        },
        showOnChangelog: true,
      },
      {
        name: 'Loom',
        label: 'Loom',
        iconName: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height={18}
            width={18}
            viewBox="0 0 16 16"
            fill="currentColor"
          >
            <path
              fill="currentColor"
              d="M15 7.222h-4.094l3.546-2.047-.779-1.35-3.545 2.048 2.046-3.546-1.349-.779L8.78 5.093V1H7.22v4.094L5.174 1.548l-1.348.779 2.046 3.545-3.545-2.046-.779 1.348 3.546 2.047H1v1.557h4.093l-3.545 2.047.779 1.35 3.545-2.047-2.047 3.545 1.35.779 2.046-3.546V15h1.557v-4.094l2.047 3.546 1.349-.779-2.047-3.546 3.545 2.047.779-1.349-3.545-2.046h4.093L15 7.222zm-7 2.896a2.126 2.126 0 110-4.252 2.126 2.126 0 010 4.252z"
            />
          </svg>
        ),
        description: 'Embed a Loom video',
        aliases: ['video', 'Loom'],
        action: (editor) => {
          // editor.chain().focus().setImageUpload().run()
          addYoutubeVideo(editor)
        },
        showOnChangelog: true,
      },

      // {
      //   title: 'Descript',
      //   description: 'Embed a Descript video.',
      //   searchTerms: ['descript', 'video'],
      //   icon: (
      // <svg
      //   width="18"
      //   height="18"
      //   viewBox="0 0 150 150"
      //   fill="none"
      //   xmlns="http://www.w3.org/2000/svg"
      // >
      //   <g clipPath="url(#clip0_7039_6637)">
      //     <path
      //       fillRule="evenodd"
      //       clipRule="evenodd"
      //       d="M25.5117 126.51C25.5117 133.35 29.8817 137.79 36.6117 137.79H69.0417C88.3917 137.79 104.222 131.14 114.972 119.49H25.5117V126.51Z"
      //       fill="currentColor"
      //     />
      //     <path
      //       fillRule="evenodd"
      //       clipRule="evenodd"
      //       d="M69.0417 12.5898H36.6117C29.8817 12.5898 25.5117 17.0298 25.5117 23.8698V30.8898H114.982C104.222 19.2398 88.3917 12.5898 69.0517 12.5898H69.0417Z"
      //       fill="currentColor"
      //     />
      //     <path
      //       fillRule="evenodd"
      //       clipRule="evenodd"
      //       d="M97.3516 93.0299C97.3516 98.5599 100.902 102.16 106.372 102.16H125.802C128.002 96.5999 129.482 90.4899 130.172 83.8999H106.372C100.902 83.8999 97.3516 87.4899 97.3516 93.0299Z"
      //       fill="currentColor"
      //     />
      //     <path
      //       fillRule="evenodd"
      //       clipRule="evenodd"
      //       d="M70.4102 57.3898C70.4102 62.9298 73.9602 66.5198 79.4302 66.5198H130.17C129.48 59.9298 128 53.8198 125.8 48.2598H79.4302C73.9602 48.2598 70.4102 51.8498 70.4102 57.3898Z"
      //       fill="currentColor"
      //     />
      //     <path
      //       fillRule="evenodd"
      //       clipRule="evenodd"
      //       d="M79.4217 93.0299C79.4217 87.4899 75.8717 83.8999 70.4017 83.8999H25.5117V102.16H70.4017C75.8717 102.16 79.4217 98.5699 79.4217 93.0299Z"
      //       fill="currentColor"
      //     />
      //     <path
      //       fillRule="evenodd"
      //       clipRule="evenodd"
      //       d="M52.4817 57.3898C52.4817 51.8498 48.9317 48.2598 43.4617 48.2598H25.5117V66.5198H43.4717C48.9417 66.5198 52.4917 62.9298 52.4917 57.3898H52.4817Z"
      //       fill="currentColor"
      //     />
      //   </g>
      //   <defs>
      //     <clipPath id="clip0_7039_6637">
      //       <rect width="150" height="150" fill="currentColor" />
      //     </clipPath>
      //   </defs>
      // </svg>
      //   ),
      //   command: ({ editor, range }: any) => {
      //     addYoutubeVideo(editor, range)
      //   },
      // },
      // {
      //   title: 'Loom',
      //   description: 'Embed a Loom video.',
      //   searchTerms: ['loom', 'video'],
      //   icon: (
      //     <svg
      //       xmlns="http://www.w3.org/2000/svg"
      //       height={18}
      //       width={18}
      //       viewBox="0 0 16 16"
      //       fill="currentColor"
      //     >
      //       <path
      //         fill="currentColor"
      //         d="M15 7.222h-4.094l3.546-2.047-.779-1.35-3.545 2.048 2.046-3.546-1.349-.779L8.78 5.093V1H7.22v4.094L5.174 1.548l-1.348.779 2.046 3.545-3.545-2.046-.779 1.348 3.546 2.047H1v1.557h4.093l-3.545 2.047.779 1.35 3.545-2.047-2.047 3.545 1.35.779 2.046-3.546V15h1.557v-4.094l2.047 3.546 1.349-.779-2.047-3.546 3.545 2.047.779-1.349-3.545-2.046h4.093L15 7.222zm-7 2.896a2.126 2.126 0 110-4.252 2.126 2.126 0 010 4.252z"
      //       />
      //     </svg>
      //   ),
      //   command: ({ editor, range }: any) => {
      //     addYoutubeVideo(editor, range)
      //   },
      // }
      // {
      //   name: 'table',
      //   label: 'Table',
      //   iconName: 'Table',
      //   description: 'Insert a table',
      //   shouldBeHidden: (editor) => editor.isActive('columns'),
      //   action: (editor) => {
      //     editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: false }).run()
      //   },
      // },
      // {
      //   name: 'image',
      //   label: 'Image',
      //   iconName: 'Image',
      //   description: 'Insert an image',
      //   aliases: ['img'],
      //   action: (editor) => {
      //     editor.chain().focus().setImageUpload().run()
      //   },
      // },
      // {
      //   name: 'columns',
      //   label: 'Columns',
      //   iconName: 'Columns',
      //   description: 'Add two column content',
      //   aliases: ['cols'],
      //   shouldBeHidden: (editor) => editor.isActive('columns'),
      //   action: (editor) => {
      //     editor
      //       .chain()
      //       .focus()
      //       .setColumns()
      //       .focus(editor.state.selection.head - 1)
      //       .run()
      //   },
      // },
      // {
      //   name: 'horizontalRule',
      //   label: 'Horizontal Rule',
      //   iconName: 'Minus',
      //   description: 'Insert a horizontal divider',
      //   aliases: ['hr'],
      //   action: (editor) => {
      //     editor.chain().focus().setHorizontalRule().run()
      //   },
      // },
      // {
      //   name: 'toc',
      //   label: 'Table of Contents',
      //   iconName: 'Book',
      //   aliases: ['outline'],
      //   description: 'Insert a table of contents',
      //   shouldBeHidden: (editor) => editor.isActive('columns'),
      //   action: (editor) => {
      //     editor.chain().focus().insertTableOfContents().run()
      //   },
      // },
    ],
  },
]

export default GROUPS
