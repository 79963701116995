import { atom } from 'jotai'

export const activeChangelogIdAtom = atom<string>('')

export const isExpandedWidgetAtom = atom<boolean>(false)
export const helpCenterWidgetActiveViewAtom = atom<{
  type: string
  id: string
}>({
  type: 'main',
  id: '',
})
export const helpCenterSearchActiveAtom = atom<boolean>(false)
export const userProfileModalAtom = atom<boolean>(false)
export const createPostAtom = atom<boolean>(false)
export const initialCategoryAtom = atom<string>('')
export const analyticsEventOriginAtom = atom<
  | 'feedback_widget'
  | 'aio_widget'
  | 'portal'
  | 'changelog_widget_small'
  | 'changelog_widget_popup'
  | 'embed_widget'
  | 'survey_widget'
>('portal')
