import axios, { AxiosError } from 'axios'
import qs from 'qs'
import { getCSRFToken } from './lib/organization'
import { mutate } from 'swr'
import { v4 as uuidv4 } from 'uuid'

const isServer = () => {
  return typeof window === 'undefined'
}

let accessToken = ''
let tinybirdToken = ''
let tinybirdFallbackSessionId = ''

const axiosClient = axios.create({
  baseURL: '/api',
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  paramsSerializer: (params) => {
    return qs.stringify(params)
  },
})

export const setAccessToken = (_accessToken: string) => {
  if (_accessToken && accessToken !== _accessToken) {
    accessToken = _accessToken
  }
}

export const getAccessToken = () => {
  return accessToken
}

export const getTinybirdToken = () => {
  return tinybirdToken
}

export const setTinybirdToken = (_tinybirdToken: string) => {
  if (_tinybirdToken && tinybirdToken !== _tinybirdToken) {
    tinybirdToken = _tinybirdToken
  }
}

export const setTinybirdFallbackSessionId = () => {
  if (!tinybirdFallbackSessionId) {
    tinybirdFallbackSessionId = uuidv4()
  }
}

export const getTinybirdFallbackSessionId = () => {
  return tinybirdFallbackSessionId
}

// We will add the current tinybird session id to each request to the backend,
// so the backend can also use that same id when tracking.
const TINYBIRD_COOKIE_NAME = 'session-id'
const getTinybirdSessionId = () => {
  try {
    if (isServer()) {
      return ''
    } else {
      const cookies = document.cookie.split(';')
      for (let i = 0; i < cookies.length; i++) {
        const [key, value] = cookies[i].split('=').map((item) => item.trim())
        if (key === TINYBIRD_COOKIE_NAME) {
          return value
        }
      }
      return '' // Cookie not found
    }
  } catch (error) {
    console.error('Error getting Tinybird session ID:', error)
    return '' // Return empty string in case of any error
  }
}

// Add Tinybird session ID to the request headers
axiosClient.interceptors.request.use((config) => {
  if (isServer()) {
    return config
  } else {
    const tbSessionId = getTinybirdSessionId() || tinybirdFallbackSessionId
    if (tbSessionId && config.headers) {
      config.headers['x-tb-session-id'] = tbSessionId
    }
    return config
  }
})

axiosClient.interceptors.request.use((config) => {
  if (isServer()) {
    return config
  } else {
    if (accessToken && config.headers) {
      if (config.headers['x-access-token'] !== accessToken) {
        config.headers['x-access-token'] = accessToken
      }
    }
    return config
  }
})

axiosClient.interceptors.response.use(undefined, async (error) => {
  const { config: originalReq, response } = error

  // Check for a 403 error with the 'invalid csrf token' message
  if (
    originalReq?.url !== '/v1/organization/csrf' && // Adjust this URL based on where you fetch the CSRF token
    !originalReq?.isRetryAttempt &&
    response &&
    response?.status === 403 &&
    response?.data?.message === 'invalid csrf token'
  ) {
    try {
      const csrfToken = await getCSRFToken() // Get the new CSRF token
      originalReq.isRetryAttempt = true
      originalReq.headers['x-csrf-token'] = csrfToken.data.token // Adjust this based on your CSRF token header name
      mutate('/v1/organization')

      return await axiosClient(originalReq)
    } catch (e) {
      return Promise.reject(error)
    }
  } else {
    return Promise.reject(error)
  }
})

axiosClient.interceptors.response.use(
  function (response) {
    return response
  },
  function (error: AxiosError) {
    // console.error('Looks like there was a problem. Status Code: ')
    return Promise.reject(error)
  }
)

export const defaultFetcher = (reqUrl: string, params?: any) => {
  return axiosClient
    .get(reqUrl, {
      withCredentials: true,
      params,
    })
    .then((res) => res.data)
}

export default axiosClient
