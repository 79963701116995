import { IChangelog } from '@/interfaces/IChangelog'
import axiosClient from '../apiClient'

export function subscribeToChangelog() {
  return axiosClient.post('/v1/changelog/subscribe')
}

export function unsubscribeFromChangelog() {
  return axiosClient.post('/v1/changelog/unsubscribe')
}

export interface ChangelogEditRequest {
  title?: string
  content?: string
  changelogCategories?: IChangelog['changelogCategories']
  featuredImage?: string
  date?: string
  sendNotification?: boolean
  allowedSegmentIds?: string[]
  locale?: string
  scheduledDate?: string
  id?: string
  hideFromBoardAndWidgets?: boolean
}

export interface ChangelogCreateRequest {
  title?: string
  content?: string
  changelogCategories?: IChangelog['changelogCategories']
  id?: string
  displayed?: boolean
  scheduledDate?: string
  date?: string
  sendNotification?: boolean
  allowedSegmentIds?: string[]
  locale?: string
  hideFromBoardAndWidgets?: boolean
}

export interface PublishChangelogRequest {
  id: string
  sendEmail?: boolean
  locales?: Array<{
    locale: string
    sendEmail: boolean
  }>
  scheduledDate?: string
}

export function createChangelog(data: ChangelogCreateRequest) {
  return axiosClient.post('/v1/changelog', data)
}

export function updateChangelog(data: ChangelogEditRequest) {
  return axiosClient.patch('/v1/changelog', data)
}

export function unscheduleChangelog(data: { changelogId: string }) {
  return axiosClient.post('/v1/changelog/unschedule', data)
}

export function unPublishChangelog(data: { id: string; locales: string[] }) {
  return axiosClient.post('/v1/changelog/unpublish', data)
}

export function publishChangelog(data: PublishChangelogRequest) {
  return axiosClient.post('/v1/changelog/publish', data)
}

export function rescheduleChangelog(data: { changelogId: string; scheduledDate: string }) {
  return axiosClient.post('/v1/changelog/schedule', data)
}

export function deleteChangelog(id: string) {
  return axiosClient.delete('/v1/changelog', { data: { id } })
}

export function reactToChangelog(data: { changelogId: string; reaction: string }) {
  return axiosClient.post('/v1/changelog/react', data)
}

// Get single changelog
export function getSingleChangelog(id: string) {
  return axiosClient.get(`/v1/changelog?page=1&id=${id}`)
}
export function getSingleChangelogWithLocale(id: string, locale: string) {
  return axiosClient.get(`/v1/changelog?page=1&id=${id}&locale=${locale}&state=all`)
}

// Generate changelog with ai
export function generateChangelogWithAI(data: {
  locale: string
  description: string
  submissionIds: string[]
}) {
  return axiosClient.post('/v1/changelog/generate', data)
}

export function removeChangelogSubscribers(data: { emails: string[] }) {
  return axiosClient.delete('/v1/changelog/subscribe', { data })
}

export function importSubscribers(data: { emails: string[]; locale?: string }) {
  return axiosClient.post('/v1/changelog/importSubscribers', data)
}
