import { IconPickerItem, iconList } from '.'
import * as React from 'react'
import { Command, CommandGroup, CommandInput, CommandItem, CommandList } from '../radix/Command'
import { cn } from '@/lib'
import { IHelpCenterIcon } from '@/interfaces/IHelpCenter'
import ImageUploadButton from '../ImageUploadButton'
import { Button } from '../radix/Button'
import { XIcon } from '@heroicons/react/solid'
import type { init, SearchIndex, Data } from 'emoji-mart'

interface IconPickerProps {
  library: 'outline' | 'solid' | 'mini'
  hideSearch?: boolean
  // @ts-ignore
  onSelect: (icon: string | undefined, type: IHelpCenterIcon['type']) => void
}

const IconPicker: React.FC<IconPickerProps> = ({ library, hideSearch, onSelect }) => {
  const [emojiMode, setEmojiMode] = React.useState(false)
  const [searchValue, setSearchValue] = React.useState<string>('')
  const [emojiResults, setEmojiResults] = React.useState<any[]>([])
  const [emojiMart, setEmojiMart] = React.useState<{
    SearchIndex: typeof SearchIndex
    data: typeof Data
    init: typeof init
  } | null>(null)

  React.useEffect(() => {
    const loadEmojiMart = async () => {
      const [emojiMartModule, emojiDataModule] = await Promise.all([
        import('emoji-mart'),
        import('@emoji-mart/data') as Promise<typeof Data>,
      ])

      const emojiData = {
        categories: emojiDataModule.categories,
        emojis: emojiDataModule.emojis,
        aliases: emojiDataModule.aliases,
        sheet: emojiDataModule.sheet,
        // Add any other necessary properties
      }

      emojiMartModule.init({ data: emojiData })

      setEmojiMart({
        SearchIndex: emojiMartModule.SearchIndex,
        data: emojiData,
        init: emojiMartModule.init,
      })
    }

    loadEmojiMart()
  }, [])

  const search = React.useCallback(
    async (value: string) => {
      if (!emojiMart) return []

      // If no search value, return all emojis as before
      if (!value) {
        const allEmojis = (emojiMart.data as any).categories.flatMap((category: any) =>
          category.emojis.map((key: string) => (emojiMart.data as any).emojis[key])
        )

        return allEmojis.map((emoji: any) => ({
          icon: emoji.skins[0].native,
          name: emoji.name,
          id: emoji.id,
        }))
      }

      const emojis = await emojiMart.SearchIndex.search(value)
      const searchStr = value.toLowerCase()

      // Filter the emojis more thoroughly:
      // We'll check name, shortcodes, tags, and native character.
      const filteredEmojis = emojis?.filter((emoji: any) => {
        const name = (emoji.name || '').toLowerCase()
        const shortcodes = (emoji.shortcodes || []).map((sc: string) => sc.toLowerCase())
        const tags = (emoji.tags || []).map((t: string) => t.toLowerCase())
        const nativeChars = (emoji.skins || []).map((skin: any) =>
          (skin.native || '').toLowerCase()
        )

        // Check if the search string is in any of these fields
        return (
          name.includes(searchStr) ||
          shortcodes.some((sc: string) => sc.includes(searchStr)) ||
          tags.some((t: string) => t.includes(searchStr)) ||
          nativeChars.some((char: string) => char.includes(searchStr))
        )
      })

      return filteredEmojis?.map((emoji: any) => ({
        icon: emoji.skins[0].native,
        name: emoji.name,
        id: emoji.id,
      }))
    },
    [emojiMart]
  )

  const handleChange = React.useCallback(
    async (value: string) => {
      const resultValue = await search(value)
      setEmojiResults(resultValue)
    },
    [search]
  )

  React.useEffect(() => {
    if (emojiMode && emojiMart) {
      handleChange(searchValue)
    }
  }, [searchValue, emojiMode, emojiMart, handleChange])

  // Rest of the component remains the same
  return (
    <div className="relative">
      <div className="flex items-center w-full gap-1 px-1 border-b bg-secondary/40 border-black/10 dark:border-white/5">
        <p
          onClick={() => setEmojiMode(true)}
          className={cn(
            'text-[13px] hover:border-gray-100/60 dark:hover:border-dark-accent p-2 py-2 border-b border-transparent cursor-pointer font-medium',
            emojiMode && 'border-accent'
          )}
        >
          Emojis
        </p>
        <p
          onClick={() => setEmojiMode(false)}
          className={cn(
            'text-[13px] hover:border-gray-100/60 dark:hover:border-dark-accent p-2 py-2  border-b border-transparent cursor-pointer font-medium',
            !emojiMode && ' border-accent'
          )}
        >
          Icons
        </p>
        <ImageUploadButton
          customClassname="text-[13px] rounded-none hover:border-gray-100/60 dark:hover:border-dark-accent focus:outline-none focus:ring-0 p-2 py-2  border-b border-transparent cursor-pointer font-medium"
          onUploadComplete={(url) => onSelect(url, 'external')}
          imgBg={false}
        />
        {/* <p
        onClick={() => {

        }}
          className={cn(
            'text-[13px] p-2 py-2  border-b border-transparent cursor-pointer font-medium'
          )}
        >
          Custom
        </p> */}

        <Button
          onClick={() => onSelect(undefined, 'predefined')}
          variant={'secondary'}
          size={'icon'}
          className="w-full ml-3 text-xs dark:bg-transparent shadow-none dark:hover:bg-secondary hover:bg-secondary"
        >
          <XIcon className="flex-shrink-0 !h-4 !w-4 secondary-svg mr-1.5" /> Remove
        </Button>
      </div>
      <Command>
        <CommandInput
          value={searchValue}
          onValueChange={setSearchValue}
          // className='py-2'
          className="h-10 dark:bg-secondary/80"
          placeholder="Search for icons..."
          autoFocus={true}
        ></CommandInput>
        <CommandList>
          <CommandGroup>
            {emojiMode
              ? emojiResults?.map((emoji: any, index: number) => (
                  <CommandItem
                    onSelect={() => onSelect(emoji.icon, 'emoji')}
                    value={emoji.name}
                    className="!inline-flex items-center !text-base !font-semibold justify-center w-[37.5px] h-[37.5px]"
                    key={emoji.name + index}
                  >
                    {emoji.icon}
                  </CommandItem>
                ))
              : iconList.map((icon: any, index: number) => (
                  <CommandItem
                    value={icon}
                    onSelect={() => onSelect(icon, 'predefined')}
                    className="!inline-flex items-center justify-center w-[37.5px] h-[37.5px]"
                    key={icon}
                  >
                    <IconPickerItem size={4} icon={icon} />
                  </CommandItem>
                ))}
          </CommandGroup>
        </CommandList>
      </Command>
    </div>
  )
}

export { IconPicker }
