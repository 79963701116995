import { cn } from '@/lib/utils'
import React from 'react'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, error, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          'flex w-full disabled:cursor-not-allowed disabled:opacity-50',
          error
            ? 'ring-red-500/60 focus:ring-0 dark:ring-offset-secondary focus:outline-none dark:ring-red-500/30 ring-1 ring-offset-2'
            : '',
          className
        )}
        ref={ref}
        {...props}
      />
    )
  }
)
Input.displayName = 'Input'

export { Input }
