import { Spinner } from '@/components/editor/components/ui/Spinner'
import { useDropZone, useFileUpload, useUploader } from './hooks'
import { cn } from '@/lib/utils'
import { ChangeEvent, useCallback } from 'react'
import { PhotographIcon, VideoCameraIcon } from '@heroicons/react/solid'
import { toast } from 'sonner'

export const ImageUploader = ({
  onUpload,
}: {
  onUpload: (url: string, type: 'image' | 'video') => void
}) => {
  const { loading, uploadFile } = useUploader({ onUpload })
  const { handleUploadClick, ref } = useFileUpload()

  const validateFileSize = useCallback((file: File) => {
    const isVideo = file.type.startsWith('video/')
    const maxSize = isVideo ? 80 * 1024 * 1024 : 20 * 1024 * 1024 // 80MB for video, 20MB for images
    if (file.size > maxSize) {
      toast.error(
        `File size exceeds the limit (${isVideo ? '80MB for videos' : '20MB for images'})`
      )
      return false
    }
    return true
  }, [])

  const { draggedInside, onDrop, onDragEnter, onDragLeave } = useDropZone({
    uploader: (file) => validateFileSize(file) && uploadFile([file]),
  })

  const onFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0]
        if (validateFileSize(file)) {
          uploadFile([file])
        }
      }
    },
    [uploadFile, validateFileSize]
  )

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8 rounded-lg min-h-[10rem] bg-opacity-80">
        <Spinner className="secondary-svg" size={1.5} />
      </div>
    )
  }

  const wrapperClass = cn(
    'flex items-center justify-center cursor-pointer px-6 py-6 rounded-lg bg-opacity-80',
    draggedInside && 'bg-gray-50 dark:bg-secondary'
  )

  return (
    <div
      className={wrapperClass}
      onDrop={onDrop}
      onDragOver={onDragEnter}
      onDragLeave={onDragLeave}
      contentEditable={false}
      onClick={() => handleUploadClick()}
    >
      <PhotographIcon className="!w-10 !h-10 secondary-svg mr-1.5" />
      <div className="flex flex-col items-center justify-center gap-2">
        <div className="text-sm font-medium text-center">
          {draggedInside ? 'Drop image or MP4 video here' : 'Add an image or MP4 video'}
        </div>
      </div>
      <input
        className="w-0 h-0 overflow-hidden opacity-0"
        ref={ref}
        type="file"
        accept=".jpg,.jpeg,.png,.webp,.gif,.mp4"
        onChange={onFileChange}
      />
    </div>
  )
}

export default ImageUploader
