import React, { useEffect, useState } from 'react'
import '../styles/globals.css'
import { Provider } from 'jotai'
import { SWRConfig } from 'swr'
import Router, { useRouter } from 'next/router'
import NProgress from 'nprogress' //nprogress module
import 'nprogress/nprogress.css' //styles of nprogress
import UpperLoader from '../components/UpperLoader'
import 'react-popper-tooltip/dist/styles.css'
import PlausibleProvider from 'next-plausible'
import AppWrapper from '../components/AppWrapper'
import TagManager from 'react-gtm-module'
import { appWithTranslation } from 'next-i18next'
import nextI18nConfig from '../../next-i18next.config'
import { Mixpanel } from '../lib/mixpanel'
import { useUser } from '../data/user'
import { objectIdToTimestamp } from '../lib/objectIdToTimestamp'
import { defaultSWRConfig } from '@/lib/utils'
import { useScrollRestoration } from '@/hooks/scroll-restorer'
// import { useScrollRestoration } from '@/hooks/hooks'

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

declare global {
  interface Window {
    FeaturebaseWidget: any
  }
}
function MyApp({ Component, pageProps }: any) {
  const { initialState } = pageProps
  const [showChat, setShowChat] = useState(false)
  // useScrollRestoration()
  const { user } = useUser()
  const router = useRouter()

  useScrollRestoration(router, {
    enabled: !router.asPath?.includes('/p') && router.asPath.includes('/widget') ? false : true,
  })

  useEffect(() => {
    if (user && Mixpanel.isLoaded() && showChat) {
      Mixpanel.identify(user.id)
      // set name and email
      Mixpanel.people.set({
        name: user.name,
        email: user.email,
        avatar: user.profilePicture,
        created: objectIdToTimestamp(user.id),
      })
    }
  }, [user, showChat])

  const tagManagerArgs = {
    gtmId: 'GTM-N38MN8X',
  }

  useEffect(() => {
    if (showChat) TagManager.initialize(tagManagerArgs)
  }, [showChat])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!router.pathname.includes('oauth')) {
        setShowChat(
          router.pathname.includes('/dashboard') ||
            window.location.href.includes('/auth') ||
            window.location.href.includes('developers')
        )
      }
    }
  }, [router.pathname])

  return (
    <PlausibleProvider
      domain="featurebasedashboard.app, combinedfeaturebase.app"
      scriptProps={{
        src: '/js/script.js',
      }}
      exclude="/oauth/*"
      enabled={showChat}
    >
      <Provider initialValues={initialState}>
        <SWRConfig value={defaultSWRConfig}>
          <AppWrapper alwaysShowChat={showChat} pageProps={pageProps} Component={Component}>
            <Component {...pageProps} />
            <UpperLoader />
          </AppWrapper>
        </SWRConfig>
      </Provider>
    </PlausibleProvider>
  )
}

export default appWithTranslation(MyApp, nextI18nConfig as any)
