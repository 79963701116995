import React from 'react'
import Script from 'next/script'
import { can, isMember } from '@/lib/acl'
import { useCurrentOrganization } from '@/data/organization'
import { useUser } from '@/data/user'

const DashboardScripts = () => {
  const { user } = useUser()
  const { org } = useCurrentOrganization()

  return (
    <>
      <Script
        id="churn"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              !function(){  
                if (!window.churnkey || !window.churnkey.created) {
                  window.churnkey = { created: true };
                  const a = document.createElement('script');
                  a.src = 'https://assets.churnkey.co/js/app.js?appId=28iygjgqs';
                  a.async = true;
                  const b = document.getElementsByTagName('script')[0];
                  b.parentNode.insertBefore(a, b);
                }
              }();
            `,
        }}
      />
      <Script
        id="featurebase-embedding"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              window.FEATUREBASE_ENV="${
                process.env.NEXT_PUBLIC_ENVIRONMENT === 'development' ? 'development' : 'production'
              }";
              const SDK_URL = "${
                process.env.NEXT_PUBLIC_ENVIRONMENT === 'development'
                  ? 'https://sdk.fbasedev.com/dist/sdk.js'
                  : 'https://do.featurebase.app/js/sdk.js'
              }";

              !(function (e, t) {
                const a = "featurebase-sdk";
                function n() {
                  if (!t.getElementById(a)) {
                    var e = t.createElement("script");
                    (e.id = a),
                      (e.src = SDK_URL),
                      t
                        .getElementsByTagName("script")[0]
                        .parentNode.insertBefore(e, t.getElementsByTagName("script")[0]);
                  }
                }
                "function" != typeof e.Featurebase &&
                  (e.Featurebase = function () {
                    (e.Featurebase.q = e.Featurebase.q || []).push(arguments);
                  }),
                  "complete" === t.readyState || "interactive" === t.readyState
                    ? n()
                    : t.addEventListener("DOMContentLoaded", n);
              })(window, document);
            `,
        }}
      />

      {/* Google Tag */}
      <Script async src={`https://www.googletagmanager.com/gtag/js?id=GTM-N38MN8X`} />
      <Script
        id="google-tag-manager"
        dangerouslySetInnerHTML={{
          __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'GTM-N38MN8X', {
                    page_path: window.location.pathname
                  });
              `,
        }}
      />
      {/* End Google Tag */}
      {can(user?.id, 'manage_billing', org) && (
        <Script
          id="customer-io"
          dangerouslySetInnerHTML={{
            __html: `var _cio = _cio || [];
    (function() {
      var a,b,c;a=function(f){return function(){_cio.push([f].
      concat(Array.prototype.slice.call(arguments,0)))}};b=["load","identify",
      "sidentify","track","page"];for(c=0;c<b.length;c++){_cio[b[c]]=a(b[c])};
      var t = document.createElement('script'),
          s = document.getElementsByTagName('script')[0];
      t.async = true;
      t.id    = 'cio-tracker';
      t.setAttribute('data-site-id', 'db04a2847242b50fcda8');
      t.src = '/js/customerio.js';
      s.parentNode.insertBefore(t, s);
    })();
  `,
          }}
        />
      )}

      <Script src="https://r.wdfl.co/rw.js" data-rewardful="826f64" />
    </>
  )
}

export default DashboardScripts
