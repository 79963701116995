import React, { useState } from 'react'
import SurveyItem from './SurveyItem'
import { SurveyType, getAvailableSurveyType, surveyTypeData } from './Survey'
import { useCurrentOrganization } from '@/data/organization'
import { useUser } from '@/data/user'
import { cn } from '@/lib/utils'
import { ClockIcon, QuestionMarkCircleIcon, StarIcon } from '@heroicons/react/outline'
import 'react-circular-progressbar/dist/styles.css'
import { ISubmission, ISubmissionPaginate } from '@/interfaces/ISubmission'
import { sendSurvey } from 'network/lib/organization'
import { AnimatePresence, motion } from 'framer-motion'
import Tooltip from './Tooltip'
import SurveyVoterList from './SurveyVoterList'
import { KeyedMutator } from 'swr'
import { mutateSubmissionItems } from '@/lib/submissionMutator'
import { useTranslation } from 'next-i18next'
import { isMember } from '@/lib/acl'

export const getColorByScore = (score: number, isText?: boolean) => {
  switch (score) {
    case 0:
      return `bg-gray-200/60 dark:$bg-gray-400`
    case 1:
      return 'bg-lime-500'
    case 2:
      return 'bg-amber-500'
    case 3:
      return 'bg-rose-500'
    default:
      return `bg-gray-200/60 dark:bg-gray-400`
  }
}

export const BallArray: React.FC<{
  scoreType: 'often' | 'importance' | 'urgency'
  submission: ISubmission
  customScore?: number
}> = ({ scoreType, submission, customScore }) => {
  let averageScore = customScore || 0
  if (customScore === undefined) {
    const totalScore = submission?.surveyScores?.[scoreType]?.totalScore || 0
    const totalVotes = submission?.surveyScores?.[scoreType]?.totalVotes || 0

    averageScore = Math.round(totalScore / totalVotes)
  }

  const color = getColorByScore(averageScore)

  const ballArray = (
    <>
      {[1, 2, 3, 4].map((value) => (
        <div
          className={`w-[7px] flex-shrink-0 h-[7px] rounded-full ${
            value <= averageScore + 1 ? color : 'bg-card-foreground/30 dark:bg-card-foreground/30'
          }`}
          key={value}
        ></div>
      ))}
    </>
  )

  return ballArray
}

export const GetIconPerType: React.FC<{
  scoreType: 'often' | 'importance' | 'urgency'
  color?: string
}> = ({ scoreType, color }) => {
  const iconStyle = cn('w-3.5 h-3.5 mr-1', color ? color : 'secondary-svg')
  switch (scoreType) {
    case 'often':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={iconStyle}
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M3 12h4l3 8l4 -16l3 8h4" />
        </svg>
      )
    case 'importance':
      return <StarIcon className={iconStyle} />
    case 'urgency':
      return <ClockIcon className={iconStyle} />
    default:
      return <QuestionMarkCircleIcon className={iconStyle} />
  }
}

const LargeSurvey: React.FC<{
  submission?: ISubmission
  mutateSubmissions: KeyedMutator<any[]>
  rawSubmissionData: ISubmissionPaginate[] | ISubmissionPaginate | undefined
}> = ({ submission, mutateSubmissions, rawSubmissionData }) => {
  const [justVoted, setJustVoted] = React.useState(false)
  const { t } = useTranslation()
  const [activeSurveyType, setActiveSurveyType] = useState<SurveyType | ''>(
    getAvailableSurveyType(submission?.votedSurveys || [], submission?.id || '')
  )

  const { org } = useCurrentOrganization()
  const { user } = useUser()

  const getNameFromScoreTypeAndScore = (scoreType: 'often' | 'importance' | 'urgency') => {
    const totalScore = submission?.surveyScores?.[scoreType]?.totalScore || 0
    const totalVotes = submission?.surveyScores?.[scoreType]?.totalVotes || 0

    // If there are no votes, return early
    if (totalVotes === 0) {
      return 'No votes yet'
    }

    if (scoreType === 'often') {
    }

    // Calculate average and round it to the nearest integer
    const score = Math.round(totalScore / totalVotes)
    const scoreTypeData = surveyTypeData[scoreType]
    // Subtract 1 to account for 0-based array indexing
    return scoreTypeData.options[score]
  }

  const GetNameAndBalls: React.FC<{ scoreType: 'often' | 'importance' | 'urgency' }> = ({
    scoreType,
  }) => {
    const totalScore = submission?.surveyScores?.[scoreType]?.totalScore || 0
    const totalVotes = submission?.surveyScores?.[scoreType]?.totalVotes || 0

    const averageScore = Math.round(totalScore / totalVotes)
    if (!submission) return null
    return (
      <div className="mt-1.5 cursor-default py-1.5 justify-between border dashboard-border px-2 rounded-md flex items-center gap-1">
        <p className="flex items-center gap-1 text-xs font-semibold text-gray-500 dark:text-gray-100 dark:border-gray-500">
          {totalVotes ? (
            t(getNameFromScoreTypeAndScore(scoreType))
          ) : (
            <span className="text-background-accent dark:text-foreground">No votes yet</span>
          )}
          {` `}
          {totalVotes ? (
            <code className="font-medium transparent-code text-background-accent dark:text-foreground/60 text-[10px]">
              ({totalVotes})
            </code>
          ) : null}
        </p>
        <div className="flex items-center gap-1">
          <BallArray submission={submission} scoreType={scoreType} />
        </div>
      </div>
    )
  }

  const availableOptions = activeSurveyType
    ? submission
      ? surveyTypeData[activeSurveyType].options
      : surveyTypeData[activeSurveyType].options?.slice(
          surveyTypeData[activeSurveyType].options.length - 3,
          surveyTypeData[activeSurveyType].options.length
        )
    : null

  if (!user || !submission) return null

  if (submission?.postCategory?.disableFollowUpQuestions) return null

  return (
    <div className="relative flex flex-col pt-3 rounded-md sm:justify-center border-gray-100/60">
      {isMember(user?.id, org) && submission ? (
        <>
          <div className="relative grid gap-4 rounded-lg sm:grid-cols-3">
            <div>
              <p className="relative flex items-center flex-shrink-0 pr-3 text-xs font-medium tracking-wide text-gray-400 dark:text-foreground">
                <GetIconPerType scoreType="often" />
                {t('usage-frequency')}
              </p>
              <Tooltip
                onlyChild={true}
                child={<GetNameAndBalls scoreType="often" />}
                dropDown={<SurveyVoterList scoreType="often" submission={submission} />}
              />
            </div>
            <div>
              <p className="relative flex items-center flex-shrink-0 pr-3 text-xs font-medium tracking-wide text-gray-400 dark:text-foreground sm:truncate">
                <ClockIcon className="w-3.5 h-3.5 mr-1 secondary-svg" />
                {t('urgency')}
              </p>
              <Tooltip
                onlyChild={true}
                child={<GetNameAndBalls scoreType="urgency" />}
                dropDown={<SurveyVoterList scoreType="urgency" submission={submission} />}
              />
            </div>
            <div>
              <p className="relative flex items-center flex-shrink-0 pr-3 text-xs font-medium tracking-wide text-gray-400 dark:text-foreground sm:truncate">
                <StarIcon className="w-3.5 h-3.5 mr-1 secondary-svg" />
                {t('importance')}
              </p>
              <Tooltip
                onlyChild={true}
                child={<GetNameAndBalls scoreType="importance" />}
                dropDown={<SurveyVoterList scoreType="importance" submission={submission} />}
              />
            </div>
          </div>
        </>
      ) : (
        <AnimatePresence initial={false} mode="wait">
          {activeSurveyType && (
            <motion.div
              initial={{
                opacity: 0.4,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0.3,
              }}
              key={activeSurveyType}
            >
              <div className="relative flex items-center">
                <p className="relative flex-shrink-0  flex items-center pr-3 text-xs font-medium text-gray-400 dark:text-foreground tracking-wide">
                  <GetIconPerType scoreType={activeSurveyType} />
                  {t(surveyTypeData[activeSurveyType].title)}{' '}
                </p>
              </div>
              <div className="relative grid items-center w-full grid-cols-2 gap-2 pb-1 mt-2 sm:flex">
                {availableOptions?.map((option: string, index) => (
                  <div className="w-full sm:w-auto" key={index} aria-hidden="true">
                    <SurveyItem
                      small={!submission ? true : false}
                      key={option}
                      index={index}
                      content={t(option)}
                      callback={() => {
                        setJustVoted && setJustVoted(false)
                        const newVotedSurveys = [...submission.votedSurveys, activeSurveyType]
                        const oldVotedSurveys = submission.votedSurveys

                        mutateSubmissionItems(
                          'votedSurveys',
                          newVotedSurveys,
                          mutateSubmissions,
                          rawSubmissionData,
                          submission.id
                        )
                        sendSurvey({
                          type: activeSurveyType,
                          submissionId: submission.id,
                          score: index,
                        })
                          .then(() => {
                            mutateSubmissions()
                          })
                          .catch(() => {
                            mutateSubmissionItems(
                              'votedSurveys',
                              oldVotedSurveys,
                              mutateSubmissions,
                              rawSubmissionData,
                              submission.id
                            )
                          })

                        setActiveSurveyType(getAvailableSurveyType(newVotedSurveys, submission.id))
                      }}
                    />
                  </div>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </div>
  )
}

export default LargeSurvey
