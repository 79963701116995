'use client'

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/radix/Accordion'
import { SidebarGroup, SidebarGroupLabel, SidebarMenu } from '@/components/radix/Sidebar'
import { Button, buttonVariants } from '@/components/radix/Button'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState, useEffect, useMemo } from 'react'
import { useAtom } from 'jotai'
import { settingsActiveSectionAtom } from '@/atoms/orgAtom'

export function CollapsibleNavSection({
  title,
  items,
}: {
  title?: string
  items: {
    title: string
    url?: string
    onSelect?: () => void
    icon?: any
    isActive?: boolean
    accordionContent?: any
    items?: any[]
  }[]
}) {
  const router = useRouter()
  const [activePage] = useAtom(settingsActiveSectionAtom)

  // Calculate initial open sections based on active route
  const initialOpenSections = useMemo(() => {
    return items
      .filter((item) => {
        if (item.items) {
          return item.items.some((subItem: any) => router.pathname.includes(subItem?.url || ''))
        }
        return router.pathname.includes(item?.url || '')
      })
      .map((item) => item.title)
  }, []) // Empty dependency array as this should only run once on mount

  // Use the initial state directly instead of empty array
  const [openSections, setOpenSections] = useState<string[]>(initialOpenSections)

  return (
    <SidebarGroup>
      {title && <SidebarGroupLabel>{title}</SidebarGroupLabel>}
      <SidebarMenu>
        <Accordion
          className="flex flex-col gap-1"
          type="multiple"
          value={openSections}
          onValueChange={setOpenSections}
        >
          {items.map((item) => {
            const isItemActive = item?.url
              ? router.pathname.includes(item?.url || '')
              : item?.isActive

            return !item?.accordionContent ? (
              item.url ? (
                <Link href={item.url || ''} key={item.title}>
                  <Button icon={item.icon} variant={isItemActive ? 'menuActive' : 'menu'} size="sm">
                    <span className="truncate">{item.title}</span>
                  </Button>
                </Link>
              ) : (
                <Button
                  icon={item.icon}
                  onClick={item.onSelect}
                  variant={isItemActive ? 'menuActive' : 'menu'}
                  size="sm"
                >
                  <span className="truncate">{item.title}</span>
                </Button>
              )
            ) : (
              <AccordionItem className="border-b-0" key={item.title} value={item.title}>
                <AccordionTrigger
                  flippedChevron={true}
                  dontTruncate={true}
                  className={buttonVariants({
                    variant: 'menu',
                    className: 'justify-between px-2',
                  })}
                >
                  <span className="flex items-center gap-1">
                    {item.icon && <item.icon className="secondary-svg mr-1" />}
                    <span className="leading-none">{item.title}</span>
                  </span>
                </AccordionTrigger>

                <AccordionContent className="px-2 border-l py-1 dashboard-border ml-[15px] pb-0 mb-2">
                  {item.accordionContent}
                </AccordionContent>
              </AccordionItem>
            )
          })}
        </Accordion>
      </SidebarMenu>
    </SidebarGroup>
  )
}
