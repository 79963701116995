import { ISubmission } from '@/interfaces/ISubmission'
import React from 'react'
import PostExternalLink from './PostExternalLink'
import { unlinkDevOpsWorkItem } from 'network/lib/organization'
import ConfirmationModal from './ConfirmationModal'
import { KeyedMutator } from 'swr'
import { toast } from 'sonner'
import { AxiosResponse } from 'axios'

const DevOpsLinks: React.FC<{ submission: ISubmission; mutateSubmissions: KeyedMutator<any> }> = ({
  submission,
  mutateSubmissions,
}) => {
  const [showModal, setShowModal] = React.useState(false)
  const [activeWorkItem, setActiveWorkItem] = React.useState<{
    id: number
    projectId: string
  }>({
    id: 0,
    projectId: '',
  })

  const unlink = () => {
    if (activeWorkItem) {
      unlinkDevOpsWorkItem({
        submissionId: submission.id,
        workItemId: activeWorkItem.id,
        projectId: activeWorkItem.projectId,
      })
        .then(() => {
          mutateSubmissions()
          toast.success('Work item unlinked successfully')
        })
        .catch((err: AxiosResponse) => {
          toast.error(err?.data?.message)
        })
        .finally(() => {
          setActiveWorkItem({
            id: 0,
            projectId: '',
          })
        })
    }
  }

  if (submission?.devopsWorkItems?.length === 0) return null
  return (
    <>
      <ConfirmationModal
        title="Unlink Azure DevOps Work Item"
        callBack={unlink}
        description="Are you sure you want to unlink this work item?"
        open={showModal}
        setOpen={setShowModal}
        buttonTxt="Unlink"
      />
      <div>
        {submission?.devopsWorkItems?.map((workItem, index) => (
          <div key={index} className="mt-3">
            <PostExternalLink
              bgColor="bg-gray-100 dark:bg-dark-accent"
              onClick={() => {
                setActiveWorkItem({
                  id: workItem.id,
                  projectId: workItem.projectId,
                })
                setShowModal(true)
              }}
              icon={
                <>
                  {/*?xml version="1.0" encoding="utf-8"?*/}
                  {/* Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools */}
                  <svg
                    width="14px"
                    height="14px"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    className="text-gray-500 dark:text-white"
                  >
                    <path
                      fill="url(#azure-devops-color-16__paint0_linear_707_116)"
                      d="M15 3.622v8.512L11.5 15l-5.425-1.975v1.958L3.004 10.97l8.951.7V4.005L15 3.622zm-2.984.428L6.994 1v2.001L2.382 4.356 1 6.13v4.029l1.978.873V5.869l9.038-1.818z"
                    />
                    <defs>
                      <linearGradient
                        id="azure-devops-color-16__paint0_linear_707_116"
                        x1={8}
                        x2={8}
                        y1="14.956"
                        y2="1.026"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#0078D4" />
                        <stop offset=".16" stopColor="#1380DA" />
                        <stop offset=".53" stopColor="#3C91E5" />
                        <stop offset=".82" stopColor="#559CEC" />
                        <stop offset={1} stopColor="#5EA0EF" />
                      </linearGradient>
                    </defs>
                  </svg>
                </>
              }
              link={workItem.url}
              title={`#${workItem.id} ${workItem.title} (${workItem.projectName})`}
            />
          </div>
        ))}
      </div>
    </>
  )
}

export default DevOpsLinks
