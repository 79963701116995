import * as React from 'react'
import {
  DayPicker,
  Button,
  DayProps,
  useDayRender,
  SelectSingleEventHandler,
} from 'react-day-picker'

import { cn } from '@/lib/utils'
import { ChevronLeftIcon, ChevronRightIcon, XIcon } from '@heroicons/react/solid'
import { buttonVariants } from './Button'
import { getEndDate } from '@/lib/utils'
import TimePicker from '../TimePicker'
import { Button as RadixButton } from '@/components/radix/Button'
import { ContextMenuItem } from '@radix-ui/react-context-menu'
import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu'
import { toast } from 'sonner'

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  customDayButton?: 'context' | 'dropdown'
}

function CustomButton(
  props: DayProps & { customDayButton: 'context' | 'dropdown' } & {
    setTimeSelectionCachedDate: React.Dispatch<React.SetStateAction<Date | undefined>> | undefined
  }
) {
  const buttonRef = React.useRef<HTMLButtonElement>(null)
  const dayRender = useDayRender(props.date, props.displayMonth, buttonRef)

  const ButtonWrapper = props.customDayButton === 'context' ? ContextMenuItem : DropdownMenuItem

  if (props.customDayButton === 'dropdown') {
    dayRender.buttonProps.className = cn(dayRender.buttonProps.className)
  }

  return (
    <ButtonWrapper
      onSelect={(event) => {
        if (props.setTimeSelectionCachedDate) {
          props.setTimeSelectionCachedDate(props.date)
          event.preventDefault()
        }
      }}
      asChild
    >
      <Button
        // className={cn(props.customDayButton === 'context' && '!dark:bg-transparent')}
        {...dayRender.buttonProps}
        ref={buttonRef}
      />
    </ButtonWrapper>
  )
}

const getNextQuarters = () => {
  const currentYear = new Date().getFullYear()
  const currentQuarter = Math.ceil((new Date().getMonth() + 1) / 3)
  const newActiveQuarters = Array.from({ length: 4 }, (_, i) => {
    const quarterNum = ((currentQuarter + i - 1) % 4) + 1
    const year = currentYear + Math.floor((currentQuarter + i - 1) / 4)
    return `Q${quarterNum}'${year.toString().slice(-2)}`
  })

  return newActiveQuarters
}

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  customDayButton,
  exactTimeSelection,
  ...props
}: CalendarProps & {
  onSelect?: SelectSingleEventHandler
  showQuarterButtons?: boolean
  unscheduling?: boolean
  exactTimeSelection?: boolean
  scheduleDate?: (date: Date | null) => any
}) {
  const [timeSelectionCachedDate, setTimeSelectionCachedDate] = React.useState<Date | undefined>(
    (props?.selected as Date) || undefined
  )

  const ButtonWrapper = customDayButton === 'context' ? ContextMenuItem : DropdownMenuItem

  return (
    <>
      {exactTimeSelection && (
        <div className="flex items-center justify-between w-full p-3 border-b bg-card/60">
          {timeSelectionCachedDate && (
            <TimePicker
              setStartDate={(date) => {
                setTimeSelectionCachedDate(date)
              }}
              startDate={timeSelectionCachedDate}
            />
          )}
          <ButtonWrapper
            className="ml-auto"
            onSelect={(event) => {
              if (!timeSelectionCachedDate) {
                toast.error('Please select a date first')
                return
              }
              props.scheduleDate && props.scheduleDate(timeSelectionCachedDate)
            }}
            asChild
          >
            <RadixButton size={'sm'}>{props?.unscheduling ? 'Reschedule' : 'Schedule'}</RadixButton>
          </ButtonWrapper>
        </div>
      )}
      {props?.unscheduling && (
        <div className="p-3 pb-0">
          {customDayButton === 'context' ? (
            <ContextMenuItem
              className={cn(
                buttonVariants({ variant: 'outline' }),
                'w-full cursor-pointer -mt-1 dark:bg-border dark:border-dark-accent dark:hover:bg-dark-accent/80'
              )}
              onSelect={() => {
                // @ts-ignore
                props.scheduleDate ? props.scheduleDate(null) : props.onSelect?.(null)
              }}
            >
              <XIcon className="w-4 h-4 mr-2 secondary-svg" />
              Unschedule
            </ContextMenuItem>
          ) : (
            <button
              className={cn(
                buttonVariants({ variant: 'outline' }),
                'w-full dark:bg-border dark:border-dark-accent dark:hover:bg-dark-accent/80'
              )}
              onClick={() => {
                // @ts-ignore
                props.scheduleDate ? props.scheduleDate(null) : props.onSelect?.(null)
              }}
            >
              <XIcon className="w-4 h-4 mr-2 secondary-svg" />
              Unschedule
            </button>
          )}
        </div>
      )}
      {props?.showQuarterButtons && (
        <div className="flex items-center gap-2 p-3 border-b">
          {getNextQuarters().map((quarter) =>
            customDayButton === 'context' ? (
              <ContextMenuItem
                key={quarter}
                className="flex items-center justify-center w-full py-1 text-xs font-semibold text-center uppercase rounded cursor-pointer main-transition dashboard-secondary dark:bg-border dark:border-dark-accent dark:hover:bg-dark-accent/80 dark:shadow-none"
                onSelect={() => {
                  const newDate = getEndDate(quarter)
                  // @ts-ignore
                  props.onSelect?.(newDate)
                }}
              >
                {quarter?.split("'")[0]}
                <span className="-mt-px text-[10px]">'{quarter?.split("'")[1]}</span>
              </ContextMenuItem>
            ) : (
              <button
                key={quarter}
                className="flex items-center justify-center w-full py-1 text-xs font-semibold text-center uppercase rounded dashboard-secondary dark:bg-border dark:border-dark-accent dark:hover:bg-dark-accent/80 dark:shadow-none"
                onClick={() => {
                  const newDate = getEndDate(quarter)
                  // @ts-ignore
                  props.onSelect?.(newDate)
                }}
              >
                {quarter?.split("'")[0]}
                <span className="-mt-px text-[10px]">'{quarter?.split("'")[1]}</span>
              </button>
            )
          )}
        </div>
      )}
      <DayPicker
        // mode="single"
        disabled={
          exactTimeSelection
            ? {
                before: new Date(),
              }
            : undefined
        }
        // selected={exactTimeSelection ? timeSelectionCachedDate : props.selected}
        showOutsideDays={showOutsideDays}
        className={cn('p-3', className)}
        classNames={{
          months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
          month: 'space-y-4',
          caption: 'flex justify-center pt-1 relative items-center',
          caption_label: 'text-sm font-medium',
          nav: 'space-x-1 flex items-center',
          nav_button: cn(buttonVariants({ variant: 'outline' }), 'h-7 w-7 p-0'),
          nav_button_previous: 'absolute left-1',
          nav_button_next: 'absolute right-1',
          table: 'w-full border-collapse space-y-1',
          head_row: 'flex',
          head_cell: 'text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]',
          row: 'flex w-full mt-2',
          //  [&:has([aria-selected])]:bg-accent
          cell: 'h-9 w-9 text-center text-sm p-0 relative first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
          day: cn(
            buttonVariants({ variant: 'ghost' }),
            'h-9 w-9 p-0 dark:text-gray-100 font-normal aria-selected:opacity-100 hover:bg-gray-100/60'
          ),
          day_selected:
            'dashboard-primary hover:bg-primary/60 dark:hover:bg-primary/60 hover:text-white dark:text-primary-foreground',
          day_today:
            'bg-accent/30 hover:bg-accent hover:text-white dark:hover:bg-accent/40 text-accent-foreground',
          day_outside: 'text-muted-foreground opacity-75',
          day_disabled: 'text-muted-foreground opacity-75',
          day_range_middle: '',
          day_hidden: 'invisible',
          ...classNames,
        }}
        components={
          customDayButton
            ? {
                IconLeft: ({ ...props }) => <ChevronLeftIcon className="w-4 h-4" />,
                IconRight: ({ ...props }) => <ChevronRightIcon className="w-4 h-4" />,
                Day: ({ ...props }) => (
                  <CustomButton
                    setTimeSelectionCachedDate={
                      exactTimeSelection ? setTimeSelectionCachedDate : undefined
                    }
                    customDayButton={customDayButton}
                    {...props}
                  />
                ),
              }
            : {
                IconLeft: ({ ...props }) => <ChevronLeftIcon className="w-4 h-4" />,
                IconRight: ({ ...props }) => <ChevronRightIcon className="w-4 h-4" />,
              }
        }
        {...props}
      />
    </>
  )
}
Calendar.displayName = 'Calendar'

export { Calendar }
