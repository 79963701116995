import React from 'react'
import SimpleTooltip from './SimpleTooltip'
import { format } from 'date-fns'
import { useTranslation } from 'next-i18next'

const DateWrapper: React.FC<{ children: React.ReactNode; date: string }> = ({ children, date }) => {
  const { t } = useTranslation()

  return (
    <SimpleTooltip
      content={
        date ? (
          <span className="py-1">
            <span className="dark:text-foreground/90 text-foreground/90">
              {t('words-that-say-created-on-in-desired-language')}
            </span>{' '}
            <span className="font-medium">{format(new Date(date || ''), 'P, p')}</span>
          </span>
        ) : null
      }
    >
      <span className="cursor-default">{children}</span>
    </SimpleTooltip>
  )
}

export default DateWrapper
