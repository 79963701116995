import ScreenshotPlaceholder from '@/components/ScreenshotPlaceholder'
import { cn } from '@/lib/utils'
import { Node } from '@tiptap/pm/model'
import { Editor, NodeViewWrapper } from '@tiptap/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { onUpload, uploadFn } from '@/components/editor/image-upload'
import { TextSelection } from '@tiptap/pm/state'
import { toast } from 'sonner'
import Loader from '@/components/Loader'

interface ImageBlockViewProps {
  editor: Editor
  getPos: () => number
  node: Node & {
    attrs: {
      src: string
      type?: 'image' | 'video'
    }
  }
  updateAttributes: (attrs: Record<string, string>) => void
}

export const ImageBlockView = (props: ImageBlockViewProps) => {
  const { editor, getPos, node } = props
  const mediaWrapperRef = useRef<HTMLDivElement>(null)
  const { src, type = 'image' } = node.attrs
  const [isProcessing, setIsProcessing] = useState(false)

  const uploadingToOurCdn = useRef(false)

  const isNotFromOurCDN = !src.includes('fb-usercontent.fra1.cdn.digitaloceanspaces.com')

  useEffect(() => {
    if (isNotFromOurCDN && !uploadingToOurCdn.current) {
      uploadingToOurCdn.current = true

      const processUpload = async () => {
        try {
          let fileToUpload
          if (src.startsWith('data:')) {
            const response = await fetch(src)
            const blob = await response.blob()
            fileToUpload = new File([blob], 'image.png', {
              type: blob.type || 'image/png',
            })
          } else {
            fileToUpload = src
          }

          const url = await onUpload(fileToUpload)
          if (url) {
            props.updateAttributes({ src: url as string })
          } else {
            toast.error('Failed to upload image to our CDN')
          }
        } catch (error) {
          console.error('Upload error:', error)
          toast.error('Failed to upload image to our CDN')
        } finally {
          uploadingToOurCdn.current = false
        }
      }

      processUpload()
    }
  }, [isNotFromOurCDN, src, props])

  const wrapperClassName = cn(
    node.attrs.align === 'left' ? 'ml-0' : 'ml-auto',
    node.attrs.align === 'right' ? 'mr-0' : 'mr-auto',
    node.attrs.align === 'center' && 'mx-auto'
  )

  const onClick = useCallback(() => {
    editor.commands.setNodeSelection(getPos())
  }, [getPos, editor.commands])

  const handleImageError = (err: any) => {
    if (src.includes('/ss-')) {
      setIsProcessing(true)
    }
  }

  const renderMedia = () => {
    if (isNotFromOurCDN) {
      return (
        <div className="flex items-center justify-center w-full py-8 px-4 text-center h-full bg-gray-100/30 dark:bg-transparent dark:bg-gradient-to-br dark:from-dark-accent/30 dark:to-dark-accent/50 rounded-md">
          <div className="!h-4 !w-4 secondary-svg">
            <Loader />
          </div>
        </div>
      )
    }
    if (isProcessing) {
      return <ScreenshotPlaceholder />
    }

    if (type === 'video') {
      return (
        <ReactPlayer
          url={src}
          controls
          width="100%"
          height="100%"
          onClick={onClick}
          config={{
            file: {
              attributes: {
                style: { objectFit: 'contain' },
              },
            },
          }}
        />
      )
    }
    return <img className="block" src={src} alt="" onClick={onClick} onError={handleImageError} />
  }

  return (
    <NodeViewWrapper>
      <div className={wrapperClassName} style={{ width: node.attrs.width }}>
        <div contentEditable={false} ref={mediaWrapperRef}>
          {renderMedia()}
        </div>
      </div>
    </NodeViewWrapper>
  )
}

export default ImageBlockView
