import * as React from 'react'
import * as HeroIcons from '@heroicons/react/solid'
import { RefreshIcon } from '@heroicons/react/outline'
import { IconList } from './iconType'
import { cn } from '@/lib'

interface IconPickerItemProps {
  icon: IconList
  size?: number
  noSize?: boolean
}

const IconPickerItem: React.FC<IconPickerItemProps> = ({ icon, size = 6, noSize }) => {
  if (icon === 'RefreshIcon') {
    return (
      <div className={cn(noSize ? '' : `!w-${size} !h-${size} !m-0 !p-0`)}>
        <RefreshIcon />
      </div>
    )
  }

  if (icon === 'BookOpenIcon') {
    return (
      <div className={cn(noSize ? '' : `!w-${size} !h-${size} !m-0 !p-0`)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
          <path d="M11.25 4.533A9.707 9.707 0 0 0 6 3a9.735 9.735 0 0 0-3.25.555.75.75 0 0 0-.5.707v14.25a.75.75 0 0 0 1 .707A8.237 8.237 0 0 1 6 18.75c1.995 0 3.823.707 5.25 1.886V4.533ZM12.75 20.636A8.214 8.214 0 0 1 18 18.75c.966 0 1.89.166 2.75.47a.75.75 0 0 0 1-.708V4.262a.75.75 0 0 0-.5-.707A9.735 9.735 0 0 0 18 3a9.707 9.707 0 0 0-5.25 1.533v16.103Z" />
        </svg>
      </div>
    )
  }
  if (icon === 'SpeakerphoneIcon') {
    return (
      <div className={cn(noSize ? '' : `!w-${size} !h-${size} !m-0 !p-0`)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
          <path d="M16.881 4.345A23.112 23.112 0 0 1 8.25 6H7.5a5.25 5.25 0 0 0-.88 10.427 21.593 21.593 0 0 0 1.378 3.94c.464 1.004 1.674 1.32 2.582.796l.657-.379c.88-.508 1.165-1.593.772-2.468a17.116 17.116 0 0 1-.628-1.607c1.918.258 3.76.75 5.5 1.446A21.727 21.727 0 0 0 18 11.25c0-2.414-.393-4.735-1.119-6.905ZM18.26 3.74a23.22 23.22 0 0 1 1.24 7.51 23.22 23.22 0 0 1-1.41 7.992.75.75 0 1 0 1.409.516 24.555 24.555 0 0 0 1.415-6.43 2.992 2.992 0 0 0 .836-2.078c0-.807-.319-1.54-.836-2.078a24.65 24.65 0 0 0-1.415-6.43.75.75 0 1 0-1.409.516c.059.16.116.321.17.483Z" />
        </svg>
      </div>
    )
  }

  const IconComponent = HeroIcons[icon as keyof typeof HeroIcons]

  if (!IconComponent) {
    console.warn(`Icon "${icon}" not found`)
    return null
  }

  return (
    <div className={cn(noSize ? '' : `!w-${size} !h-${size} !m-0 !p-0`)}>
      <IconComponent />
    </div>
  )
}

export { IconPickerItem }
