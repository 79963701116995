'use client'

import * as React from 'react'
import { Sidebar } from '@/components/radix/Sidebar'

export function DashboardDynamicSidebar({
  sidebarContent,
  sidebarWidth,
  ...props
}: React.ComponentProps<typeof Sidebar> & {
  sidebarContent?: React.ReactNode
  sidebarWidth?: string
}) {
  return (
    <Sidebar sidebarWidth={sidebarWidth} className="border-r-0" {...props}>
      {sidebarContent}
      {/* {sidebarContent !== undefined && <SidebarRail />} */}
    </Sidebar>
  )
}
