'use client'

import { AnimatePresence, motion } from 'framer-motion'
import useMeasure from 'react-use-measure'
import { ignoreCircularReferences } from './radix/Popover'
import React from 'react'

const ResizablePanel: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [ref, { height }] = useMeasure()
  const [initialHeight, setInitialHeight] = React.useState<number | null>(null)

  // Set the initial height once it's measured
  React.useEffect(() => {
    if (height && initialHeight === null) {
      setInitialHeight(height)
    }
  }, [height, initialHeight])

  // If the initial height hasn't been measured yet, render the content without animation
  if (initialHeight === null) {
    return <div ref={ref}>{children}</div>
  }

  return (
    <motion.div
      animate={{ height: height || 'auto' }}
      initial={{ height: initialHeight }}
      className="relative overflow-hidden"
    >
      <AnimatePresence initial={false}>
        <motion.div
          key={JSON.stringify(children, ignoreCircularReferences())}
          className={height ? 'absolute w-full' : 'relative w-full'}
        >
          <div ref={ref}>{children}</div>
        </motion.div>
      </AnimatePresence>
    </motion.div>
  )
}

export default ResizablePanel
