import { IHelpCenterIcon } from '@/interfaces/IHelpCenter'
import React from 'react'
import BoardIcon from './BoardIcon'
import { cn } from '@/lib'
import { IPostCategory } from '@/interfaces/ISubmission'
import { getFirstEmoji, removeEmojis } from '@/pages/widget/feedback-widget'
import { useTranslation } from 'next-i18next'
import { activeCategoryName } from '@/lib/utils'

const FeedbackWidgetCard: React.FC<{
  // title: string
  // description: string
  index: number
  category: IPostCategory
  // fullName: string
  // icon: IHelpCenterIcon | null
  setActivePage: (value: React.SetStateAction<IPostCategory>) => void
}> = ({ index, category, setActivePage }) => {
  const { i18n } = useTranslation()

  const nameOfCategory = activeCategoryName(category, i18n?.language)

  return (
    <button
      onClick={() => setActivePage(category)}
      className="relative flex items-center w-full gap-3 p-3 text-base font-medium text-gray-600 border rounded-md dark:text-white white-bg-hover group"
    >
      {(category.icon || getFirstEmoji(nameOfCategory)) && (
        <div className="board-icon-wrapper text-center">
          {category.icon ? (
            <BoardIcon className="inline-block" noMargin={true} icon={category.icon} />
          ) : (
            getFirstEmoji(nameOfCategory) && (
              <div className="">
                <span className="w-4">{getFirstEmoji(nameOfCategory)}</span>
              </div>
            )
          )}
        </div>
      )}
      <div className="flex justify-between w-full">
        {removeEmojis(nameOfCategory)}
        <code className="pl-3 pr-2  my-auto text-sm border-l pointer-events-none code dark:border-white/[5%] bg-transparent dark:bg-transparent dark:!text-foreground/60 rounded-none !text-background-accent/80">
          {index}
        </code>
      </div>
    </button>
  )
}
export default FeedbackWidgetCard
