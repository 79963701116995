import { Command, CommandItem, CommandList } from 'cmdk'
import { sanitizeHTML } from '@/lib/contentSanitizer'
import { useRouter } from 'next/router'
import { useAtom } from 'jotai'
import { helpCenterUrlPartsAtom } from '@/atoms/orgAtom'
import { DocumentIcon } from '@heroicons/react/solid'
import { useTranslation } from 'next-i18next'
import React, { useState } from 'react'
import { useCurrentOrganization } from '@/data/organization'
import { isPlan } from '@/lib/utils'

interface HitProps {
  hit: any
}

const Hit: React.FC<HitProps> = ({ hit }) => {
  // Function to count the number of highlights in a given attribute
  const countHighlights = (attribute: string) => {
    const content = hit._highlightResult?.[attribute]?.value || ''
    return (content.match(/<ais-highlight-0000000000>/g) || []).length
  }

  // Determine whether to show body or description
  const showBody = countHighlights('strippedBody') > countHighlights('description')

  // Function to render highlighted content
  const renderHighlightedContent = (content: string) => {
    // Replace highlight tags with <mark>
    const replacedContent = content.replace(
      /<ais-highlight-0000000000>(.*?)<\/ais-highlight-0000000000>/g,
      '<mark>$1</mark>'
    )

    return sanitizeHTML(replacedContent, false, false, true)
  }

  return (
    <a>
      <div className="list-none relative px-4 py-3.5 cursor-pointer border-gray-100/80 dark:border-border">
        {hit?.state === 'draft' && (
          <div className="flex items-center gap-1.5 px-1.5 py-1 absolute top-2 right-2">
            <DocumentIcon className="h-3.5 w-3.5 secondary-svg" />
            <p className="text-xs font-semibold text-gray-600 dark:text-gray-100/90">Draft</p>
          </div>
        )}
        <div className="flex items-center gap-1.5 pr-10">
          <p className="flex items-center text-sm font-semibold text-gray-600 dark:text-white">
            <span
              dangerouslySetInnerHTML={{
                __html: renderHighlightedContent(hit._highlightResult.title.value),
              }}
            />
          </p>
        </div>
        {showBody
          ? hit?._highlightResult?.strippedBody?.value && (
              <p className="max-w-[680px] mt-2 text-[13px] text-gray-500 line-clamp-3 dark:text-foreground">
                <span
                  dangerouslySetInnerHTML={{
                    __html: renderHighlightedContent(hit._highlightResult.strippedBody.value),
                  }}
                />
              </p>
            )
          : hit?._highlightResult?.description?.value && (
              <p className="max-w-[680px] mt-2 text-[13px] text-gray-500 line-clamp-3 dark:text-foreground">
                <span
                  dangerouslySetInnerHTML={{
                    __html: renderHighlightedContent(hit._highlightResult.description.value),
                  }}
                />
              </p>
            )}
      </div>
    </a>
  )
}

interface HitsProps {
  hits: any[]
  state: { query: string }
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
  setActiveView?: React.Dispatch<React.SetStateAction<{ type: string; id: string }>>
  dashboardView?: boolean
  setShowAIView: React.Dispatch<React.SetStateAction<boolean>>
  showAIView: boolean
  aiSearchForQuery: string
  setAiSearchForQuery: React.Dispatch<React.SetStateAction<string>>
}

const Hits: React.FC<HitsProps> = ({
  hits,
  state,
  setOpen,
  setActiveView,
  dashboardView,
  setShowAIView,
  showAIView,
  aiSearchForQuery,
  setAiSearchForQuery,
}) => {
  const router = useRouter()
  const [helpCenterUrlParts] = useAtom(helpCenterUrlPartsAtom)
  const { t } = useTranslation()
  const { org } = useCurrentOrganization()
  const isRequiredPlan = isPlan(org?.plan, 'growth')

  return (
    <CommandList
      className={`list-none h-full max-h-[60dvh] custom-scrollbar-stronger overscroll-contain z-50 overflow-y-auto journal-scroll inset-x-0`}
    >
      <div className="divide-y divide-foreground/10">
        {state.query && isRequiredPlan && !dashboardView && (
          <CommandItem
            onSelect={() => {
              setAiSearchForQuery(state.query)
              setShowAIView(true)
            }}
            className="w-full flex items-center gap-3 main-transition hover:bg-accent/5 dark:hover:bg-accent/[3.5%] aria-selected:bg-accent/5 dark:aria-selected:bg-accent/[3.5%] data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
          >
            <a>
              <div
                className={`list-none w-full flex items-center relative px-4 py-3.5 cursor-pointer border-gray-100/80 dark:border-border `}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="text-accent h-7 w-7 mr-3"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z"
                    clipRule="evenodd"
                  />
                </svg>

                <div className="w-full pr-10 ">
                  <p className="w-full text-sm font-semibold text-gray-600 dark:text-white line-clamp-2">
                    {t('ask-ai-about')} <span className="mark-style">{state.query}</span>
                  </p>
                  <p className="max-w-[680px] mt-0.5 text-[13px] text-gray-500 line-clamp-3 dark:text-foreground">
                    <span>{t('use-ai-to-answer-your-question-in-seconds')}</span>
                  </p>
                </div>
              </div>
            </a>
          </CommandItem>
        )}
        {hits &&
          hits?.length > 0 &&
          hits.map((hit: any) => {
            return (
              <Command.Item
                onSelect={() => {
                  setActiveView
                    ? setActiveView({
                        type: 'article',
                        id: hit.articleId,
                      })
                    : router.push(
                        dashboardView
                          ? `/dashboard/articles/${hit.articleId}`
                          : `${helpCenterUrlParts.subpath}articles/${hit.slug}`
                      )
                  setOpen && setOpen(false)
                }}
                key={hit.id}
                className="w-full main-transition hover:bg-accent/5 dark:hover:bg-accent/[3.5%] aria-selected:bg-accent/5 dark:aria-selected:bg-accent/[3.5%] data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
              >
                <Hit hit={hit} />
              </Command.Item>
            )
          })}
      </div>
      {!hits?.length && !isRequiredPlan && (
        <div className="flex flex-col items-center justify-center mx-auto space-y-3 my-7">
          <p className="text-sm font-medium text-background-accent dark:text-foreground/80">
            {t('no-results-found')}
          </p>
        </div>
      )}
    </CommandList>
  )
}

export default Hits
