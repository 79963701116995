import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import SurveyItem from './SurveyItem'
import { sendSurvey } from 'network/lib/organization'
import { ISubmission, ISubmissionPaginate } from '@/interfaces/ISubmission'
import CryptoJS from 'crypto-js'
import { useCurrentOrganization } from '@/data/organization'
import { useUser } from '@/data/user'
import { mutateSubmissionItems } from '@/lib/submissionMutator'
import { KeyedMutator } from 'swr'
import { useTranslation } from 'next-i18next'
import { isMember } from '@/lib/acl'

export const surveyTypeData = {
  often: {
    title: 'survey.often.title',
    options: [
      'survey.often.never',
      'survey.often.occasionally',
      'survey.often.regularly',
      'survey.often.daily',
    ],
  },
  importance: {
    title: 'survey.importance.title',
    options: [
      'survey.importance.notImportant',
      'survey.importance.niceToHave',
      'survey.importance.important',
      'survey.importance.essential',
    ],
  },
  urgency: {
    title: 'survey.urgency.title',
    options: [
      'survey.urgency.never',
      'survey.urgency.later',
      'survey.urgency.next',
      'survey.urgency.now',
    ],
  },
}

export const surveyTypeDataOld = {
  often: {
    title: 'How often would you use this?',
    options: ['😬 Never', '🙂 Occasionally', '😃 Regularly', '🤩 Daily'],
  },
  importance: {
    title: 'How important is this to you?',
    options: ['😬 Not important', '🥱 Nice to have', '😃 Important', '🤩 Essential'],
  },
  urgency: {
    title: 'When do you need this?',
    options: ['😬 Never', '🥱 Later', '😃 Next', '🤩 Now'],
  },
}
export type SurveyType = 'urgency' | 'importance' | 'often'

export const getAvailableSurveyType = (votedSurveys: SurveyType[], submissionId: string) => {
  if (votedSurveys.length === 3) return ''
  const allSurveyTypes: SurveyType[] = ['importance', 'urgency', 'often']
  const unvotedSurveyTypes = allSurveyTypes.filter((type) => !votedSurveys.includes(type))

  return unvotedSurveyTypes[0]
}

function stringToNumber(string: string, length: number) {
  const hash = CryptoJS.SHA256(string)
  const number = parseInt(hash.toString(CryptoJS.enc.Hex).slice(0, 8), 16)
  return number % length // Modulus by length to get a number between 0 and length-1
}

const Survey: React.FC<{
  submissionId: string
  setJustVoted?: React.Dispatch<React.SetStateAction<boolean>>
  justVoted?: boolean
  width?: number
  isVoteSurvey?: boolean
  submission: ISubmission
  renderDate?: Date | undefined
  mutateSubmissions: KeyedMutator<any[]>
  rawSubmissionData: ISubmissionPaginate[] | ISubmissionPaginate | undefined
}> = ({
  submissionId,
  setJustVoted,
  justVoted,
  width,
  isVoteSurvey,
  submission,
  renderDate,
  mutateSubmissions,
  rawSubmissionData,
}) => {
  const [activeSurveyType, setActiveSurveyType] = useState<SurveyType | ''>(
    getAvailableSurveyType(submission?.votedSurveys || [], submissionId)
  )
  const { t } = useTranslation()
  useEffect(() => {
    setActiveSurveyType(getAvailableSurveyType(submission?.votedSurveys || [], submissionId))
  }, [submission?.votedSurveys, submissionId])

  const { org } = useCurrentOrganization()
  const { user } = useUser()

  const isMobile = width ? width < 600 : false
  if (!activeSurveyType || !user) return null

  if (isMember(user?.id, org)) return null

  if (submission?.postCategory?.disableFollowUpQuestions) return null

  return (
    <motion.div
      className="flex flex-col [--survey-width:100%] sm:absolute sm:right-[64px]  inset-y-0 bg-white/60 dark:bg-gray-800/30 dark:backdrop-blur-md backdrop-blur sm:[--survey-width:335px] items-start justify-center overflow-hidden flex-shrink-0 px-4 py-4 min-h-[98px] dark:border-border  sm:border-l  "
      initial={{
        opacity: 0,
        width: isMobile ? '100%' : 0,
        paddingLeft: isMobile ? 16 : 0,
        paddingRight: isMobile ? 16 : 0,
        translateY: isMobile ? -16 : 0,
      }}
      animate={{
        opacity: 1,
        width: 'var(--survey-width)',
        paddingLeft: 16,
        paddingRight: 16,
        translateY: 0,
      }}
      exit={{
        opacity: 0.8,
        width: 0,
        paddingLeft: 0,
        paddingRight: 0,
        display: isMobile ? 'none' : 'auto',
      }}
      transition={{
        opacity: { duration: 0.6, type: 'easeInOut' },
        paddingLeft: { duration: 0.6, type: 'easeInOut' },
        paddingRight: { duration: 0.6, type: 'easeInOut' },
        marginRight: { duration: 0 },
        width: { duration: 0.6, type: 'easeInOut' }, // fixed typo here
      }}
    >
      <div className="min-w-[250px] w-full">
        <p className="w-full text-[15px] sm:text-center sm:truncate font-semibold text-gray-400 dark:text-gray-50">
          {t(surveyTypeData[activeSurveyType].title)}
        </p>
        <div className="flex items-center gap-2 pt-2 mx-auto sm:justify-center">
          {surveyTypeData[activeSurveyType].options
            ?.slice(
              surveyTypeData[activeSurveyType].options.length - 3,
              surveyTypeData[activeSurveyType].options.length
            )
            .map((option: string, index) => (
              <div className="w-full sm:w-auto" key={option}>
                <SurveyItem
                  callback={() => {
                    const newVotedSurveys = [...submission.votedSurveys, activeSurveyType]
                    const oldVotedSurveys = submission.votedSurveys

                    if (
                      submission.user?._id === user?.id &&
                      new Date(submission.date).getTime() + 600000 >
                        new Date(renderDate || new Date()).getTime()
                    ) {
                      if (newVotedSurveys.length === 3) {
                        setJustVoted && setJustVoted(false)
                      } else {
                        setActiveSurveyType(getAvailableSurveyType(newVotedSurveys, submission.id))
                      }
                    } else {
                      setJustVoted && setJustVoted(false)
                    }
                    mutateSubmissionItems(
                      'votedSurveys',
                      newVotedSurveys,
                      mutateSubmissions,
                      rawSubmissionData,
                      submission.id
                    )
                    sendSurvey({ type: activeSurveyType, submissionId, score: index + 1 })
                      .then(() => {})
                      .catch(() => {
                        mutateSubmissionItems(
                          'votedSurveys',
                          oldVotedSurveys,
                          mutateSubmissions,
                          rawSubmissionData,
                          submission.id
                        )
                      })
                  }}
                  isVoteSurvey={isVoteSurvey}
                  key={option}
                  index={index + 1}
                  content={t(option)}
                />
              </div>
            ))}
        </div>
      </div>
    </motion.div>
  )
}

export default Survey
