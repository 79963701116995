import { Extension, generateJSON } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'
import MarkdownIt from 'markdown-it'

function isCodeBlock(text: string) {
  const trimmedText = text.trim()
  // Check for fenced code blocks
  if (
    (trimmedText.startsWith('```') && trimmedText.endsWith('```')) ||
    (trimmedText.startsWith('~~~') && trimmedText.endsWith('~~~'))
  ) {
    return false
  }

  // Check if most lines are indented (for code blocks using indentation)
  const lines = text.split('\n')
  const indentedLines = lines.filter((line) => /^\s{4}/.test(line))
  if (indentedLines.length / lines.length > 0.5) {
    return true
  }

  return false
}

export const MarkdownPasteHandler = Extension.create({
  name: 'markdownPasteHandler',

  addProseMirrorPlugins() {
    const { editor } = this

    return [
      new Plugin({
        key: new PluginKey('markdownPasteHandler'),
        props: {
          handlePaste(view, event) {
            const clipboardData = event.clipboardData
            const text = clipboardData?.getData('text/plain')
            const html = clipboardData?.getData('text/html')

            // Check if the pasted content is plain text (potential Markdown) and not HTML
            if (text && !html) {
              try {
                // Function to detect if the text is a code block

                if (isCodeBlock(text)) {
                  // Do not process as Markdown if it's a code block
                  return false
                }

                const md = new MarkdownIt()
                const tokens = md.parse(text, {})

                // Define significant token types that indicate Markdown syntax
                const significantTokenTypes = new Set([
                  'heading_open',
                  'heading_close',
                  'paragraph_open',
                  'paragraph_close',
                  'bullet_list_open',
                  'bullet_list_close',
                  'ordered_list_open',
                  'ordered_list_close',
                  'list_item_open',
                  'list_item_close',
                  'blockquote_open',
                  'blockquote_close',
                  'code_block',
                  'fence',
                  'hr',
                  'link_open',
                  'link_close',
                  'image',
                  'strong_open',
                  'strong_close',
                  'em_open',
                  'em_close',
                  'table_open',
                  'table_close',
                  'thead_open',
                  'thead_close',
                  'tbody_open',
                  'tbody_close',
                  'tr_open',
                  'tr_close',
                  'th_open',
                  'th_close',
                  'td_open',
                  'td_close',
                  // Add more token types as needed
                ])

                let significantTokenCount = 0
                let totalTokenCount = 0

                tokens.forEach((token) => {
                  totalTokenCount++
                  if (significantTokenTypes.has(token.type)) {
                    significantTokenCount++
                  }
                  if (token.type === 'inline' && token.children) {
                    token.children.forEach((childToken) => {
                      totalTokenCount++
                      if (significantTokenTypes.has(childToken.type)) {
                        significantTokenCount++
                      }
                    })
                  }
                })

                const ratio = significantTokenCount / totalTokenCount

                // Decide whether to treat the text as Markdown based on the ratio
                if (ratio > 0.1) {
                  const mdContent = text

                  // Convert Markdown to HTML
                  const htmlContent = md.render(mdContent)

                  // Parse the HTML and manipulate it
                  const parser = new DOMParser()
                  const doc = parser.parseFromString(htmlContent, 'text/html')

                  // Find paragraphs that only contain images and unwrap them
                  const paragraphs = doc.querySelectorAll('p')
                  paragraphs.forEach((p) => {
                    // Check if the paragraph contains only images or empty text nodes
                    const hasOnlyImages = Array.from(p.childNodes).every(
                      (node) =>
                        (node.nodeType === Node.ELEMENT_NODE && node.nodeName === 'IMG') ||
                        (node.nodeType === Node.TEXT_NODE && !node.textContent?.trim())
                    )

                    if (hasOnlyImages) {
                      // Replace the paragraph with its child nodes
                      const fragment = document.createDocumentFragment()
                      while (p.firstChild) {
                        fragment.appendChild(p.firstChild)
                      }
                      p.parentNode?.replaceChild(fragment, p)
                    }
                  })

                  const serializer = new XMLSerializer()
                  const newHtmlContent = serializer.serializeToString(doc.body)

                  // Generate JSON from HTML using the editor's extensions
                  const jsonContent = generateJSON(
                    newHtmlContent,
                    editor.extensionManager.extensions
                  )

                  // Insert the parsed content into the editor
                  editor.commands.insertContent(jsonContent, {
                    parseOptions: {
                      preserveWhitespace: false,
                    },
                    applyPasteRules: true,
                    applyInputRules: true,
                    updateSelection: true,
                  })

                  return true // Indicate that the paste event has been handled
                } else {
                  // Not Markdown, use default paste handling
                  return false
                }
              } catch (error) {
                console.error('Error parsing Markdown content:', error)
                // Fallback to default behavior if parsing fails
                return false
              }
            }

            return false // Use default paste handling for other content types
          },
        },
      }),
    ]
  },
})
