import { atom } from 'jotai'
import { IPopupNotification } from '../interfaces/IPopupNotification'
import { INotification } from '@/interfaces/IOrganization'
import { KeyedMutator } from 'swr'

export const popupNotificationsAtom = atom<IPopupNotification[]>([])

export type INotificationAtom = {
  notificationResults: INotification[]
  size: number
  setSize: (size: number | ((_size: number) => number)) => Promise<any[] | undefined>
  rawNotificationResults: any[] | undefined
  mutateNotifications: KeyedMutator<any[]>
  totalNotificationResults: any
  notificationLoading: boolean
  totalUnviewedResults: number
} | null

export const notificationAtom = atom<INotificationAtom>(null)
export const notificationTypeAtom = atom<{
  type: string
  viewed: boolean | undefined
}>({ type: 'All', viewed: undefined })
