import { cn } from '@/lib/utils'
import { memo, useCallback } from 'react'

export type ColorButtonProps = {
  color?: string
  active?: boolean
  onColorChange?: (color: string) => void // eslint-disable-line no-unused-vars
}

export const ColorButton = memo(({ color, active, onColorChange }: ColorButtonProps) => {
  const wrapperClassName = cn(
    'flex items-center justify-center hover:bg-gray-200/[15%] dark:hover:bg-white/[5%] px-1.5 py-1.5 rounded group',
    active && 'bg-gray-200/[15%] dark:bg-white/[5%]'
  )
  const bubbleClassName = cn('w-4 h-4 rounded shadow-sm')

  const handleClick = useCallback(() => {
    if (onColorChange) {
      onColorChange(color || '')
    }
  }, [onColorChange, color])

  return (
    <button onClick={handleClick} className={wrapperClassName}>
      <div style={{ backgroundColor: color, color: color }} className={bubbleClassName}></div>
    </button>
  )
})

ColorButton.displayName = 'ColorButton'
