import React from 'react'

const EmptyIllustration: React.FC<{ primary?: string; tailwind?: string }> = ({
  primary,
  tailwind = '',
}) => {
  return (
    <>
      <svg enableBackground="new 0 0 36 36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m34.904 20.355c-3.782-5.791-3.38-11.727-5.203-14.518s-7.256-7.603-20.093.782-9.572 17.5-7.566 20.569c9.388 14.372 39.561 3.423 32.862-6.833z"
          // fill="#efefef"
          className={`fill-current text-accent opacity-10`}
          // className="opacity-10"
        />
        <path
          d="m29.25 18.75v6.5c0 1.1-.9 2-2 2h-18.5c-1.1 0-2-.9-2-2v-6.5h3.76c.44 0 .83.29.95.71l1.08 3.58c.12.42.51.71.95.71h9.02c.44 0 .83-.29.95-.71l1.08-3.58c.12-.42.51-.71.95-.71z"
          className="text-gray-100 fill-current text-accent dark:text-gray-500"
        />
        <path
          d="m29.25 18.75h-3.76c-.44 0-.83.29-.95.71l-1.08 3.58c-.12.42-.51.71-.95.71h-9.02c-.44 0-.83-.29-.95-.71l-1.08-3.58c-.12-.42-.51-.71-.95-.71h-3.76l4.5-10h13.5z"
          className={`fill-current text-accent opacity-60`}
        />
        {/* <path
          d="m25.71 23.04 1.08-3.58c.12-.42.51-.71.95-.71h-2.25c-.44 0-.83.29-.95.71l-1.08 3.58c-.12.42-.51.71-.95.71h2.25c.44 0 .83-.29.95-.71z"
          fill="#d5dbe1"
        /> */}
        {/* <path
          d="m9 25.25v-6.5h-2.25v6.5c0 1.1.9 2 2 2h2.25c-1.1 0-2-.9-2-2z"
          fill="#d5dbe1"
        /> */}

        <g className={`fill-current text-accent opacity-60`}>
          <path d="m27.744 6.5c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5zm0-2c-.276 0-.5.225-.5.5s.224.5.5.5.5-.225.5-.5-.224-.5-.5-.5z" />
          <path d="m7.605 4.173h1v2h-1z" transform="matrix(.707 -.707 .707 .707 -1.284 7.246)" />
          <path d="m6.927 8.739h2v1h-2z" transform="matrix(.707 -.707 .707 .707 -4.211 8.312)" />
          <path d="m10.994 4.673h2v1h-2z" transform="matrix(.707 -.707 .707 .707 -.145 9.996)" />
        </g>
        <path
          className="text-background-accent fill-current opacity-90 dark:text-background"
          d="m27.25 28h-18.5c-1.517 0-2.75-1.233-2.75-2.75v-6.5c0-.106.022-.211.066-.308l4.5-10c.121-.269.389-.442.684-.442h13.5c.295 0 .563.173.684.442l4.5 10c.044.097.066.202.066.308v6.5c0 1.517-1.233 2.75-2.75 2.75zm-19.75-9.089v6.339c0 .689.561 1.25 1.25 1.25h18.5c.689 0 1.25-.561 1.25-1.25v-6.339l-4.235-9.411h-12.53z"
        />
        <path
          className="text-background-accent fill-current opacity-90 dark:text-background"
          d="m22.506 24.5h-9.012c-.778 0-1.452-.501-1.676-1.247l-1.073-3.574c-.032-.105-.13-.179-.239-.179h-3.756v-1.5h3.756c.778 0 1.452.501 1.676 1.247l1.073 3.574c.032.106.13.179.239.179h9.012c.109 0 .208-.073.239-.179l1.073-3.574c.224-.746.897-1.247 1.676-1.247h3.756v1.5h-3.756c-.109 0-.208.073-.239.179l-1.073 3.574c-.224.746-.898 1.247-1.676 1.247z"
        />
        <path
          className="text-background-accent fill-current opacity-90 dark:text-background"
          d="m2 26.5h32v1.5h-32z"
        />
      </svg>
    </>
  )
}

export default EmptyIllustration
