import { PlusCircleIcon } from '@heroicons/react/solid'
import { useCommandState } from 'cmdk'
import React, { useState } from 'react'
import { Command, CommandEmpty, CommandInput, CommandList } from './Command'
import { Popover, PopoverContent, PopoverTrigger } from './Popover'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import { toast } from 'sonner'
import { cn } from '@/lib'
import { useMediaQuery } from '@/lib/hooks'

type CustomPageType = React.FC<{
  closeComboBox: () => void
  setPages: React.Dispatch<React.SetStateAction<string[]>>
  activePage: string
  setOnlyDisplayCustomPage: React.Dispatch<React.SetStateAction<boolean>>
  setExtendWidthOnCustomPage: React.Dispatch<React.SetStateAction<string>>
}>

type ModularComboBoxProps = {
  TriggerButton: () => JSX.Element
  oncloseFocus?: () => void
  CommandItems: React.FC<{
    closeComboBox: () => void
    setPages: React.Dispatch<React.SetStateAction<string[]>>
    setOnlyDisplayCustomPage: React.Dispatch<React.SetStateAction<boolean>>
    setExtendWidthOnCustomPage: React.Dispatch<React.SetStateAction<string>>
  }>
  searchableDisplayName: string
  popoverContentProps?: React.ComponentPropsWithoutRef<typeof PopoverContent>
  initialOnlyDisplayCustomPage?: boolean
  customClassName?: string
  paywalled?: boolean
  closeCallback?: (input: string) => void
} & (
  | {
      allowNewCreation: true
      CustomPage: CustomPageType
      setNewItemName: (name: string) => void
      firstNewStep: string
    }
  | {
      allowNewCreation?: false
      CustomPage?: CustomPageType
    }
)

const ModularComboBox = (props: ModularComboBoxProps) => {
  const {
    TriggerButton,
    popoverContentProps,
    searchableDisplayName,
    CommandItems,
    allowNewCreation,
    initialOnlyDisplayCustomPage,
    paywalled,
  } = props
  const [open, setOpen] = useState(false)
  const [pages, setPages] = useState<string[]>(initialOnlyDisplayCustomPage ? ['custom'] : [])
  const [onlyDisplayCustomPage, setOnlyDisplayCustomPage] = useState(
    initialOnlyDisplayCustomPage || false
  )
  const [extendWidthOnCustomPage, setExtendWidthOnCustomPage] = useState('')
  const page = pages[pages.length - 1]
  const { t } = useTranslation()
  const isMobile = useMediaQuery('(max-width: 767px)')

  if (paywalled) {
    return <TriggerButton />
  }

  return (
    <Popover
      open={open}
      onOpenChange={(open) => {
        props.closeCallback && props.closeCallback(page)
        setOpen(open)
      }}
    >
      <PopoverTrigger
        onClick={() => {
          setExtendWidthOnCustomPage('')
          setPages(initialOnlyDisplayCustomPage ? ['custom'] : [])
          setOnlyDisplayCustomPage(false)
        }}
        asChild
      >
        {TriggerButton()}
      </PopoverTrigger>
      <PopoverContent
        disableResizeAnimation={onlyDisplayCustomPage}
        className={cn(
          onlyDisplayCustomPage ? extendWidthOnCustomPage : 'w-[240px]',
          props.customClassName
        )}
        open={open}
        onCloseAutoFocus={(e) => {
          props.oncloseFocus && props.oncloseFocus()
          return e.preventDefault()
        }}
        {...popoverContentProps}
      >
        <Command loop>
          {!onlyDisplayCustomPage ? (
            <>
              <CommandInput
                allowNewCreation={allowNewCreation ? true : false}
                goToPreviousPage={(event) => {
                  if (pages.length > 0) {
                    setPages((pages) => {
                      return pages.slice(0, pages.length - 1)
                    })
                  } else {
                    if (event.key === 'Escape') {
                      setOpen(false)
                    }
                  }
                }}
                autoFocus={isMobile ? false : true}
                initiateNewItemCreation={(name) => {
                  if (allowNewCreation) {
                    if (name?.length > 2) {
                      props.setNewItemName(name)
                      setPages([...pages, props.firstNewStep])
                    } else {
                      toast.error('Please enter the name of the new item in the search box first.')
                    }
                  }
                }}
                placeholder={
                  t('search-for', {
                    type: page && allowNewCreation ? page : searchableDisplayName,
                  }) + '...'
                }
                // placeholder={`Search ${page && allowNewCreation ? page : searchableDisplayName}...`}
              />
              <CommandList>
                {!page ? (
                  <>
                    <CommandItems
                      closeComboBox={() => {
                        setOpen(false)
                      }}
                      setPages={setPages}
                      setOnlyDisplayCustomPage={setOnlyDisplayCustomPage}
                      setExtendWidthOnCustomPage={setExtendWidthOnCustomPage}
                    />
                    <CreateNew
                      initiateNewItemCreation={(name) => {
                        if (allowNewCreation) {
                          props.setNewItemName(name)
                          setPages([...pages, props.firstNewStep])
                        }
                      }}
                      allowNewCreation={allowNewCreation}
                      type={searchableDisplayName}
                    />
                  </>
                ) : null}
                {page && props.CustomPage ? (
                  <>
                    <props.CustomPage
                      setOnlyDisplayCustomPage={setOnlyDisplayCustomPage}
                      closeComboBox={() => {
                        setOpen(false)
                      }}
                      setPages={setPages}
                      setExtendWidthOnCustomPage={setExtendWidthOnCustomPage}
                      activePage={page}
                    />
                    <CommandEmpty>No {page?.toLowerCase()} found</CommandEmpty>
                  </>
                ) : null}
              </CommandList>
            </>
          ) : page && props.CustomPage ? (
            <props.CustomPage
              setOnlyDisplayCustomPage={setOnlyDisplayCustomPage}
              closeComboBox={() => {
                setOpen(false)
                setOnlyDisplayCustomPage(false)
              }}
              setExtendWidthOnCustomPage={setExtendWidthOnCustomPage}
              setPages={setPages}
              activePage={page}
            />
          ) : null}
        </Command>
      </PopoverContent>
    </Popover>
  )
}

export default ModularComboBox

const CreateNew: React.FC<{
  type: string
  allowNewCreation?: boolean
  initiateNewItemCreation?: (name: string) => void
}> = ({ type, allowNewCreation, initiateNewItemCreation }) => {
  const searchCount = useCommandState((state) => {
    return state.filtered.count
  })
  const search = useCommandState((state) => {
    return state.search
  })

  if (searchCount === 0 && allowNewCreation && initiateNewItemCreation) {
    return (
      <AnimatePresence initial={true}>
        <motion.div
          initial={{ display: 'none' }}
          animate={{ display: 'block' }}
          className="max-w-full"
        >
          <CommandList className="py-1 pr-2 w-full">
            <button
              onClick={() => {
                if (search?.length > 2) {
                  initiateNewItemCreation(search)
                } else {
                  toast.error('Please enter the name of the new item in the search box first.')
                }
              }}
              className="w-full dropdown-item"
            >
              <PlusCircleIcon className=" mr-1.5 h-4 w-4 secondary-svg" />
              Create new {type}
            </button>
          </CommandList>
        </motion.div>
      </AnimatePresence>
    )
  } else {
    return <CommandEmpty>No {type} found</CommandEmpty>
  }
}
