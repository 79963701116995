import React from 'react'

interface MutedItemWrapperProps {
  children?: React.ReactNode
}

const MutedItemWrapper = ({ children }: MutedItemWrapperProps) => {
  return (
    <div className="w-full muted-item-background dark:text-foreground/60  text-sm text-center">
      {children}
    </div>
  )
}

export default MutedItemWrapper
