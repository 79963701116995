import React from 'react'
import MutedItemWrapper from './MutedItemWrapper'
import Loader from '../Loader'

const MutedItemLoading = () => {
  return (
    <MutedItemWrapper>
      <div className="w-5 h-5 mx-auto secondary-svg">
        <Loader />
      </div>
    </MutedItemWrapper>
  )
}

export default MutedItemLoading
