import { UniqueIdentifier } from '@alissavrk/dnd-kit-core'
import { atom } from 'jotai'

export const disablePopupClosingAtom = atom<{
  [id: string]: boolean
}>({})
export const uploadingImageAtom = atom(false)

export const activeAuthorAtom = atom<{
  [id: string]: string
}>({})

export const isMountedAtom = atom(false)

export const tableOfContentItemsAtom = atom<any>([])

export const helpdocsOpenIdsAtom = atom<UniqueIdentifier[]>([])
