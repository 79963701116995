import React, { useEffect, useState } from 'react'
import InlineError from './InlineError'
import PopupWrapper from './PopupWrapper'
import CustomColorSwatch from './CustomColorSwatch'
import { IOrganizationStatus } from '../interfaces/IOrganization'

const EditStatusModal: React.FC<{
  open: boolean
  setOpen: Function
  callBack: Function
  initialStatus: IOrganizationStatus
}> = ({ open, setOpen, callBack, initialStatus }) => {
  const [status, setStatus] = useState<IOrganizationStatus>(initialStatus)
  const [errors, setErrors] = useState({ name: false, color: false })

  useEffect(() => {
    setStatus(initialStatus)
  }, [initialStatus])

  const editStatus = () => {
    if (status.name === '' || status.color === '') {
      setErrors({ name: status.name === '', color: status.color === '' })
    } else {
      callBack(status)
      setErrors({ name: false, color: false })
      setOpen(false)
    }
  }

  return (
    <PopupWrapper isOpen={open} setIsOpen={setOpen}>
      <h2 className="text-lg font-medium text-gray-600 dark:text-white">Edit Status</h2>
      <p className="mt-1 mb-2 text-sm text-gray-400 dark:text-foreground">
        Enter a new name for your status.
      </p>
      <label htmlFor="status" className="text-sm text-gray-500 dark:text-gray-100">
        Name
      </label>
      <input
        value={status.name}
        onChange={(event) => {
          errors && setErrors({ ...errors, name: false })
          setStatus({ ...status, name: event.target.value })
        }}
        className="mt-1"
        id="status"
        type="text"
        placeholder=""
      />
      {errors.name && <InlineError error="Please enter a status name." />}

      <CustomColorSwatch
        active={status.color}
        setActive={(newColor: string) => setStatus((prev) => ({ ...prev, color: newColor }))}
      />
      {errors.color && <InlineError error="Please select a color." />}

      <button onClick={editStatus} className="mt-4 ml-auto dashboard-primary">
        Change status
      </button>
    </PopupWrapper>
  )
}

export default EditStatusModal
