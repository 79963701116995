import * as React from 'react'
import * as SwitchPrimitives from '@radix-ui/react-switch'

import { cn } from '@/lib/utils'

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & {
    icon?: React.ReactElement
    small?: boolean
  }
>(({ className, icon, small, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      'peer inline-flex p-0 h-[20px] w-[36px] shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-accent data-[state=unchecked]:bg-gray-100/60 dark:data-[state=unchecked]:bg-border',
      className,
      small ? 'h-[16px] w-[28px]' : ''
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        'pointer-events-none h-4 flex items-center justify-center w-4 rounded-full bg-background dark:bg-gray-200/30 data-[state=checked]:dark:bg-primary-foreground/70 shadow-lg ring-0 transition-transform dark:data-[state=checked]:text-primary text-foreground  data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0',
        small ? 'h-3 w-3 data-[state=checked]:translate-x-3' : ''
      )}
    >
      {icon ? icon : null}
    </SwitchPrimitives.Thumb>
  </SwitchPrimitives.Root>
))
Switch.displayName = SwitchPrimitives.Root.displayName

export { Switch }
