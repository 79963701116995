import { mutateSubmissionItems } from '@/lib/submissionMutator'
import { activeCategoryName, cn } from '@/lib/utils'
import { LockClosedIcon } from '@heroicons/react/solid'
import React from 'react'
import { KeyedMutator } from 'swr'
import { updateSubmissionInfo } from '../../network/lib/submission'
import { useCurrentOrganization } from '../data/organization'
import { useUser } from '../data/user'
import { IPostCategory, ISubmission, ISubmissionPaginate } from '../interfaces/ISubmission'
import CategoryCombobox from './CategoryCombobox'
import MultiselectButton from './MultiselectButton'
import { can } from '@/lib/acl'
import { toast } from 'sonner'
import BoardIcon from './BoardIcon'
import { useTranslation } from 'next-i18next'
import SimpleTooltip from './SimpleTooltip'

const Category: React.FC<{
  category: ISubmission['postCategory']
  small?: boolean
  postId?: string
  mutateSubmissions?: KeyedMutator<any[]>
  roadmap?: boolean
  dash?: boolean
  widget?: boolean
  transparent?: boolean
  rawSubmissionData?: ISubmissionPaginate[] | ISubmissionPaginate
  xSmall?: boolean
  noBg?: boolean
}> = ({
  category,
  small = false,
  postId,
  mutateSubmissions,
  roadmap,
  dash,
  widget = false,
  transparent = false,
  rawSubmissionData,
  xSmall = false,
  noBg = false,
}) => {
  const { user } = useUser()
  const { org } = useCurrentOrganization()

  const changeCategory = (category: IPostCategory) => {
    if (!postId) return
    updateSubmissionInfo({ submissionId: postId, categoryId: category.id }).catch(() => {
      toast.error('Failed to update board.')
    })
    if (mutateSubmissions && rawSubmissionData) {
      mutateSubmissionItems('postCategory', category, mutateSubmissions, rawSubmissionData, postId)
    }
  }

  const { i18n, t } = useTranslation()

  const nameOfCategory = activeCategoryName(category, i18n?.language)

  if (postId && can(user?.id, 'set_post_category', org)) {
    return (
      <>
        <CategoryCombobox
          callBack={changeCategory}
          selectedCategory={category}
          TriggerButton={() => (
            <MultiselectButton
              compact={dash}
              customCustomBadgePadding={'pr-9'}
              customBadge={
                category?.private && (
                  <span
                    className={cn(
                      'bg-gray-100/40 backdrop-blur dark:bg-gray-200/[8%] main-transition leading-none h-5 w-5 flex items-center justify-center text-foreground rounded'
                    )}
                  >
                    <LockClosedIcon className="secondary-svg !h-3 !w-3" />
                  </span>
                )
              }
              icon={
                category?.icon ? (
                  <BoardIcon smallMargin={true} icon={category.icon} small={true} />
                ) : undefined
              }
            >
              {nameOfCategory}
            </MultiselectButton>
          )}
        />
      </>
    )
  } else {
    return (
      <div
        className={`px-2 ${
          xSmall ? 'py-0.5' : 'py-1'
        } truncate flex items-center text-background-accent dark:text-foreground ${
          small ? 'text-xs' : 'text-sm'
        } font-medium border-gray-100/50 bg-gray-50/50 dark:bg-secondary dark:border-border/70 dark:shadow-sm ${
          noBg && 'dark:bg-white/[3%] dark:border-white/[5%] dark:shadow-none'
        } ${transparent && 'dark:bg-border'} rounded-md border`}
      >
        {category?.icon && <BoardIcon icon={category.icon} smallMargin={true} small={true} />}
        <p className={`truncate`}>{nameOfCategory}</p>
        {category?.private && (
          <span className="ml-2">
            <LockClosedIcon className="secondary-svg !h-3 !w-3" />
          </span>
        )}
      </div>
    )
  }
}

export default Category
