import { IResponse } from '@/components/SurveyWidgetPage'
import { IAdvancedSurvey } from '@/interfaces/ISurvey'
import axiosClient from 'network/apiClient'
import useSWR from 'swr'

export function respondToSurvey(data: { id: string; responses: IResponse[] }) {
  return axiosClient.post('/v1/organization/advancedSurvey/respond', data)
}

// Create a new survey
export function createSurvey(data: IAdvancedSurvey) {
  return axiosClient.post('/v1/organization/advancedSurvey', { survey: data })
}

// Edit an existing survey
export function editSurvey(data: IAdvancedSurvey) {
  return axiosClient.patch('/v1/organization/advancedSurvey', { survey: data })
}
// Edit an existing survey
export function deleteSurveyRequest(id: string) {
  return axiosClient.delete('/v1/organization/advancedSurvey', { data: { id: id } })
}

export function useArticleSurvey(articleId: string | null) {
  const { data, error, mutate } = useSWR(
    articleId
      ? `/v1/organization/advancedSurvey/available?articleId=${articleId}&helpcenterMode=true`
      : null
  )

  return {
    survey: data?.surveys?.[0],
    isLoading: !error && !data,
    isError: error,
    mutate,
  }
}
